<template>
    <transition name="modal">
        <div @click="close" class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div @click.stop class="d-flex justify-content-center">
                        <div
                            class="bg-light cal-container m-4"
                            style="height: 90vh; width: 90vw; border-radius: 15px; overflow: hidden;"
                        >
                            <div
                                class="d-flex position-absolute p-4"
                                style="z-index: 100; right: 0"
                            >
                                <CircleCloseBtn @handleBtnClick="close" />
                            </div>
                            <div
                                class="field-container"
                                style="position: absolute; z-index: 100; background-color: white; overflow: hidden; top:2%; left:1%"
                            >
                                <div
                                    class="text-14 py-3 px-4"
                                    style="font-weight: 600;"
                                >
                                    {{ $t('search_bar.filter_search_results') }}
                                </div>
                                <hr
                                    class="p-0 m-0"
                                    style="border-top: 1px solid #e4e4e4;"
                                />
                                <AdvanceSearch
                                    :input-price-range="inputPriceRange"
                                    :input-time-frame="inputTimeFrame"
                                    :input-star-rating="inputStarRating"
                                    :input-theme="inputTheme"
                                    :is-show-theme="true"
                                    :is-show-time-frame="true"
                                    @setPriceRange="setPriceRange"
                                    @setTimeFrame="setTimeFrame"
                                    @setStarRating="setStarRating"
                                    @setTheme="setTheme"
                                    @setClearFilter="setClearFilter"
                                    class="px-4"
                                />
                            </div>
                            <GMap
                                :map="map"
                                :selected-list-item="selectedListItem"
                                @updateMapPopupInfo="updateMapPopupInfo"
                                @navigateToDetails="navigateToDetails"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import AdvanceSearch from '@/components/ui/AdvanceSearch'
import CircleCloseBtn from '~/components/item/CircleCloseBtn'
import GMap from '~/components/ui/Map'
export default {
    name: 'MapSearch',
    components: { GMap, CircleCloseBtn, AdvanceSearch },
    props: {
        numPpl: {
            type: Number,
            default: 0
        },
        date: {
            type: String,
            required: true,
            default: undefined
        },
        spaceType: {
            type: String,
            required: true,
            default: undefined
        },
        inputPriceRange: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputTimeFrame: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputStarRating: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputTheme: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        map: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        },
        selectedListItem: {
            type: Object,
            required: true,
            default: undefined
        }
    },
    data() {
        return {}
    },
    computed: {},
    mounted() {
        document.documentElement.style.overflow = 'hidden'
    },
    beforeDestroy() {
        document.documentElement.style.overflow = 'auto'
    },
    methods: {
        close() {
            this.$emit('close')
        },
        updateMapPopupInfo(item) {
            this.$emit('updateMapPopupInfo', item)
        },
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        },
        setAndDisplayMap(listItem) {
            this.$emit('setAndDisplayMap', listItem)
        },
        setPriceRange(priceRange) {
            const isSearch = true
            const isMapFilter = true
            this.$emit('setPriceRange', priceRange, isSearch, isMapFilter)
        },
        setTimeFrame(timeFrame) {
            const isSearch = true
            const isMapFilter = true
            this.$emit('setTimeFrame', timeFrame, isSearch, isMapFilter)
        },
        setTheme(checked) {
            const isSearch = true
            const isMapFilter = true
            this.$emit('setTheme', checked, isSearch, isMapFilter)
        },
        setStarRating(checked) {
            const isSearch = true
            const isMapFilter = true
            this.$emit('setStarRating', checked, isSearch, isMapFilter)
        },
        setClearFilter(filter) {
            this.$emit('setClearFilter', filter)
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
    display: table;
    transition: opacity 0.3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {
    transition: all 0.3s ease;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}
.cal-container {
    position: relative;
    border-radius: 10px;
}
@media (max-width: 767.98px) {
    .cal-container {
        position: relative;
        border-radius: 10px;
        width: 90vw;
    }
    .date-picker-wrapper >>> .vdp-datepicker__calendar {
        font-size: 14px !important;
        width: 100%;
        height: 100%;
    }
    .modal-wrapper {
        /*vertical-align: middle;*/
    }
}
.field-container {
    border-radius: 8px;
    width: 279px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
}
</style>
