<template>
    <gmap-custom-marker :marker="coordinate" :offset-y="-15" :offset-x="12" :zIndex="101">
        <div>
            <div
                :key="updateSwiper"
                class="d-none d-lg-block px-3 py-3 top-marker"
            >
                <div class="d-flex justify-content-end">
                    <CircleCloseBtn
                        :btn-color="'grey'"
                        @handleBtnClick="closeMarker"
                        class="mb-3"
                        style="margin-right: -4px; margin-top: -3px"
                    />
                </div>
                <ListItem
                    :list-item="selectedListItem"
                    :date="date"
                    :num-ppl="numPpl"
                    :is-map-view="true"
                    @navigateToDetails="navigateToDetails"
                    @updateMapPopupInfo="updateMapPopupInfo"
                    @setAndDisplayMap="setAndDisplayMap"
                />
            </div>
            <!--            <div-->
            <!--                class="d-flex justify-content-center position-relative"-->
            <!--            >-->
            <!--                <div class="bottomTriangle d-none d-lg-block"></div>-->
            <!--            </div>-->
        </div>
    </gmap-custom-marker>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import ListItem from '@/components/list/ListItem'
import CircleCloseBtn from '~/components/item/CircleCloseBtn'

export default {
    name: 'ListInfo',
    components: {
        CircleCloseBtn,
        ListItem,
        'gmap-custom-marker': GmapCustomMarker
    },
    props: {
        coordinate: {
            type: Object,
            required: true,
            default: undefined
        },
        image: {
            type: String,
            required: false,
            default: undefined
        },
        currency: {
            type: String,
            required: false,
            default: undefined
        },
        price: {
            type: Number,
            required: true,
            default: 0
        },
        id: {
            type: String,
            required: false,
            default: undefined
        },
        date: {
            type: String,
            required: false,
            default: undefined
        },
        type: {
            type: String,
            required: false,
            default: undefined
        },
        urlTitle: {
            type: String,
            required: false,
            default: undefined
        },
        spaceName: {
            type: String,
            required: false,
            default: undefined
        },
        numPpl: {
            type: Number,
            default: undefined
        },
        selectedListItem: {
            type: Object,
            required: true,
            default: undefined
        }
    },
    data() {
        return {
            updateSwiper: 0
        }
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        space() {
            return this.$route.params.space
        }
    },
    mounted() {
        setTimeout(() => {
            this.updateSwiper += 1
        }, 300)
    },
    methods: {
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        },
        setAndDisplayMap(listItem) {
            this.$emit('setAndDisplayMap', listItem)
        },
        updateMapPopupInfo(listItem) {
            this.$emit('updateMapPopupInfo', listItem)
        },
        closeMarker() {
            this.$emit('closeMarker')
        }
    }
}
</script>

<style scoped>
.talkBubble {
    border-radius: 500px;
    background-color: white;
}
.image {
    border-radius: 50px;
    height: 70px;
    width: 70px;
    object-fit: cover;
}
.bottomTriangle {
    position: absolute;
    width: 0;
    height: 0;
    top: -9px;
    border-bottom: 16px solid var(--light);
    border-left: 16px solid transparent;
    transform: rotate(45deg);
    z-index: 100;
}
.button {
    height: 35px;
    width: 35px;
    border-radius: 500px;
    z-index: 9999;
}
.text-space {
    /*font-size: 14px;*/
    color: #292929;
}
.text-price {
    /*font-size: 16px;*/
    color: #404040;
}
.text-price b {
    /*font-size: 12px;*/
}
.icon-arrow {
    font-size: 14px;
}
.top-marker {
    width: 350px;
    background-color: white;
    border-radius: 8px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    font-family: 'Poppins';
}
</style>
