<template>
    <client-only>
        <b-dropdown
            variant="transparent"
            class="p-0 m-0 sorting-dropdown"
            :right="alignRight"
            no-caret
        >
            <template #button-content>
                <SortDownSvg style="width: 16px; height: 16px" />
                <div class="text-13 pl-2 text-mid">
                    {{ inputSort.text ? inputSort.text : $t('list.sort') }}
                </div>
            </template>
            <b-dropdown-item
                v-for="(item, index) in searchBarSetting.sorting"
                :key="index"
                @click="setSort(item)"
                active-class="bg-primary"
            >
                <p
                    :class="
                        inputSort.key === item.key &&
                        inputSort.value === item.value
                            ? 'text-primary'
                            : ''
                    "
                    class="text-14"
                >
                    {{ item.text }}
                </p>
                <div
                    v-if="index !== searchBarSetting.sorting.length - 1"
                    style="border-bottom: 1px solid #e4e4e4; padding: 10px 0;"
                ></div>
            </b-dropdown-item>
        </b-dropdown>
        <template #placeholder>
            <TextSkeleton
                width="100px"
                height="17px"
                class="skeleton-container"
            />
        </template>
    </client-only>
</template>
<script>
import TextSkeleton from '~/components/item/TextSkeleton.vue'
import SortDownSvg from '@/components/iconSvg/SortDownSvg.vue'

export default {
    name: 'SortingButton',
    components: { SortDownSvg, TextSkeleton },
    props: {
        inputSort: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        },
        alignRight: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        searchBarSetting() {
            return this.$store.getters['api/searchBarSetting']
        }
    },
    methods: {
        setSort(item) {
            this.$emit('setSort', item)
        }
    }
}
</script>
<style scoped>
.sorting-dropdown >>> button {
    display: flex;
    align-items: center;
    border-radius: 20px;
    border: 1px solid #e4e4e4;
    background-color: white;
    box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
    padding: 8px 14px;
}
.sorting-dropdown >>> .dropdown-menu {
    min-width: 266px;
    border-radius: 20px;
    margin-top: 7px;
    padding: 10px 22px;
    box-shadow: rgb(17 17 26 / 10%) 0px 0px 16px;
}
.sorting-dropdown >>> .dropdown-item {
    padding: 10px 0 10px 0;
}
.sorting-dropdown >>> .dropdown-item:hover {
    background-color: transparent;
    color: #f2bc1f;
}
@media (max-width: 576px) {
    .sorting-dropdown >>> .dropdown-menu {
        min-width: 100%;
    }
    .sorting-dropdown >>> .dropdown-item {
        text-align: right;
    }
}
.skeleton-container {
    height: 38px;
}
</style>
