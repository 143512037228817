<template>
    <div
        :class="
            listItem?.frame_style && daySpaceStatus
                ? 'mobile-featured-frame loaded-card'
                : daySpaceStatus
                ? 'loaded-card'
                : ''
        "
        class="w-100 card-container card-shadow"
        @click="onCardClicked"
    >
        <div v-if="daySpaceStatus">
            <SpaceCardFeaturedFrame
                v-if="
                    (listItem?.ranking?.is_featured || listItem?.ranking?.is_promotion) &&
                        listItem?.frame_style
                "
                :algolia-lang="algoliaLang"
                :frame-style="listItem?.frame_style"
                :class="isAvailable ? '' : 'sold-out-opacity'"
            />
            <div v-if="listItem" class="px-4 py-3">
                <!--Name, Stars, Location, Tags-->
                <!--Debug: style="border: red solid 1px;"-->
                <div
                    class="m-auto pb-2 pb-lg-2 px-0 d-flex justify-content-between flex-column flex-lg-row"
                    :class="isAvailable ? '' : 'sold-out-opacity-desktop'"
                >
                    <div class="px-0">
                        <div
                            class="d-flex justify-content-between align-items-center"
                        >
                            <SpaceCardTitle
                                :title="listItem?.name?.[algoliaLang]"
                                class="pt-1 pt-lg-0 mb-1 mr-0 mr-lg-2"
                            />
                            <CrownFeatureIcon
                                v-if="listItem?.frame_style?.type === 'FEATURED'"
                            />
                            <div
                                v-if="listItem?.frame_style?.type === 'PROMOTION'"
                                class="flex-grow-1"
                            />
                            <SpaceCardPromoFrameIcon
                                v-if="listItem?.frame_style?.type === 'PROMOTION'"
                                :algolia-lang="algoliaLang"
                                :frame-style="listItem?.frame_style"
                            />
                        </div>
                        <SpaceCardRatingsLocation
                            :algolia-lang="algoliaLang"
                            :list-item="listItem"
                            class="mt-0 mt-lg-1 mb-1"
                        />
                    </div>
                    <div
                        class="px-0 d-flex flex-column justify-content-between align-items-lg-end"
                    >
                        <!--Feature Tags (Yellow)-->
                        <div
                            class="d-flex d-lg-block justify-content-between align-items-end"
                        >
                            <div class="d-flex flex-column align-items-lg-end mt-lg-2">
                                <NearbyDistanceText
                                    class="text-12 mt-0 mb-3 nearby-distance"
                                    :nearby-distance="listItem.nearbyDistance"
                                />
                                <div
                                    v-if="listItem.space_tags?.length > 0"
                                    class="d-flex flex-wrap align-items-center justify-content-lg-end ml-n2 ml-lg-0 mr-0 mr-lg-n2"
                                >
                                    <SpaceCardFeatureTags
                                        :feature-tags="listItem.space_tags"
                                    />
                                </div>
                            </div>
                            <div class="flex-grow-1" />
                            <b-button
                                aria-label="display map"
                                class="bg-transparent d-block d-lg-none border-0 px-0 py-0 mb-2 ml-2"
                                @click.prevent.stop="setAndDisplayMap"
                            >
                                <ShowMapSvg fill-color="#404040" />
                            </b-button>
                        </div>
                    </div>
                </div>
                <!--Gallery, Tags, Shortcuts-->
                <!--Debug: style="border: blue solid 1px;"-->
                <div class="row h-auto m-auto" style="position: sticky;">
                    <div
                        class="col-12 col-lg-5 px-0 d-flex flex-column justify-content-center"
                    >
                        <router-link
                            :to="{ path: globalComputeLinkToDetails(listItem.slug, spaceCardType) }"
                            class="space-swiper-link position-static position-relative"
                        >
                            <div
                                @click.prevent.stop="
                                    navigateToDetails({
                                        btn: getGAEventName(landingPageName,'SPACE_CARD', 'Image'),
                                        slug: listItem?.slug,
                                        name: listItem?.name?.[algoliaLang],
                                        objectID: listItem?.id,
                                        queryID: listItem?.queryID
                                    })
                                ">
                                <SwiperContainer
                                    :swiper-pagination="true"
                                    :name="listItem?.slug"
                                    :type="spaceCardType"
                                    :swiper-button="deviceType() !== 'mobile'"
                                    class="round-image-swiper-wrapper space-card-swiper"
                                >
                                    <ImageContainer
                                        v-for="(image, imgIndex) in listItem.images"
                                        :key="imgIndex"
                                        :src="computeImageUrlWithVariant(image)"
                                        :alt="listItem?.name?.[algoliaLang]"
                                        :loading="imgIndex === 0 ? 'eager' : 'lazy'"
                                        :class="
                                            isAvailable ? '' : 'sold-out-opacity'
                                        "
                                        class="swiper-slide space-card-image w-100"
                                    />
                                </SwiperContainer>
                            </div>
                            <SpaceCardImageTags :list-item="listItem" />
                            <SpaceCardFavButton
                                :slug="listItem.slug"
                                :space-name="listItem?.name?.[algoliaLang]"
                                :space="$route.params.space"
                                class="position-absolute"
                                style="right: 0; top: 3%; z-index: 1;"
                            />
                            <SpaceCardViewMapButton
                                :is-map-view="isMapView"
                                :list-item="listItem"
                                @onViewMapButtonClicked="openDesktopMap"
                            />
                        </router-link>
                        <SpaceCardTopComment
                            :content="
                                isHotelSpaceType
                                    ? listItem.top_comments?.[0]
                                    : undefined
                            "
                            class="d-none d-lg-block mt-3"
                        />
                    </div>
                    <!--Hotel-->
                    <div
                        v-if="isHotelSpaceType"
                        class="col-12 col-lg-7 pl-0 pl-lg-4 pr-0 d-flex flex-column justify-content-center"
                    >
                        <!--Data Tags (White)-->
                        <!--Pending BE Data-->
                        <SpaceCardDataTags v-if="false" class="mt-2 mt-lg-0" />
                        <SpaceCardTopComment
                            :content="listItem.top_comments?.[0]"
                            class="d-block d-lg-none mt-3"
                        />
                        <div v-if="isAvailable">
                            <div
                                class="d-flex align-items-end justify-content-between mt-3 mt-lg-0 mb-2"
                            >
                                <Tags :tags="listItem.fomo_tags" />
                                <SpaceCardMoreButton
                                    :btn="getGAEventName(landingPageName,'SPACE_CARD', 'More')"
                                    :algolia-lang="algoliaLang"
                                    :dest-url="globalComputeLinkToDetails(listItem.slug, spaceCardType)"
                                    :list-item="listItem"
                                    @onMoreClicked="onMoreClicked"
                                >
                                    <div>{{ $t('list.view_all') }}</div>
                                    <ChevronRightSvg fill-color="#838383" />
                                </SpaceCardMoreButton>
                            </div>
                            <SpaceCardTimeslots
                                v-if="spaceCardType !== 'staycation'"
                                :algolia-lang="algoliaLang"
                                :button-list="listItem.button_list"
                                :date="date"
                                :list-item="listItem"
                                @onTimeslotClicked="navigateToDetails"
                            />
                        </div>
                        <SpaceCardSoldOut
                            v-else
                            :next-available="listItem.next_available"
                            :algolia-lang="algoliaLang"
                            :dest-url="globalComputeLinkToDetails(listItem.slug, spaceCardType)"
                            :list-item="listItem"
                            class="mt-3 mt-lg-0"
                            @onMoreClicked="onMoreClicked"
                        />
                    </div>
                    <!--Party | Co-work | Meeting-->
                    <div
                        v-if="isNonHotelSpaceType"
                        class="col-12 col-lg-7 pl-0 pl-lg-4 pr-0 d-flex flex-column justify-content-center"
                    >
                        <SpaceCardTopComment
                            v-if="false"
                            content="dummy"
                            class="d-block d-lg-none mt-3"
                        />
                        <SpaceCardAreaCapacity
                            :area="listItem.button_list?.[0]?.area"
                            :capacity="listItem.capacity_str"
                            class="mt-3 mt-lg-0"
                        />
                        <div v-if="isAvailable">
                            <div
                                class="d-flex justify-content-end mt-3 mt-lg-0 mb-2"
                            >
                                <SpaceCardMoreButton
                                    :btn="getGAEventName(landingPageName,'SPACE_CARD', 'More')"
                                    :algolia-lang="algoliaLang"
                                    :dest-url="globalComputeLinkToDetails(listItem.slug, spaceCardType)"
                                    :list-item="listItem"
                                    @onMoreClicked="onMoreClicked"
                                >
                                    <div>{{ $t('list.view_all') }}</div>
                                    <ChevronRightSvg fill-color="#838383" />
                                </SpaceCardMoreButton>
                            </div>
                            <SpaceCardPartyMeetTimeslots
                                :algolia-lang="algoliaLang"
                                :button-list="listItem.button_list"
                                :date="date"
                                :slug="listItem.slug"
                                :list-item="listItem"
                                @onTimeslotClicked="navigateToDetails"
                            />
                        </div>
                        <SpaceCardSoldOut
                            v-else
                            :next-available="listItem.next_available"
                            :algolia-lang="algoliaLang"
                            :dest-url="globalComputeLinkToDetails(listItem.slug, spaceCardType)"
                            :list-item="listItem"
                            class="mt-3 mt-lg-0"
                            @onMoreClicked="onMoreClicked"
                        />
                        <SpaceCardOtherTimes
                            v-if="isAvailable"
                            :algolia-lang="algoliaLang"
                            :date="date"
                            :list-item="listItem"
                            :prev-times="listItem?.prevStartTimes"
                            :after-times="listItem?.afterStartTimes"
                            class="mt-2"
                            @onTimeslotClicked="navigateToDetails"
                        />
                    </div>
                </div>
            </div>
        </div>
        <SpaceCardSkeleton v-else />
    </div>
</template>

<script>
import { getAlgoliaLang } from '@/services/algoliaApi'
import SpaceCardFavButton from '@/components/list/SpaceCardFavButton'
import SpaceCardImageTags from '@/components/list/SpaceCardImageTags'
import SpaceCardTitle from '@/components/list/SpaceCardTitle'
import SpaceCardFeatureTags from '@/components/list/SpaceCardFeatureTags'
import SpaceCardRatingsLocation from '@/components/list/SpaceCardRatingsLocation'
import SpaceCardDataTags from '@/components/list/SpaceCardDataTags'
import SpaceCardTimeslots from '@/components/list/SpaceCardTimeslots'
import SpaceCardTopComment from '@/components/list/SpaceCardTopComment'
import SpaceCardMoreButton from '@/components/list/SpaceCardMoreButton'
import SpaceCardViewMapButton from '@/components/list/SpaceCardViewMapButton'
import SpaceCardFeaturedFrame from '@/components/list/SpaceCardFeaturedFrame'
import SpaceCardAreaCapacity from '@/components/list/SpaceCardAreaCapacity'
import SpaceCardPartyMeetTimeslots from '@/components/list/SpaceCardPartyMeetTimeslots'
import SpaceCardOtherTimes from '@/components/list/SpaceCardOtherTimes'
import SpaceCardSoldOut from '@/components/list/SpaceCardSoldOut'
import SpaceCardSkeleton from '@/components/list/SpaceCardSkeleton'
import SpaceCardPromoFrameIcon from '@/components/list/SpaceCardPromoFrameIcon'
import Tags from '@/components/item/Tags'
import SwiperContainer from '@/components/ui/SwiperContainer'
import ShowMapSvg from '@/components/iconSvg/ShowMapSvg'
import NearbyDistanceText from '@/components/item/NearbyDistanceText'
import ChevronRightSvg from '@/components/iconSvg/ChevronRightSvg'
import CrownFeatureIcon from '@/components/item/CrownFeatureIcon'
import ImageContainer from "@/components/ui/ImageContainer.vue";
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'ListSpaceCard',
    components: {
        ImageContainer,
        CrownFeatureIcon,
        ChevronRightSvg,
        NearbyDistanceText,
        ShowMapSvg,
        SpaceCardFavButton,
        SpaceCardImageTags,
        SpaceCardTitle,
        SpaceCardFeatureTags,
        SpaceCardRatingsLocation,
        SpaceCardTimeslots,
        SpaceCardDataTags,
        SpaceCardTopComment,
        SpaceCardMoreButton,
        SpaceCardViewMapButton,
        SpaceCardFeaturedFrame,
        SpaceCardAreaCapacity,
        SpaceCardPartyMeetTimeslots,
        SpaceCardOtherTimes,
        SpaceCardSoldOut,
        SpaceCardSkeleton,
        SpaceCardPromoFrameIcon,
        Tags,
        SwiperContainer
    },
    props: {
        listItem: {
            type: Object,
            required: false,
            default: undefined
        },
        date: {
            type: String,
            default: undefined
        },
        numPpl: {
            type: Number,
            default: 0
        },
        isMapView: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        algoliaLang() {
            return getAlgoliaLang(this.paramsRegion, this.paramsLang)
        },
        isHotelSpaceType() {
            return this.globalSpaceType(this.$route.params.space) === 'rest'
        },
        isNonHotelSpaceType() {
            return (
                this.globalSpaceType(this.$route.params.space) === 'party' ||
                this.globalSpaceType(this.$route.params.space) === 'meet' ||
                this.globalSpaceType(this.$route.params.space) === 'work'
            )
        },
        spaceCardType() {
            return this.$route.params.space
        },
        daySpaceStatus() {
            return this.listItem?.status?.day_space_status
        },
        isAvailable() {
            return (
                this.listItem.button_list?.length > 0 &&
                this.listItem.isAvailable
            )
        }
    },
    methods: {
        getGAEventName,
        navigateToDetails(params) {
            const _params = {
                ...params,
                isMapView: this.isMapView
            }
            this.$emit('navigateToDetails', _params)
        },
        computeImageUrlWithVariant(url) {
            return this.resizeImage(url, 'public', 'w=500')
        },
        onMoreClicked(params) {
            this.navigateToDetails(params)
        },
        openDesktopMap(listItem) {
            this.updateMapPopupInfo(listItem)
            this.$emit('setDesktopMapVisible')
        },
        updateMapPopupInfo(listItem) {
            this.$emit('updateMapPopupInfo', listItem)
        },
        setAndDisplayMap() {
            this.$emit('setAndDisplayMap', this.listItem, 'SPACE_CARD')
        },
        onCardClicked() {
            this.$emit('navigateToDetails', {
                btn: getGAEventName(this.landingPageName,'SPACE_CARD', 'Elsewhere'),
                name: this.listItem?.name?.[this.algoliaLang],
                objectID: this.listItem?.id,
                queryID: this.listItem?.queryID,
                slug: this.listItem?.slug
            })
        }
    }
}
</script>

<style scoped>
.card-container {
    background-color: #ffffff;
    border-radius: 17px;
    cursor: pointer;
}
.space-card-swiper {
    border-radius: 12px;
    aspect-ratio: 16 / 9;
    height: auto;
}
.space-card-image {
    aspect-ratio: 16 / 9;
    height: auto;
    object-fit: cover;
}
.space-swiper-link:hover .view-map-button {
    opacity: 1;
    transition: opacity 0.3s ease;
}
.space-swiper-link {
    border-radius: 10px;
    cursor: pointer;
    position: relative;
}
.image-wrapper {
    position: relative;
}

.image-wrapper::before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 66.67%;
}

.image-wrapper > :first-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
@media (max-width: 991px) {
    .space-card-swiper {
        height: auto;
    }
    .space-card-image {
        height: auto;
        aspect-ratio: 3/2;
    }
    .mobile-featured-frame {
        border: 3px solid transparent;
        background: linear-gradient(#ffffff, #ffffff) padding-box,
            linear-gradient(180deg, #ffc000, #fedc01) border-box;
    }
}
@media (min-width: 991px) {
    .sold-out-opacity-desktop {
        opacity: 0.6;
    }
    .loaded-card:hover {
        box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
        border-radius: 17px;
        transform: translateY(-6px);
        transition: all 0.25s ease;
    }
    .nearby-distance{
        text-align: end;
        max-width: 300px;
    }
}
.sold-out-opacity {
    opacity: 0.6;
}
</style>
