<template>
    <GmapMap
        ref="map"
        :center="map.center"
        :zoom="deviceType() === 'desktop' ? 15 : 12"
        :options="map.option"
        map-type-id="roadmap"
        style="width: 100%; height: 100%"
    >
        <!--        <GmapMarker-->
        <!--            :visible="!isHideMarker && deviceType() !== 'mobile'"-->
        <!--            :position="map.popup.selected.coordinate"-->
        <!--            :clickable="false"-->
        <!--            :draggable="false"-->
        <!--            :animation="2"-->
        <!--            :options="{-->
        <!--                scaleControl: true,-->
        <!--                controlSize: 50-->
        <!--            }"-->
        <!--            :icon="{-->
        <!--                url: require('assets/images/icon/map-pin.png'),-->
        <!--                size: {-->
        <!--                    width: 46,-->
        <!--                    height: 46,-->
        <!--                    f: 'px',-->
        <!--                    b: 'px'-->
        <!--                },-->
        <!--                scaledSize: {-->
        <!--                    width: 23,-->
        <!--                    height: 23,-->
        <!--                    f: 'px',-->
        <!--                    b: 'px'-->
        <!--                },-->
        <!--                anchor: { x: 0, y: 0 }-->
        <!--            }"-->
        <!--        />-->
        <PriceMakerPopupInfo
            :key="`price-maker-${index}`"
            v-for="(item, index) in spaceResultHits"
            :coordinate="{
                lat: item._geoloc[0],
                lng: item._geoloc[1]
            }"
            :price="getPriceForPriceMarker(item)"
            :listItem="item"
            :index="index"
            :selectedCoordinate="map.popup.selected.coordinate"
            @updateMapPopupInfo="updateMapPopupInfo(item, index)"
        />
        <markerPopupInfo
            v-if="map.popup.visible && !isHideMarker"
            :id="map.popup.selected.id"
            :coordinate="map.popup.selected.coordinate"
            :image="map.popup.selected.image"
            :currency="map.popup.selected.currency"
            :price="map.popup.selected.price"
            :date="map.popup.selected.date"
            :type="map.popup.selected.type"
            :url-title="map.popup.selected.urlTitle"
            :space-name="map.popup.selected.spaceName"
            :num-ppl="numPpl"
            :selected-list-item="selectedListItem"
            @navigateToDetails="navigateToDetails"
            @setAndDisplayMap="setAndDisplayMap"
            @updateMapPopupInfo="updateMapPopupInfo"
            @closeMarker="isHideMarker = true"
        />
    </GmapMap>
</template>

<script>
import markerPopupInfo from '@/components/maps/info/list'
import PriceMakerPopupInfo from "@/components/maps/info/PriceMakerPopupInfo.vue";
export default {
    name: 'GMap',
    components: {PriceMakerPopupInfo, markerPopupInfo },
    props: {
        numPpl: {
            type: Number,
            default: 0
        },
        date: {
            type: String,
            default: undefined
        },
        spaceType: {
            type: String,
            default: undefined
        },
        inputPriceRange: {
            type: Array,
            default() {
                return []
            }
        },
        inputTimeFrame: {
            type: Array,
            default() {
                return []
            }
        },
        map: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        },
        selectedListItem: {
            type: Object,
            required: true,
            default: undefined
        }
    },
    data() {
        return {
            isHideMarker: false
        }
    },
    computed: {
        spaceResultHits() {
            return this.$store.getters['algoliaSearch/spaceResultHits']
        }
    },
    mounted() {},
    methods: {
        updateMapPopupInfo(item, index) {
            this.isHideMarker = false
            this.$emit('updateMapPopupInfo', item, index)
        },
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        },
        setAndDisplayMap(listItem) {
            this.$emit('setAndDisplayMap', listItem)
        },
        getPriceForPriceMarker(item) {
            const minPrices = item.minPrices?.today?.total
            const buttonPrice =
                item?.button_list?.length > 0 ? item?.button_list[0].price : ''
            const price =
                minPrices && minPrices !== '-' ?
                    minPrices : buttonPrice ? buttonPrice : 0
            return price
        }
    }
}
</script>

<style scoped></style>
