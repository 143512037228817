<template>
    <div
        class="d-flex flex-column align-items-center justify-content-center bg-f3f3f5 py-4 py-lg-5 sold-out-container"
    >
        <OutOfRoomSvg height="30px" width="30px" />
        <p class="text-reg text-1e1e1e text-13 mt-3">
            {{ $t('list_timeslot_btn.out_of_room_reminder') }}
        </p>
        <div v-if="nextAvailable" class="text-reg text-1e1e1e text-13">
            <span>
                {{ $t('list_timeslot_btn.sold_out_next_avail') }}
            </span>
            <span class="text-ffc000 text-bold text-14">
                {{ nextAvailable }}
            </span>
            <span>{{ $t('full_stop') }}</span>
        </div>
        <SpaceCardMoreButton
            :btn="getGAEventName(this.landingPageName, 'SPACE_CARD', 'sold out more 2')"
            :algolia-lang="globalAlgoliaLang"
            :dest-url="destUrl"
            :list-item="listItem"
            @onMoreClicked="onMoreClicked"
        >
            <CircleForwardButton :bg-color="'d9d9d9'" class="mt-3" />
        </SpaceCardMoreButton>
    </div>
</template>

<script>
import OutOfRoomSvg from '@/components/iconSvg/OutOfRoomSvg'
import CircleForwardButton from '@/components/item/CircleForwardButton.vue'
import SpaceCardMoreButton from '@/components/list/SpaceCardMoreButton.vue'
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'SpaceCardSoldOut',
    components: {
        SpaceCardMoreButton,
        CircleForwardButton,
        OutOfRoomSvg
    },
    props: {
        nextAvailable: {
            type: String,
            required: false,
            default: undefined
        },
        listItem: {
            type: Object,
            required: true
        },
        destUrl: {
            type: String,
            required: true
        },
        algoliaLang: {
            type: String,
            required: true
        }
    },
    methods: {
        getGAEventName,
        onMoreClicked(params) {
            this.$emit('onMoreClicked', params)
        }
    }
}
</script>

<style scoped>
.sold-out-container {
    border-radius: 17px;
}
</style>
