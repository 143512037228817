<template>
    <svg
        width="24px"
        height="24px"
        viewBox="0 0 64 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            :stroke="isSelected ? '#ffffff' : '#f2bc1f'"
            d="M32 18.8281L52.88 18.8281"
            stroke-width="4"
            stroke-linecap="round"
        />
        <path
            :stroke="isSelected ? '#ffffff' : '#f2bc1f'"
            d="M11.1201 18.8281L21.5601 18.8281"
            stroke-width="4"
            stroke-linecap="round"
        />
        <path
            :stroke="isSelected ? '#ffffff' : '#f2bc1f'"
            d="M45.0503 44.9282L52.8803 44.9282"
            stroke-width="4"
            stroke-linecap="round"
        />
        <path
            :stroke="isSelected ? '#ffffff' : '#f2bc1f'"
            d="M11.1201 44.9282L32.0001 44.9282"
            stroke-width="4"
            stroke-linecap="round"
        />
        <circle
            :stroke="isSelected ? '#ffffff' : '#f2bc1f'"
            cx="26.78"
            cy="18.8282"
            r="5.22"
            transform="rotate(90 26.78 18.8282)"
            stroke-width="4"
            stroke-linecap="round"
        />
        <circle
            :stroke="isSelected ? '#ffffff' : '#f2bc1f'"
            cx="39.8303"
            cy="44.928"
            r="5.22"
            transform="rotate(90 39.8303 44.928)"
            stroke-width="4"
            stroke-linecap="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'FilterSvg',
    props: {
        isSelected: {
            type: Boolean,
            default: false,
            required: true
        }
    }
}
</script>

<style scoped></style>
