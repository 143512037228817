<template>
    <PopularFilter
        :date="date"
        :num-ppl="numPpl"
        :space="space"
        :type="type"
        :slug="slug"
        :device="device"
        :is-display-btn="isDisplayBtn"
        :name="'swimming-pool'"
        :hits="hits"
        :btn-name="eventBtnName('Location', 'swimming pool')"
        @navigateToDetails="navigateToDetails"
    >
        {{
            getLocationHeader(
                'swimming_pool_space',
                query,
                space,
                localeKeyword
            )
        }}
    </PopularFilter>
</template>

<script>
import dateServices from '@/services/dateServices'
import PopularFilter from '~/components/location/PopularFilter'

export default {
    name: 'SwimmingPoolSpace',
    components: {
        PopularFilter
    },
    props: {
        date: {
            type: String,
            default: dateServices.getDateByFormat(new Date())
        },
        numPpl: {
            type: Number,
            default: 0
        },
        space: {
            type: String,
            default: 'hotel'
        },
        query: {
            type: String,
            required: true,
            default: undefined
        },
        type: {
            type: String,
            required: true,
            default: undefined
        },
        slug: {
            type: String,
            required: true,
            default: ''
        },
        device: {
            type: String,
            default: ''
        },
        isDisplayBtn: {
            type: Boolean,
            default: false
        },
        localeKeyword: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            outDoorName: {
                en: 'Outdoor swimming pool',
                tc: '室外泳池',
                sc: '室外泳池',
                ms: 'Kolan renang luar',
                vi: 'Bể bơi ngoài trời'
            },
            inDoorName: {
                en: 'Indoor swimming pool',
                tc: '室內泳池',
                sc: '室内泳池',
                ms: 'Kolam renang dalaman',
                vi: 'Bể bơi trong nhà'
            },
            filter: { f_swimming_pool: [] }
        }
    },
    async fetch() {
        await this.getPopularFilterHits()
    },
    computed: {
        hits() {
            // eslint-disable-next-line standard/computed-property-even-spacing
            const hits = this.$store.getters[
                'algoliaSearch/swimmingPoolSpaceResultHits'
            ]
            return hits.slice(0, 10)
        }
    },
    mounted() {
        this.getPopularFilterHits()
    },
    methods: {
        async getPopularFilterHits() {
            try {
                let params = this.getPopularFilterParams(this.space, this.query)
                this.filter.f_swimming_pool = [
                    this.outDoorName[this.globalAlgoliaLang],
                    this.inDoorName[this.globalAlgoliaLang]
                ]
                params = {
                    ...params,
                    ...this.filter,
                    isSkipAnalytics: true
                }
                await this.$store.dispatch(
                    'algoliaSearch/SEARCH_SWIMMING_POOL_SPACE_INDEX',
                    params
                )
            } catch (err) {
                this.$nuxt.error(err)
            }
        },
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        }
    }
}
</script>
