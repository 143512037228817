<template>
    <div class="text-14">
        <div v-if="!isLoadingSpace">
            <span>{{ $t('list.showing') }}</span>
            <span>
                {{ totalHits }}
            </span>
            <span>
                {{ totalHits > 1 ? $t('list.results') : $t('list.result') }}
            </span>
        </div>
        <TextSkeleton
            v-else
            width="120px"
            height="17px"
            class="skeleton-container"
        />
    </div>
</template>

<script>
import TextSkeleton from '~/components/item/TextSkeleton.vue'

export default {
    name: 'TotalResultsText',
    components: { TextSkeleton },
    props: {
        isLoadingSpace: {
            type: Boolean,
            required: true
        },
        totalHits: {
            type: Number,
            required: true
        }
    }
}
</script>

<style scoped>
.skeleton-container {
    height: 18px;
}
@media (min-width: 992px) {
    .skeleton-container {
        height: 21px;
    }
}
</style>
