var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.prevTimes.length > 0 || _vm.afterTimes.length > 0)?_c('div',[_c('p',{staticClass:"text-s-bold text-14 text-1e1e1e mt-2 start-time-title"},[_vm._v("\n        "+_vm._s(_vm.$t('list_timeslot_btn.other_start_time'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"row mt-2 mt-lg-0 pl-3"},[_vm._l((_vm.prevTimes),function(time,index){return _c('router-link',{key:index,staticClass:"start-time-link",attrs:{"to":{
                path: _vm.computeTimeslotPath(
                    _vm.listItem.slug,
                    time.book_type,
                    time.start_value,
                    time.end_value
                )
            }}},[_c('div',{staticClass:"start-time-button text-reg text-13 text-ffc000 bg-fff2cc py-2 px-3 mr-2 button-hover",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onTimeslotClicked({
                        btn: 'Time',
                        slug: _vm.listItem.slug,
                        name: _vm.listItem?.name?.[_vm.algoliaLang],
                        objectID: _vm.listItem?.id,
                        queryID: _vm.listItem?.queryID,
                        book_type: time.book_type,
                        start_value: time.start_value,
                        end_value: time.end_value
                    })}}},[_vm._v("\n                "+_vm._s(time.start_time_str)+"\n            ")])])}),_vm._v(" "),_c('div',{staticClass:"col-12 mb-2 d-none d-lg-block"}),_vm._v(" "),_vm._l((_vm.afterTimes),function(time,index){return _c('router-link',{key:index + 4,staticClass:"start-time-link",attrs:{"to":{
                path: _vm.computeTimeslotPath(
                    _vm.listItem.slug,
                    time.book_type,
                    time.start_value,
                    time.end_value
                )
            }}},[_c('div',{staticClass:"start-time-button text-reg text-13 text-ffc000 bg-fff2cc py-2 px-3 mr-2 button-hover",on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onTimeslotClicked({
                        btn: 'Time',
                        slug: _vm.listItem.slug,
                        name: _vm.listItem?.name?.[_vm.algoliaLang],
                        objectID: _vm.listItem?.id,
                        queryID: _vm.listItem?.queryID,
                        book_type: time.book_type,
                        start_value: time.start_value,
                        end_value: time.end_value
                    })}}},[_vm._v("\n                "+_vm._s(time.start_time_str)+"\n            ")])])})],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }