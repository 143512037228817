import { render, staticRenderFns } from "./TotalResultsText.vue?vue&type=template&id=92f2cf24&scoped=true"
import script from "./TotalResultsText.vue?vue&type=script&lang=js"
export * from "./TotalResultsText.vue?vue&type=script&lang=js"
import style0 from "./TotalResultsText.vue?vue&type=style&index=0&id=92f2cf24&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "92f2cf24",
  null
  
)

export default component.exports