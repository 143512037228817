<template>
    <div v-if="prevTimes.length > 0 || afterTimes.length > 0">
        <p class="text-s-bold text-14 text-1e1e1e mt-2 start-time-title">
            {{ $t('list_timeslot_btn.other_start_time') }}
        </p>
        <div class="row mt-2 mt-lg-0 pl-3">
            <router-link
                v-for="(time, index) in prevTimes"
                :to="{
                    path: computeTimeslotPath(
                        listItem.slug,
                        time.book_type,
                        time.start_value,
                        time.end_value
                    )
                }"
                :key="index"
                class="start-time-link"
            >
                <div
                    @click.prevent.stop="
                        onTimeslotClicked({
                            btn: 'Time',
                            slug: listItem.slug,
                            name: listItem?.name?.[algoliaLang],
                            objectID: listItem?.id,
                            queryID: listItem?.queryID,
                            book_type: time.book_type,
                            start_value: time.start_value,
                            end_value: time.end_value
                        })
                    "
                    class="start-time-button text-reg text-13 text-ffc000 bg-fff2cc py-2 px-3 mr-2 button-hover"
                >
                    {{ time.start_time_str }}
                </div>
            </router-link>
            <div class="col-12 mb-2 d-none d-lg-block" />
            <router-link
                v-for="(time, index) in afterTimes"
                :to="{
                    path: computeTimeslotPath(
                        listItem.slug,
                        time.book_type,
                        time.start_value,
                        time.end_value
                    )
                }"
                :key="index + 4"
                class="start-time-link"
            >
                <div
                    @click.prevent.stop="
                        onTimeslotClicked({
                            btn: 'Time',
                            slug: listItem.slug,
                            name: listItem?.name?.[algoliaLang],
                            objectID: listItem?.id,
                            queryID: listItem?.queryID,
                            book_type: time.book_type,
                            start_value: time.start_value,
                            end_value: time.end_value
                        })
                    "
                    class="start-time-button text-reg text-13 text-ffc000 bg-fff2cc py-2 px-3 mr-2 button-hover"
                >
                    {{ time.start_time_str }}
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpaceCardOtherTimes',
    props: {
        date: {
            required: true,
            type: String,
            default: undefined
        },
        listItem: {
            type: Object,
            required: true
        },
        prevTimes: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        afterTimes: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        algoliaLang: {
            type: String,
            required: true
        }
    },
    computed: {
        spaceCardType() {
            return this.$route.params.space
        }
    },
    methods: {
        computeTimeslotPath(slug, bookLength, startValue, endValue) {
            const path = `/${this.paramsRegion}/${this.paramsLang}/${slug}/${this.spaceCardType}`
            // if (this.numPpl > 0 && this.spaceCardType === 'party') {
            //     path += `&numPpl=${this.numPpl}`
            // }
            // if (bookLength) {
            //     path += `&booklength=${bookLength}`
            // }
            // if (startValue) {
            //     path += `&start=${startValue}`
            // }
            // if (endValue) {
            //     path += `&end=${endValue}`
            // }
            return path
        },
        onTimeslotClicked(params) {
            this.$emit('onTimeslotClicked', params)
        }
    }
}
</script>

<style scoped>
.start-time-link {
    text-decoration: none;
}
.start-time-button {
    border-radius: 6px;
    width: 64px;
    text-align: center;
}
.start-time-title {
    user-select: none;
}
</style>
