<template>
    <p v-if="exactHit.length > 0 && nearbyDistance" class="text-404040">
        {{ getRelativeDistance(nearbyDistance) }}
    </p>
</template>
<script>
import { getAlgoliaLang } from '@/services/algoliaApi'

export default {
    name: 'NearbyDistanceText',
    props: {
        nearbyDistance: {
            type: Number,
            default: undefined
        }
    },
    computed: {
        algoliaLang() {
            return getAlgoliaLang(this.paramsRegion, this.paramsLang)
        },
        exactHit() {
            return this.$store.getters['algoliaSearch/exactHit']
        }
    },
    methods: {
        getRelativeDistance(nearbyDistance) {
            return this.$t('list.relative_distance', {
                space_name: this.exactHit[0].name[this.algoliaLang],
                nearby_distance: nearbyDistance.toFixed(2)
            })
        }
    }
}
</script>
