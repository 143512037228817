<template>
    <div class="h-100">
        <div
            v-if="listItem?.status?.day_space_status"
            class="d-flex flex-column justify-content-between h-100"
        >
            <div>
                <div
                    v-if="
                        (isMapView &&
                            (listItem?.ranking?.is_featured || listItem?.ranking?.is_promotion) &&
                            listItem?.frame_style?.content) ||
                            !isMapView
                    "
                    :style="featuredTitleStyle(listItem)"
                    class="d-flex align-items-center justify-content-end featured-title"
                >
                    <span
                        v-if="
                             (listItem?.ranking?.is_featured || listItem?.ranking?.is_promotion) &&
                                listItem?.frame_style?.content
                        "
                    >
                        {{
                            listItem?.frame_style?.content[
                                algoliaLang
                            ] ?? ''
                        }}
                    </span>
                    <RecommendSvg
                        v-if="
                            (listItem?.ranking?.is_featured || listItem?.ranking?.is_promotion)
                        "
                        :style="featuredTitleStyle(listItem)"
                        class="ml-1"
                    />
                </div>
                <router-link
                    :to="{
                        path: globalComputeLinkToDetails(listItem.slug, space)
                    }"
                    class="d-block"
                    style="border-radius: 10px; cursor: pointer;"
                >
                    <div
                        @click.prevent="
                            navigateToDetails({
                                btn: getGAEventName('MAP_SEARCH','SPACE_CARD', 'Image'),
                                slug: listItem?.slug,
                                name: listItem?.name?.[algoliaLang],
                                objectID: listItem?.id,
                                queryID: listItem?.queryID
                            })
                        "
                        :class="{
                            'frame-shadow': listItem?.ranking?.is_featured || listItem?.ranking?.is_promotion,
                            'empty-image-container':
                                listItem.images.length === 0
                        }"
                        class="image-container round-image-swiper-wrapper"
                    >
                        <SwiperContainer
                            :swiper-pagination="true"
                            :name="listItem?.slug"
                            :type="type"
                            :style="featuredFrameStyle(listItem)"
                        >
                            <div
                                v-for="(image, stepIndex) in listItem.images"
                                :key="stepIndex"
                                class="swiper-slide image-wrapper w-100"
                                style="cursor: pointer;"
                            >
                                <ImageContainer
                                    :src="computeImageUrlWithVariant(image)"
                                    :alt="listItem?.name?.[algoliaLang]"
                                    :style="
                                        listItem.isAvailable &&
                                        listItem.button_list?.length > 0
                                            ? ''
                                            : 'filter: opacity(0.5);'
                                    "
                                    :loading="
                                        stepIndex !== 0 ? 'lazy' : 'eager'
                                    "
                                    class="item-image"
                                    height="216"
                                    width="400"
                                />
                            </div>
                        </SwiperContainer>
                        <HeartButton
                            :slug="listItem.slug"
                            :space-name="listItem?.name?.[algoliaLang]"
                            :space="$route.params.space"
                            class="position-absolute"
                            style="right: 0; top: 3%; z-index: 1;"
                        />
                        <div
                            class="position-absolute m-3 text-right d-flex flex-column"
                            style="top: 0; left: 0; z-index: 1;"
                        >
                            <span
                                v-if="
                                    (!listItem.isAvailable ||
                                        !listItem.button_list ||
                                        listItem.button_list.length === 0) &&
                                        listItem.status?.day_space_status
                                "
                                :style="
                                    'backgroundColor:' +
                                        getTagColor('flow-yellow')
                                "
                                class="text-light global-space-tag text-mid mb-2"
                                >{{ tagText(listItem.status.day_space_status) }}
                            </span>
                            <span
                                v-for="(tag, e) in listItem.image_tags"
                                v-if="
                                    listItem.image_tags &&
                                        listItem.isAvailable &&
                                        tag.content
                                "
                                :key="e"
                                :style="
                                    `backgroundColor: ${getTagColor(
                                        tag.colour
                                    )}`
                                "
                                class="text-light global-space-tag text-mid mb-2"
                            >
                                {{ tag.name }}
                            </span>
                        </div>
                        <HeartButton
                            :slug="listItem.slug"
                            :space-name="listItem?.name?.[algoliaLang]"
                            :space="$route.params.space"
                            class="position-absolute"
                            style="right: 0; top: 3%; z-index: 1;"
                        />
                        <b-button
                            v-if="!isMapView"
                            class="position-absolute align-items-center view-map-button d-none d-lg-flex"
                            variant="transparent"
                            style="bottom: 8px; right: 8px; background-color: white;z-index: 1;"
                            @click.stop.prevent="openDesktopMap(listItem)"
                        >
                            <svg
                                height="15"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                <path
                                    d="M497.1 222.1l-208.1-208.1c-9.364-9.364-21.62-14.04-33.89-14.03C243.7 .0092 231.5 4.686 222.1 14.03L14.03 222.1C4.676 231.5 .0002 243.7 .0004 255.1c.0002 12.26 4.676 24.52 14.03 33.87l208.1 208.1C231.5 507.3 243.7 511.1 256 511.1c12.26 0 24.52-4.677 33.87-14.03l208.1-208.1c9.352-9.353 14.03-21.61 14.03-33.87C511.1 243.7 507.3 231.5 497.1 222.1zM410.5 252l-96 84c-10.79 9.545-26.53 .9824-26.53-12.03V272H223.1l-.0001 48C223.1 337.6 209.6 352 191.1 352S159.1 337.6 159.1 320V240c0-17.6 14.4-32 32-32h95.1V156c0-13.85 16.39-20.99 26.53-12.03l96 84C414 231 415.1 235.4 415.1 240S414 249 410.5 252z"
                                />
                            </svg>
                            <div class="ml-1 text-13">
                                {{ $t('list.view_on_map') }}
                            </div>
                        </b-button>
                    </div>
                </router-link>
                <div>
                    <div
                        class="d-flex justify-content-between align-items-start my-2"
                    >
                        <h2
                            v-if="routeName === 'region-locale-space'"
                            class="space-name text-404040 text-s-bold text-left align-self-center mr-2"
                        >
                            {{ listItem?.name?.[algoliaLang] }}
                        </h2>
                        <div
                            v-else
                            class="space-name text-404040 text-s-bold text-left align-self-center mr-2"
                        >
                            {{ listItem?.name?.[algoliaLang] }}
                        </div>
                        <div class="flex-center mt-1 mt-lg-1">
                            <UserRating
                                v-if="listItem?.ranking?.user_rating"
                                :rating="listItem?.ranking?.user_rating"
                                class="text-19"
                            />
                            <TotalComment
                                v-if="listItem.ranking.total_comments"
                                :comments="listItem.ranking.total_comments"
                                class="ml-1"
                            />
                        </div>
                    </div>
                    <div class="d-flex align-items-center mb-2">
                        <StarRating
                            :star-rating="listItem.ranking?.star_rating"
                        />
                        <div
                            class="d-flex align-items-center w-100 justify-content-end"
                        >
                            <LocationIcon class="mr-1" />
                            <router-link
                                v-if="
                                    listItem?.location?.area?.has_page &&
                                        !hideLocationLink(
                                            listItem?.location?.area?.slug
                                        )
                                "
                                :to="{
                                    path: navigateToMap(listItem)
                                }"
                            >
                                <TextButton
                                    class="space-area active-space-area text-14"
                                    >{{
                                        listItem?.location?.area?.name?.[
                                            algoliaLang
                                        ]
                                    }}</TextButton
                                >
                            </router-link>
                            <div v-else class="space-area text-14">
                                {{
                                    listItem?.location?.area?.name?.[
                                        algoliaLang
                                    ]
                                }}
                            </div>
                        </div>
                    </div>
                    <Tags :tags="listItem.fomo_tags" />
                    <div
                        class="d-flex justify-content-between align-items-start"
                    >
                        <div>
                            <p
                                v-if="exactHit && listItem.nearbyDistance"
                                class="text-404040 text-reg text-12 mt-2"
                            >
                                {{
                                    getRelativeDistance(listItem.nearbyDistance)
                                }}
                            </p>
                            <div
                                v-if="
                                    listItem.status?.is_refundable ||
                                        listItem.space_tags?.length > 0
                                "
                                class="d-flex align-items-center flex-wrap mt-2"
                            >
                                <span
                                    v-if="listItem.status.is_refundable"
                                    class="text-13 text-primary mr-3 mt-1"
                                    style="border-radius: 7px; border: solid 1px #F2BC1F; font-weight: 500; padding: 1px 8px; white-space: nowrap"
                                >
                                    {{ $t('list.free_cancellation') }}
                                </span>
                                <Tags :tags="listItem.space_tags" />
                            </div>
                        </div>
                        <div class="d-flex flex-column align-items-end">
                            <b-button
                                aria-label="display map"
                                class="bg-transparent map-toggle-btn border-0 px-1 py-0 mt-2"
                                @click="setAndDisplayMap(listItem)"
                            >
                                <ShowMapSvg fill-color="#606060" />
                            </b-button>
                        </div>
                    </div>
                </div>
                <div
                    v-if="listItem.top_comments?.length > 0"
                    class="mt-2 d-flex align-items-end justify-content-between"
                >
                    <p
                        v-for="(comment, commentIndex) in listItem.top_comments"
                        v-if="commentIndex === 0"
                        :key="commentIndex"
                        class="d-block text-12 mr-1 text-primary"
                    >
                        {{ comment }}
                    </p>
                </div>
            </div>
            <div
                v-if="
                    space !== 'staycation' &&
                        listItem.isAvailable &&
                        listItem.button_list?.length > 0
                "
                :class="
                    listItem.button_list?.length > 0
                        ? 'justify-content-end'
                        : 'justify-content-center'
                "
                class="d-flex flex-fill align-self-end justify-content-end align-content-end mt-3 w-100"
                style="border: 1px solid #e1e1e1; border-radius: 10px; height: 80px; max-height: 80px;"
            >
                <router-link
                    v-for="(btn, i) in listItem.button_list"
                    :key="i"
                    :to="{
                        path: navigatePath(
                            listItem.slug,
                            btn.book_type,
                            btn.start_value,
                            btn.end_value
                        )
                    }"
                    :class="
                        listItem.button_list?.length === 1 ? 'w-100' : 'w-50'
                    "
                    class="timeslot_btn p-0 m-0 py-3 px-2 btn position-relative"
                    style="border-radius: 0; border-right: 1px solid #e1e1e1; border-top: 0; border-bottom: 0; border-left: 0"
                >
                    <div
                        @click.prevent="
                            navigateToDetails({
                                btn: getGAEventName('MAP_SEARCH','SPACE_CARD', 'Quick button', i),
                                slug: listItem?.slug,
                                name: listItem?.name?.[algoliaLang],
                                objectID: listItem?.id,
                                queryID: listItem?.queryID,
                                book_type: btn.book_type,
                                start_value: btn.start_value,
                                end_value: btn.end_value
                            })
                        "
                        class="d-flex justify-content-center flex-column align-items-center"
                    >
                        <div class="d-flex align-items-center">
                            <div
                                v-if="btn.book_type === 'overnight'"
                                class="d-flex align-items-center"
                            >
                                <span class="text-12 text-ikonoMini-4 mr-1">
                                    
                                </span>
                                <span class="text-15 timeslot-btn-text">
                                    {{ btn.time }}
                                </span>
                            </div>
                            <div
                                v-else-if="
                                    btn.book_type === 'session' ||
                                        btn.book_type === 'hour'
                                "
                                class="d-flex align-items-center"
                            >
                                <span
                                    class="text-13 text-ikonoMini-4 align-self-center mr-1"
                                >
                                    
                                </span>
                                <div class="timeslot-btn-text">
                                    <span :class="btn.duration ? 'mr-1' : ''">
                                        {{ btn.duration }}
                                    </span>
                                    <span>
                                        {{ btn.time }}
                                    </span>
                                </div>
                            </div>
                            <div
                                v-else-if="btn.book_type === 'day'"
                                class="d-flex align-items-center"
                            >
                                <LightModeSvg
                                    style="width: 18px; height: 18px;"
                                    class="mr-2"
                                />
                                <span class="timeslot-btn-text">
                                    {{ btn.time }}
                                </span>
                            </div>
                            <div
                                v-else-if="btn.book_type === 'hourly'"
                                class="d-flex align-items-center"
                            >
                                <p
                                    class="text-15 text-ikonoMini-4 align-self-center mr-2"
                                >
                                    
                                </p>
                                <span class="timeslot-btn-text">
                                    {{ btn.book_type_string }}
                                </span>
                            </div>
                            <span
                                v-if="btn.cross_day > 0"
                                class="text-11 timeslot-btn-text mb-2"
                            >
                                {{ `+${btn.cross_day}` }}
                            </span>
                        </div>
                        <div class="d-flex align-items-baseline">
                            <p
                                v-if="btn.price"
                                class="text-18 font-weight-bolder"
                            >
                                {{ getDisplayPrice(btn.price) }}
                            </p>
                            <div
                                v-if="
                                    space === 'party' ||
                                        isShowPerHour(btn.book_type)
                                "
                                class="text-14 font-weight-bolder"
                            >
                                {{ `/${$t('details.hr')}` }}
                            </div>
                        </div>
                        <span
                            v-if="space === 'party' && btn.is_room_price"
                            class="text-13"
                        >
                            {{ `(${$t('details.whole_unit')})` }}
                        </span>
                        <span
                            v-if="btn.availability_tag"
                            :class="
                                getTagStyleByType(btn.availability_tag.type)
                            "
                            class="fomo-tag-timeslot"
                        >
                            {{ btn.availability_tag.content }}
                        </span>
                    </div>
                </router-link>
                <router-link
                    :to="{
                        path: globalComputeLinkToDetails(listItem.slug, space)
                    }"
                    :style="
                        listItem.button_list?.length === 1
                            ? 'width: 25%'
                            : 'width: 20%'
                    "
                    class="timeslot_btn py-3 px-2"
                    style="white-space: nowrap;"
                >
                    <div
                        @click.prevent="
                            navigateToDetails({
                                btn: getGAEventName('MAP_SEARCH','SPACE_CARD', 'More'),
                                slug: listItem?.slug,
                                name: listItem?.name?.[algoliaLang],
                                objectID: listItem?.id,
                                queryID: listItem?.queryID
                            })
                        "
                        class="text-13 text-bold"
                    >
                        {{ $t('list.more') }}
                    </div>
                </router-link>
            </div>
            <div
                v-else-if="!listItem.status.day_space_status"
                class="d-flex align-items-center justify-content-center"
            >
                <b-spinner variant="primary" />
            </div>
            <div
                v-if="
                    listItem.button_list?.length <= 0 && listItem.next_available
                "
                class="mt-2 mb-1"
            >
                <hr class="h-line-solid my-2" />
                <div class="d-flex justify-content-between">
                    <div class="text-bold">
                        {{ $t('list.earliest_available_date') }}
                    </div>
                    <div class="text-15 text-bold">
                        {{ listItem.next_available }}
                    </div>
                </div>
            </div>
        </div>
        <b-skeleton-wrapper v-else :loading="true">
            <template #loading>
                <b-skeleton-img
                    class="item-image skeleton-image"
                    aspect="0.9"
                    no-aspect
                />
                <b-skeleton class="mt-3 pt-2 pb-3" width="90%" />
                <b-skeleton class="mt-2 py-2 pb-3" width="65%" />
            </template>
        </b-skeleton-wrapper>
    </div>
</template>

<script>
import HeartButton from '~/components/item/HeartButton'
import StarRating from '~/components/item/StarRating'
import Tags from '~/components/item/Tags'
import colors from '~/themes/colors'
import SwiperContainer from '~/components/ui/SwiperContainer'
import { getAlgoliaLang } from '@/services/algoliaApi'
import TextButton from '@/components/item/TextButton'
import LocationIcon from '@/components/iconSvg/Location'
import ShowMapSvg from '@/components/iconSvg/ShowMapSvg'
import UserRating from '@/components/item/UserRating.vue'
import TotalComment from '@/components/item/TotalComment.vue'
import RecommendSvg from '~/components/iconSvg/RecommendSvg.vue'
import LightModeSvg from '@/components/iconSvg/LightModeSvg.vue'
import ImageContainer from "@/components/ui/ImageContainer.vue";
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'ListItem',
    components: {
        ImageContainer,
        LightModeSvg,
        RecommendSvg,
        TotalComment,
        UserRating,
        LocationIcon,
        TextButton,
        SwiperContainer,
        Tags,
        StarRating,
        HeartButton,
        ShowMapSvg
    },
    props: {
        listItem: {
            type: Object,
            default: undefined
        },
        date: {
            type: String,
            default: undefined
        },
        numPpl: {
            type: Number,
            default: 0
        },
        isMapView: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        space() {
            return this.$route.params.space
        },
        algoliaLang() {
            return getAlgoliaLang(
                this.$route.params.region,
                this.$route.params.locale
            )
        },
        exactHit() {
            return this.$store.getters['algoliaSearch/exactHit']
        },
        keyword() {
            return this.$route.params.keyword
        },
        routeName() {
            return this.$route.name
        }
    },
    methods: {
        getGAEventName,
        getTagColor(color) {
            switch (color) {
                case 'light-blue':
                    return colors.tagLightBlue
                case 'green':
                    return colors.tagGreen
                case 'deep-blue':
                    return colors.tagDeepBlue
                case 'flow-yellow':
                default:
                    return colors.tagYellow
            }
        },
        setAndDisplayMap(listItem) {
            this.$emit('setAndDisplayMap', listItem)
        },
        navigateToDetails(params) {
            const _params = {
                ...params,
                isMapView: this.isMapView
            }
            this.$emit('navigateToDetails', _params)
        },
        updateMapPopupInfo(listItem) {
            this.$emit('updateMapPopupInfo', listItem)
        },
        openDesktopMap(listItem) {
            this.updateMapPopupInfo(listItem)
            this.$emit('setDesktopMapVisible')
        },
        navigatePath(slug, bookLength, startValue, endValue) {
            const path = `/${this.region}/${this.lang}/${slug}/${this.space}`
            // if (this.numPpl > 0 && this.space === 'party') {
            //     path += `&numPpl=${this.numPpl}`
            // }
            // if (bookLength) {
            //     path += `&booklength=${bookLength}`
            // }
            // if (startValue) {
            //     path += `&start=${startValue}`
            // }
            // if (endValue) {
            //     path += `&end=${endValue}`
            // }
            return path
        },
        featuredTitleStyle(listItem) {
            const color = '#F2BC1F'
            const fill = color
            const fontStyle = 'normal'
            const fontWeight = '700'
            const fontSize = 16
            return {
                color,
                fill,
                fontSize: `${fontSize}px`,
                fontWeight,
                fontStyle
            }
        },
        featuredFrameStyle(listItem) {
            if (!listItem?.ranking?.is_featured && !listItem?.ranking?.is_promotion) {
                return {}
            }
            const color = '#F2BC1F'
            return {
                border: '6px solid',
                borderColor: color,
                borderRadius: '8px'
            }
        },
        getRelativeDistance(nearbyDistance) {
            return this.$t('list.relative_distance', {
                space_name: this.exactHit[0].name[this.algoliaLang],
                nearby_distance: nearbyDistance.toFixed(2)
            })
        },
        navigateToMap(listItem) {
            const locationSlug = listItem?.location?.area?.slug
            const tail = this.keyword ?? 'maps'
            return `/${this.paramsRegion}/${this.paramsLang}/${this.space}/${locationSlug}/${tail}`
        },
        hideLocationLink(locationSlug) {
            return this.$route.params.location === locationSlug
        },
        computeImageUrlWithVariant(url) {
            // if (this.deviceType() === 'mobile') {
            //     return this.resizeImage(url, 'public', 'medium')
            // }
            // return url
            return this.resizeImage(url, 'public', 'medium')
        },
        isShowPerHour(bookType) {
            return (
                (this.space === 'co-working' || this.space === 'meeting') &&
                bookType === 'hourly'
            )
        }
    }
}
</script>
<style scoped>
.timeslot_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #606060;
    text-align: center;
    text-decoration: none;
}
.timeslot_btn:hover {
    color: var(--primary);
}
.timeslot_btn:active {
    color: rgba(242, 188, 31, 0.6);
}
.swiper-slide {
    position: relative;
}
.swiper-slide::before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 66.67%;
}
.swiper-slide > :first-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1200 / 800;
}
.image-wrapper {
    position: relative;
}
.image-wrapper::before {
    display: block;
    content: '';
    width: 100%;
    padding-bottom: 66.67%;
}
.image-wrapper > :first-child {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.map-toggle-btn {
    display: none;
}
.space-name {
    font-size: 18px;
}
.space-area {
    letter-spacing: 1px;
    word-break: break-all;
    color: #9d9d9d;
}
.active-space-area:hover {
    text-decoration: underline;
    text-underline-offset: 0.35rem;
}
.timeslot-btn-text {
    display: flex;
    font-weight: 600;
    white-space: nowrap;
    font-size: 13px;
}
.view-map-button {
    display: flex;
    transition: 0s;
    opacity: 0;
}
.empty-image-container {
    aspect-ratio: 1200 / 800;
}
.image-container {
    border-radius: 12px;
}
.image-container:hover .view-map-button {
    opacity: 1;
    transition-delay: 0.5s;
}
@media (max-width: 575px) {
    .map-toggle-btn {
        display: flex;
    }
    .space-name {
        font-size: 19px;
    }
    .timeslot_btn {
        color: #404040;
    }
}
@media (min-width: 576px) {
    .featured-title {
        min-height: 32px;
    }
}
@media (min-width: 576px) and (max-width: 767.98px) {
    .map-toggle-btn {
        display: flex;
    }
}
.frame-shadow:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.4);
}
.fomo-tag-timeslot {
    position: absolute;
    bottom: -13px;
    white-space: nowrap;
}
@media (min-width: 992px) {
    .skeleton-image {
        margin-top: 32px;
    }
}
.skeleton-image {
    border-radius: 10px;
    height: 100%;
}
</style>
