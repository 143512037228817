<template>
    <div
        :style="computeFrameStyle"
        class="d-none d-lg-flex align-items-center featured-frame-container px-4"
    >
        <BrownFeatureFrameIcon
            v-if="frameStyle?.type === 'FEATURED'"
            class="mr-1"
        />
        <p
            :style="computeTitleStyle"
            class="text-bold text-15 ml-1 featured-frame-title"
        >
            {{ frameStyle?.content[algoliaLang] }}
        </p>
    </div>
</template>

<script>
import BrownFeatureFrameIcon from '@/components/item/BrownFeatureFrameIcon.vue'

export default {
    name: 'SpaceCardFeaturedFrame',
    components: {
        BrownFeatureFrameIcon
    },
    props: {
        frameStyle: {
            type: Object,
            required: false,
            default: undefined
        },
        algoliaLang: {
            type: String,
            required: true
        }
    },
    computed: {
        computeFrameStyle() {
            const gradient = ['#ffc000', '#fedc01']
            return {
                background: `linear-gradient(90deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`,
                height: '100%',
                width: '100%'
            }
        },
        computeTitleStyle() {
            return {
                color: '#765821'
            }
        }
    }
}
</script>

<style scoped>
.featured-frame-container {
    overflow: hidden;
    border-top-left-radius: 17px;
    border-top-right-radius: 17px;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    box-shadow: 12px 0 20px 0 #0000001a;
}
.featured-frame-title {
    user-select: none;
}
</style>
