<template>
    <PopularFilter
        :date="date"
        :num-ppl="numPpl"
        :space="space"
        :type="type"
        :slug="slug"
        :device="device"
        :is-display-btn="isDisplayBtn"
        :name="'popular-space'"
        :hits="hits"
        :header-type="headerType"
        :btn-name="btnName ?? `Popular ${space}`"
        @navigateToDetails="navigateToDetails"
        @handleBtnClick="$emit('handleBtnClick')"
    >
        <slot />
    </PopularFilter>
</template>

<script>
import dateServices from '@/services/dateServices'
import PopularFilter from '~/components/location/PopularFilter'
import _filter from 'lodash/filter'

export default {
    name: 'PopularSpace',
    components: {
        PopularFilter
    },
    props: {
        date: {
            type: String,
            default: dateServices.getDateByFormat(new Date())
        },
        numPpl: {
            type: Number,
            default: 0
        },
        space: {
            type: String,
            default: 'hotel'
        },
        query: {
            type: String,
            required: true,
            default: undefined
        },
        type: {
            type: String,
            required: true,
            default: undefined
        },
        slug: {
            type: String,
            required: true,
            default: ''
        },
        device: {
            type: String,
            default: ''
        },
        isDisplayBtn: {
            type: Boolean,
            default: false
        },
        localeKeyword: {
            type: String,
            default: 'maps'
        },
        headerType: {
            required: false,
            type: String,
            default: 'mobile'
        },
        btnName: {
            type: String,
            default: ''
        },
        spaceName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {}
    },
    async fetch() {
        await this.getPopularFilterHits()
    },
    computed: {
        availablePopularSpaceIn28DaysHits() {
            // eslint-disable-next-line standard/computed-property-even-spacing
            return this.$store.getters[
                'algoliaSearch/availablePopularSpaceIn28DaysHits'
            ]
        },
        hits() {
            const hits = this.availablePopularSpaceIn28DaysHits.slice(0, 10)
            return _filter(hits, (hit) => {
                return hit.slug !== this.spaceName
            })
        }
    },
    mounted() {
        if (!this.isBlockGooglebotFromButton) {
            this.getPopularFilterHits()
        }
    },
    methods: {
        async getPopularFilterHits() {
            try {
                const params = this.getPopularFilterParams(
                    this.space,
                    this.query
                )
                params.isSkipAnalytics = true
                await this.$store.dispatch(
                    'algoliaSearch/SEARCH_POPULAR_SPACE_INDEX',
                    params
                )
            } catch (err) {
                this.$nuxt.error(err)
            }
        },
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        }
    }
}
</script>
