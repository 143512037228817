<template>
    <div class="d-flex align-items-center flex-wrap">
        <StarRating :star-rating="listItem.ranking?.star_rating" class="mb-1" />
        <div
            v-if="
                listItem?.ranking?.user_rating ||
                    listItem.ranking?.total_comments
            "
            class="d-flex align-items-center mb-1"
        >
            <SpaceCardSeparator :separator-height="17" class="mx-2 mx-lg-3" />
            <ThumbSvg width="12px" height="12px" />
            <UserRating
                v-if="listItem?.ranking?.user_rating"
                :rating="listItem?.ranking?.user_rating"
                class="ml-2"
            />
            <TotalComment
                v-if="listItem.ranking?.total_comments"
                :comments="listItem.ranking?.total_comments"
                class="ml-1"
            />
        </div>
        <div class="d-flex align-items-center mb-1">
            <SpaceCardSeparator
                :separator-height="17"
                v-if="
                    listItem.ranking?.star_rating &&
                        listItem?.location?.area?.name?.[algoliaLang]
                "
                class="mx-2 mx-lg-3"
            />
            <SpaceCardLocation
                :algolia-lang="algoliaLang"
                :is-hide-location-link="
                    hideLocationLink(listItem?.location?.area?.slug)
                "
                :list-item="listItem"
                :type="'LISTING'"
            />
        </div>
    </div>
</template>

<script>
import SpaceCardSeparator from '@/components/list/SpaceCardSeparator'
import SpaceCardLocation from '@/components/list/SpaceCardLocation'
import StarRating from '@/components/item/StarRating'
import TotalComment from '@/components/item/TotalComment'
import UserRating from '@/components/item/UserRating'
import ThumbSvg from '@/components/iconSvg/ThumbSvg'

export default {
    name: 'SpaceCardRatingsLocation',
    components: {
        SpaceCardSeparator,
        SpaceCardLocation,
        StarRating,
        TotalComment,
        UserRating,
        ThumbSvg
    },
    props: {
        algoliaLang: {
            type: String,
            required: true
        },
        listItem: {
            type: Object,
            required: true,
            default: undefined
        }
    },
    methods: {
        hideLocationLink(locationSlug) {
            return this.$route.params.location === locationSlug || !locationSlug
        }
    }
}
</script>

<style scoped></style>
