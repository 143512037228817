<template>
    <div
        class="space-card-separator"
        :style="{ height: `${separatorHeight}px` }"
    />
</template>

<script>
export default {
    name: 'SpaceCardSeparator',
    props: {
        separatorHeight: {
            type: Number,
            required: false,
            default: 12
        }
    }
}
</script>

<style scoped>
.space-card-separator {
    background-color: #afafaf;
    height: 12px;
    width: 1px;
}
</style>
