<template>
    <div>
        <router-link
            v-for="(btn, index) in buttonList"
            :to="{
                path: computeTimeslotPath(
                    listItem.slug,
                    btn.book_type,
                    btn.start_value,
                    btn.end_value
                )
            }"
            :key="index"
            class="timeslot-link button-active"
        >
            <div
                @click.prevent.stop="
                    onTimeslotClicked({
                        btn: getGAEventName(landingPageName,'SPACE_CARD', 'Quick button', index),
                        slug: listItem.slug,
                        name: listItem?.name?.[algoliaLang],
                        objectID: listItem?.id,
                        queryID: listItem?.queryID,
                        book_type: btn.book_type,
                        start_value: btn.start_value,
                        end_value: btn.end_value
                    })
                "
                :class="buttonList.length - 1 !== index ? 'mb-2' : ''"
                class="row position-relative timeslot-click-area button-active"
            >
                <div class="col px-2">
                    <div v-if="btn.book_type === 'hourly'" class="slot-area">
                        <p class="text-reg text-12 text-center">
                            {{ $t('list_timeslot_btn.next_start_time') }}
                        </p>
                        <p class="text-bold mt-2 timeslot-text">
                            {{ btn.start_time_str }}
                        </p>
                    </div>
                    <div v-if="btn.book_type === 'day'" class="slot-area">
                        <p class="text-reg text-12 text-center">
                            {{ $t('list_timeslot_btn.day_pass_range') }}
                        </p>
                        <p
                            class="text-dark text-bold mt-2 timeslot-text"
                            style="white-space: nowrap"
                        >
                            {{ `${btn.start_time_str} - ${btn.end_time_str}` }}
                        </p>
                    </div>
                </div>
                <SpaceCardSeparator
                    :separator-height="48"
                    class="flex-shrink-1 flex-grow-0 flex-wrap"
                />
                <div class="col px-2">
                    <div v-if="btn.book_type === 'hourly'" class="slot-area">
                        <p class="text-reg text-12 text-center">
                            {{ $t('list_timeslot_btn.min_rental') }}
                        </p>
                        <p class="text-bold mt-2 timeslot-text">
                            {{ btn.fmt_min_duration }}
                        </p>
                    </div>
                    <div v-if="btn.book_type === 'day'" class="slot-area">
                        <p class="text-reg text-12 text-center">
                            {{ $t('list_timeslot_btn.durations') }}
                        </p>
                        <p class="text-bold mt-2 timeslot-text">
                            {{ btn.fmt_duration }}
                        </p>
                    </div>
                </div>
                <div
                    class="col d-flex flex-column align-items-start justify-content-center pl-0 pl-lg-2 px-2"
                >
                    <div
                        v-if="btn.book_type === 'hourly'"
                        class="d-flex align-items-center"
                    >
                        <p
                            class="text-15 text-ikonoMini-4 align-self-center mr-2"
                        >
                            
                        </p>
                        <span class="text-reg text-12">
                            {{ $t('list_timeslot_btn.hourly') }}
                        </span>
                    </div>
                    <div
                        v-else-if="btn.book_type === 'day'"
                        class="d-flex align-items-center"
                    >
                        <LightModeSvg
                            style="width: 18px; height: 18px;"
                            class="mr-2"
                        />
                        <span class="text-reg text-12">
                            {{ $t('list_timeslot_btn.day_pass') }}
                        </span>
                    </div>
                    <div class="d-flex align-items-center">
                        <span class="text-bold text-18">
                            {{ getDisplayPrice(btn.price) }}
                        </span>
                        <span class="text-reg text-12">
                            {{
                                btn.is_room_price
                                    ? $t('list_timeslot_btn.per_room')
                                    : $t('list_timeslot_btn.per_guest')
                            }}
                        </span>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import LightModeSvg from '@/components/iconSvg/LightModeSvg'
import SpaceCardSeparator from '@/components/list/SpaceCardSeparator'
import {getGAEventName} from "@/utils/analysis";
export default {
    name: 'SpaceCardPartyMeetTimeslots',
    components: {
        LightModeSvg,
        SpaceCardSeparator
    },
    props: {
        listItem: {
            type: Object,
            required: true
        },
        buttonList: {
            required: true,
            type: Array
        },
        date: {
            required: true,
            type: String,
            default: undefined
        },
        algoliaLang: {
            type: String,
            required: true
        }
    },
    computed: {
        spaceCardType() {
            return this.$route.params.space
        }
    },
    methods: {
        getGAEventName,
        computeTimeslotPath(slug, bookLength, startValue, endValue) {
            const path = `/${this.paramsRegion}/${this.paramsLang}/${slug}/${this.spaceCardType}`
            // if (this.numPpl > 0 && this.spaceCardType === 'party') {
            //     path += `&numPpl=${this.numPpl}`
            // }
            // if (bookLength) {
            //     path += `&booklength=${bookLength}`
            // }
            // if (startValue) {
            //     path += `&start=${startValue}`
            // }
            // if (endValue) {
            //     path += `&end=${endValue}`
            // }
            return path
        },
        onTimeslotClicked(params) {
            this.$emit('onTimeslotClicked', params)
        }
    }
}
</script>

<style scoped>
.timeslot-link {
    text-decoration: none;
}
.timeslot-click-area {
    border: 1px solid #d6d6d6;
    border-radius: 17px;
    color: #1e1e1e;
    width: 100%;
    margin: auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
    align-items: center;
}
.timeslot-click-area:hover {
    opacity: 0.6;
}
@media (max-width: 992px) {
    .timeslot-click-area {
        padding-top: 1.7rem;
        padding-bottom: 1.7rem;
    }
    .timeslot-text {
        font-size: 13px;
    }
}
@media (min-width: 993px) {
    .timeslot-text {
        font-size: 17px;
    }
}
.slot-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
</style>
