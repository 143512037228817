<template>
    <div>
        <!--        <h1 style="color: transparent; position: absolute; z-index: -50;">-->
        <!--            {{-->
        <!--                $t(-->
        <!--                    `list.title_space_type_${globalSpaceTypeNum(-->
        <!--                        $route.params.space-->
        <!--                    )}`,-->
        <!--                    { country: regionName() }-->
        <!--                )-->
        <!--            }}-->
        <!--        </h1>-->
        <SpaceSearchView />
        <div v-if="isHotel" class="px-4 mt-4 mb-5 main-list-container">
            <HeaderTitle>{{
                $t('intro.daycation_header', { country: regionName() })
            }}</HeaderTitle>
            <p class="mt-2 text-14">
                {{ $t('intro.daycation_content', { country: regionName() }) }}
            </p>
            <HeaderTitle class="mt-4">{{
                $t('intro.hourly_hotel_header', { country: regionName() })
            }}</HeaderTitle>
            <p class="mt-2 mb-4 text-14">
                {{
                    $t('intro.hourly_hotel_content', { country: regionName() })
                }}
            </p>
        </div>
    </div>
</template>
<script>
import _isEmpty from 'lodash/isEmpty'
import HeaderTitle from '@/components/home/HeaderTitle'
import SpaceSearchView from '@/components/ui/SpaceSearchView'
import { getSpaceNameFromService } from '@/utils/spaceUtils'
import dateServices from '@/services/dateServices'
export default {
    components: { HeaderTitle, SpaceSearchView },
    beforeRouteEnter(to, from, next) {
        next((vm) => {
            vm.prevRoute = from.fullPath
            vm.sendPageTrack()
        })
    },
    validate({ store, route, payload }) {
        if (!payload) {
            const region = route.params.region
            const lang = route.params.locale
            const space = route.params.space
            const isLocaleValid = store.state.localeRegion[region]?.includes(
                lang
            )
            if (!isLocaleValid) return false
            const isSpaceValid = store.state.spaces?.includes(space)
            if (!isSpaceValid) return false
        }
        return true
    },
    async asyncData({ store, route, error, payload, $device, redirect }) {
        try {
            let spaceTypeInfo = ''
            const space = route.params.space
            const lang = route.params.locale
            const region = route.params.region
            spaceTypeInfo = await store.dispatch('api/GET_SPACE_TYPE_INFO', {
                lang,
                region,
                all: 0
            })
            let deviceType = ''
            if ($device.isDesktop) {
                deviceType = 'desktop'
            } else if ($device.isTablet) {
                deviceType = 'tablet'
            } else if ($device.isMobile) {
                deviceType = 'mobile'
            }
            let find = true
            if (spaceTypeInfo && spaceTypeInfo.length > 0) {
                find = spaceTypeInfo.find((item) => {
                    return item.appclip_space_type === space
                })
            }
            if (!find && space !== 'hotel') {
                let path = `/${region}/${lang}/hotel`
                if (route.query.date) path += `?date=${route.query.date}`
                redirect(path)
            } else {
                await store.dispatch('api/GET_SEARCH_BAR_SETTING', {
                    space_type: getSpaceNameFromService(space),
                    region: route.params.region,
                    lang: route.params.locale,
                    device: deviceType
                })
            }
            /* Need to test with SSG
            const obj = {
                lang: route.params.locale,
                region: route.params.region,
                all: 0
            }
            await store.dispatch('api/GET_SPACE_TYPE_INFO', obj)
            */
        } catch (err) {
            error(err)
        }
    },
    data() {
        return {
            prevRoute: null
        }
    },
    head() {
        const tail = _isEmpty(this.metaDataObject)
            ? `def_${this.globalSpaceTypeNum(this.$route.params.space)}`
            : this.globalSpaceTypeNum(this.$route.params.space)
        return {
            title: this.$t('list.meta_title_' + tail, this.metaDataObject),
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.$t(
                        'list.meta_description_' + tail,
                        this.metaDataObject
                    )
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: this.$t(
                        'list.meta_title_' + tail,
                        this.metaDataObject
                    )
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: this.$t(
                        'list.meta_description_' + tail,
                        this.metaDataObject
                    )
                },
                {
                    hid: 'og:url',
                    property: 'og:url',
                    content: 'https://app.flowtheroom.com' + this.$route.path
                },
                {
                    hid: 'twitter:title',
                    name: 'twitter:title',
                    content: this.$t(
                        'list.meta_title_' + tail,
                        this.metaDataObject
                    )
                },
                {
                    hid: 'twitter:description',
                    name: 'twitter:description',
                    content: this.$t(
                        'list.meta_description_' + tail,
                        this.metaDataObject
                    )
                },
                {
                    hid: 'twitter:url',
                    name: 'twitter:url',
                    content: 'https://app.flowtheroom.com' + this.$route.path
                },
                {
                    hid: 'keywords',
                    name: 'keywords',
                    content: this.queryInput
                }
            ],
            ...this.postAffiliateProTracker
        }
    },
    computed: {
        searchingSeo() {
            return this.$store.getters['api/searchingSeo']
        },
        metaDataObject() {
            const obj = {
                country: this.regionName(),
                currentYear: dateServices.getCurrentYear()
            }
            if (this.searchingSeo?.min_p) {
                obj.min_price =
                    this.searchingSeo?.currency + this.searchingSeo?.min_p
            }
            if (this.searchingSeo?.min_r) {
                obj.min_rate =
                    this.searchingSeo?.currency + this.searchingSeo?.min_r
            }
            if (this.$route.query?.q) {
                obj.query = this.metaQueryInput
            }
            return obj
        },
        isHotel() {
            return this.$route.params.space === 'hotel'
        },
        metaQueryInput() {
            return this.$route.query?.q + ' ｜ ' || ''
        },
        queryInput() {
            return this.$route.query?.q || ''
        }
    },
    methods: {
        sendPageTrack() {
            const type = this.globalSpaceTypeNum(this.space)
            const params = {
                page_title: this.$t('list.meta_title_def_' + type, {
                    country: this.regionName()
                })
            }
            this.globalSendPageTrack(params, this.prevRoute)
        }
    }
}
</script>
