<template>
    <ImageContainer
        alt="desktop crown icon"
        width="17"
        height="17"
        src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/2316b78a-9f53-45c4-55b4-6e26ff229700/public"
        style="user-select: none;"
    />
</template>

<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'BrownFeatureFrameIcon',
    components: {ImageContainer}
}
</script>
