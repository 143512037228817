<template>
    <div class="d-flex d-lg-none">
        <div class="crown-feature-icon flex-center mt-n2 mr-n2">
            <ImageContainer
                alt="popular icon"
                width="21"
                height="20"
                src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/6263a19b-5689-4b0b-6057-3b0657a9c500/public"
            />
        </div>
    </div>
</template>
<script>
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'CrownFeatureIcon',
    components: {ImageContainer},
    methods: {
        handleBtnClick() {
            this.$emit('handleBtnClick')
        }
    }
}
</script>
<style scoped>
.crown-feature-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: linear-gradient(90deg, #fedc01, #ffc000);
}
</style>
