<template>
    <router-link
        :to="{
            path: globalComputeLinkToDetails(listItem?.slug, space)
        }"
    >
        <div
            @click.prevent="navigateToDetails(listItem)"
            class="w-100 d-flex flex-column card image-container mb-3 btn-active-shrink card-shadow"
            style="cursor: pointer;"
        >
            <div class="position-relative card-top-section">
                <ImageContainer
                    v-if="listItem.images?.length > 0"
                    isResize
                    target="w=400"
                    :src="listItem.images[0]"
                    :alt="listItem?.slug"
                    class="item-image"
                    width="400"
                />
                <div
                    class="position-absolute m-3 text-right d-flex flex-column"
                    style="top: 0; left: 0; z-index: 1;"
                >
                    <span
                        v-if="
                            (!listItem.isAvailable ||
                                !listItem.button_list ||
                                listItem.button_list?.length === 0) &&
                                listItem.status?.day_space_status
                        "
                        :style="'backgroundColor:' + getTagColor('flow-yellow')"
                        class="d-inline-flex text-light text-reg px-3 py-2 button-box-shadow mb-2 py-1 align-self-start"
                        style="border-radius: 20px; word-break: break-all; font-weight: 600"
                        >{{ tagText(listItem.status.day_space_status) }}
                    </span>
                </div>
            </div>
            <div
                class="py-2 px-3 card-content card-bottom-section"
            >
                <div>
                    <p
                        class="space-name hide-overflow-text text-s-bold text-404040"
                        style="-webkit-line-clamp: 1;"
                    >
                        {{ listItem?.name?.[algoliaLang] }}
                    </p>
                    <div class="d-flex align-items-center mt-1">
                        <UserRating
                            v-if="listItem?.ranking?.user_rating"
                            :rating="listItem?.ranking?.user_rating"
                            class="text-16"
                        />
                        <TotalComment
                            v-if="listItem.ranking.total_comments"
                            :comments="listItem.ranking.total_comments"
                            class="ml-1"
                        />
                        <div class="flex-grow-1" />
                        <SpaceCardLocation
                            :algolia-lang="algoliaLang"
                            is-hide-location-link
                            :list-item="listItem"
                            is-hide-icon
                        />
                    </div>
                </div>
                <div>
                    <div
                        v-if="isUnlistedHourlyHotel"
                        class="earliest-available text-404040 px-2 py-1 py-sm-2 mt-1 d-lg-flex align-items-center justify-content-between mx-n1"
                    >
                        <div class="text-12 mr-1 mr-lg-2">
                            {{ $t('list.hourly_hotel_min_duration_title') }}
                        </div>
                        <div class="earliest-available-date text-s-bold" style="white-space: nowrap;">
                            {{ $t('list.hourly_hotel_min_duration') }}
                        </div>
                    </div>
                    <div
                        v-else-if="listItem.next_available"
                        class="earliest-available text-404040 px-2 py-1 py-sm-2 mt-1 d-lg-flex align-items-center justify-content-between mx-n1"
                    >
                        <div class="text-12 mr-1 mr-lg-2">
                            {{ $t('list.earliest_available_date') }}
                        </div>
                        <div class="earliest-available-date text-s-bold" style="white-space: nowrap;">
                            {{
                                nextAvailableDisplayDate(
                                    listItem.next_available
                                )
                            }}
                        </div>
                    </div>
                    <div class="d-flex justify-content-end">
                        <MinPrice
                            :space-type="globalSpace()"
                            :price="getMinPrices(listItem?.status)"
                            :is-room-price="listItem?.is_room_price"
                            class="text-18 mt-1"
                        />
                    </div>
                </div>
            </div>
        </div>
    </router-link>
</template>

<script>
import { getAlgoliaLang } from '@/services/algoliaApi'
import colors from '@/themes/colors'
import TextButton from '@/components/item/TextButton'
import LocationIcon from '@/components/iconSvg/Location'
import MinPrice from '@/components/item/MinPrice'
import UserRating from '@/components/item/UserRating'
import TotalComment from '@/components/item/TotalComment'
import dateServices from '@/services/dateServices'
import SpaceCardLocation from '@/components/list/SpaceCardLocation'
import ImageContainer from "@/components/ui/ImageContainer.vue";
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'PopularSpaceCard',
    components: {
        ImageContainer,
        TotalComment,
        UserRating,
        MinPrice,
        LocationIcon,
        TextButton,
        SpaceCardLocation
    },
    props: {
        listItem: {
            type: Object,
            default: undefined
        },
        date: {
            type: String,
            required: true,
            default: undefined
        },
        numPpl: {
            type: Number,
            default: 0
        },
        space: {
            type: String,
            required: true,
            default: undefined
        },
        slug: {
            type: String,
            required: true,
            default: ''
        },
        btnName: {
            type: String,
            required: true
        },
        isUnlistedHourlyHotel: {
            required: false,
            type: Boolean,
            default: false
        },
        position: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {}
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        algoliaLang() {
            return getAlgoliaLang(
                this.$route.params.region,
                this.$route.params.locale
            )
        },
        keyword() {
            return this.$route.params.keyword
        },
        location() {
            return this.$route.params.location
        },
        hideLocationLink() {
            return this.location === this.slug
        }
    },
    methods: {
        navigateToDetails(listItem) {
            const params = {
                btn: getGAEventName(this.landingPageName, this.btnName, this.slug, this.position),
                slug: listItem?.slug,
                name: listItem?.name?.[this.algoliaLang],
                objectID: listItem?.id,
                queryID: listItem?.queryID,
                startDate: dateServices.getToday(),
                space: this.paramsSpace
            }
            this.$emit('navigateToDetails', params)
        },
        tagText(daySpaceStatus) {
            switch (daySpaceStatus) {
                case 'AVAILABLE':
                    return this.$t('list.sold_out')
                case 'FULL':
                    return this.$t('list.sold_out')
                case 'CLOSED':
                    return this.$t('list.closed')
                case 'STOPPED':
                    return this.$t('list.unavailable')
                case 'QUARANTINE':
                    return this.$t('list.unavailable')
                default:
                    return ''
            }
        },
        getTagColor(color) {
            switch (color) {
                case 'light-blue':
                    return colors.tagLightBlue
                case 'green':
                    return colors.tagGreen
                case 'deep-blue':
                    return colors.tagDeepBlue
                case 'flow-yellow':
                default:
                    return colors.tagYellow
            }
        },
        // navigatePath(slug, bookLength, startValue, endValue) {
        //     let path = `/${this.region}/${this.lang}/${slug}/${this.space}?date=${this.date}`
        //     if (this.numPpl > 0 && this.space === 'party') {
        //         path += `&numPpl=${this.numPpl}`
        //     }
        //     if (bookLength) {
        //         path += `&booklength=${bookLength}`
        //     }
        //     if (startValue) {
        //         path += `&start=${startValue}`
        //     }
        //     if (endValue) {
        //         path += `&end=${endValue}`
        //     }
        //     return path
        // },
        getMinPrices(status) {
            let minPrice = ''
            let bookTypes = ''
            switch (this.paramsSpace) {
                case 'hotel':
                    bookTypes = ['hour', 'session', 'overnight']
                    break
                case 'co-working':
                case 'meeting':
                    bookTypes = ['hourly', 'day']
                    break
                case 'party':
                    bookTypes = ['hourly']
                    break
                default:
                    bookTypes = ['hour', 'session', 'overnight']
                    break
            }
            minPrice = this.getBookTypesMinPrice(status, bookTypes, 'total')
            return minPrice && minPrice !== '-'
                ? this.getDisplayPrice(minPrice)
                : ''
        },
        getBookTypesMinPrice(status, bookTypes, duration) {
            const arr = []
            bookTypes.map((bookType) => {
                const bookTypeMinPrice = this.getMinPricesPerBookType(
                    bookType,
                    duration,
                    [status]
                )
                if (
                    bookTypeMinPrice !== undefined &&
                    bookTypeMinPrice !== '-' &&
                    bookTypeMinPrice !== ''
                ) {
                    arr.push(bookTypeMinPrice)
                }
            })
            return this.compareMinPrices(arr)
        },
        getMinPricesPerBookType(bookLength, duration = 'perHour', hits) {
            const arr = []
            hits.map((hit) => {
                const price =
                    hit?.min_prices?.perBookType[bookLength]?.day28[duration]
                if (price !== undefined && price !== '') {
                    arr.push(price)
                }
            })
            return this.compareMinPrices(arr)
        },
        compareMinPrices(arr) {
            let minPrice = ''
            if (arr.length > 0) minPrice = arr.reduce((a, b) => Math.min(a, b))
            return minPrice
        },
        nextAvailableDate(nextAvailable) {
            return nextAvailable ? nextAvailable.split(' ')[0] : this.date
        },
        nextAvailableDisplayDate(nextAvailable) {
            const date = this.nextAvailableDate(nextAvailable)
            return dateServices.getToday() === date
                ? this.$t('home.today')
                : dateServices.getTomorrow() === date
                    ? this.$t('home.tomorrow')
                    : dateServices.formatSearchBarDisplayDate(date)
        }
    }
}
</script>
<style scoped>
.card {
    border-radius: 10px;
    aspect-ratio: 1;
}
.item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    aspect-ratio: 1200 / 800;
}
.space-name {
    font-size: 17px;
    -webkit-line-clamp: 2;
}
.image-container {
    /*fix Overflow hidden + round corners not working on Safari bug*/
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}
.card-content {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
@media (max-width: 576px) {
    .space-name {
        font-size: 13px;
        -webkit-line-clamp: 1;
    }
}
@media (max-width: 991px) {
    .space-name {
        font-size: 15px;
    }
    .card-top-section {
        height: 35%;
    }
    .card-bottom-section {
        height: 100%;
    }
    .earliest-available-date {
        font-size: 12px;
    }
}
@media (min-width: 992px) {
    .card-top-section {
        height: 40%;
    }
    .earliest-available-date {
        font-size: 13px;
    }
}
@media (min-width: 992px) and (max-width: 1500px) {
    .space-name {
        font-size: 15px;
    }
}
a:hover {
    text-decoration: none;
}
.earliest-available {
    background: #f2f3f3;
    border-radius: 6px;
}
</style>
