<template>
    <gmap-custom-marker
        :marker="coordinate"
        :offset-y="27"
        :offset-x="12"
        :zIndex="isMarkerSelected ? highestZIndex : originalZIndex"
    >
        <div @click="updateMapPopupInfo">
            <div
                v-if="price > 0"
                :class="isMarkerSelected ? 'selected-price-marker' : 'unselected-price-marker'"
                class="text-15 price-marker">
                {{ getDisplayPrice(price) }}
            </div>
            <!--            <div-->
            <!--                class="d-flex justify-content-center position-relative"-->
            <!--            >-->
            <!--                <div class="bottomTriangle"></div>-->
            <!--            </div>-->
        </div>
    </gmap-custom-marker>
</template>

<script>
import GmapCustomMarker from 'vue2-gmap-custom-marker'
import ListItem from '@/components/list/ListItem'
import CircleCloseBtn from '~/components/item/CircleCloseBtn'

export default {
    name: 'PriceMakerPopupInfo',
    components: {
        CircleCloseBtn,
        ListItem,
        'gmap-custom-marker': GmapCustomMarker
    },
    props: {
        coordinate: {
            type: Object,
            required: true,
            default: undefined
        },
        price: {
            type: Number,
            required: true,
            default: 0
        },
        listItem: {
            type: Object,
            required: true,
            default: undefined
        },
        index: {
            type: Number,
            required: true,
            default: 0
        },
        selectedCoordinate: {
            type: Object,
            required: true,
            default: undefined
        }
    },
    data() {
        return {
            updateSwiper: 0,
            highestZIndex: 100,
            originalZIndex: 50
        }
    },
    computed: {
        lang() {
            return this.$route.params.locale
        },
        region() {
            return this.$route.params.region
        },
        space() {
            return this.$route.params.space
        },
        isMarkerSelected() {
            return (
                this.selectedCoordinate.lat === this.coordinate.lat &&
                this.selectedCoordinate.lng === this.coordinate.lng
            )
        }
    },
    mounted() {
        setTimeout(() => {
            this.updateSwiper += 1
        }, 300)
    },
    methods: {
        updateMapPopupInfo() {
            this.$emit('updateMapPopupInfo', this.listItem, this.index)
        }
    }
}
</script>

<style scoped>
/*.bottomTriangle {
    position: absolute;
    width: 0;
    height: 0;
    top: -9px;
    border-bottom: 16px solid var(--light);
    border-left: 16px solid transparent;
    border-right: 2px solid #d5d5d5;
    transform: rotate(45deg);
    z-index: 100;
}*/
.price-marker {
    box-shadow: rgb(0 0 0 / 30%) 0 1px 4px -1px;
    border-radius: 15px;
    font-weight: 700;
    padding: 3px 12px;
    font-family: 'Poppins';
    cursor: pointer;
}

.selected-price-marker {
    background-color: var(--palette-gold);
}

.unselected-price-marker {
    background-color: white;
}
</style>
