<template>
    <svg
        width="9"
        height="10"
        viewBox="0 0 9 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.00002 5.97827V6.22978C8.98505 6.27185 8.96766 6.31324 8.95555 6.35621C8.88863 6.59557 8.74158 6.7724 8.5406 6.90985C8.55931 6.94449 8.57758 6.97689 8.59475 7.00996C8.68699 7.18858 8.72595 7.38182 8.69623 7.57822C8.65066 7.87922 8.49106 8.11251 8.23593 8.27178C8.18749 8.30193 8.19146 8.31722 8.21171 8.36604C8.2542 8.46885 8.30043 8.57661 8.31121 8.68572C8.34467 9.02181 8.23989 9.30819 7.97924 9.52731C7.81612 9.66454 7.63143 9.7471 7.419 9.74867C7.00602 9.75182 6.59282 9.75407 6.17984 9.74912C5.55091 9.7417 4.92022 9.78512 4.29371 9.69558C4.01391 9.65554 3.73126 9.62674 3.45652 9.56285C3.12698 9.48614 2.80514 9.37501 2.47823 9.28525C2.41462 9.2677 2.40669 9.23778 2.40691 9.18176C2.40801 7.58924 2.40823 5.99672 2.40625 4.4042C2.40625 4.34233 2.43091 4.30701 2.47405 4.27192C2.65567 4.12367 2.84366 3.98194 3.01405 3.82109C3.19676 3.64832 3.36715 3.46092 3.53423 3.27195C3.70176 3.08253 3.85123 2.87624 3.94369 2.63733C4.13389 2.14556 4.31308 1.64929 4.50152 1.15661C4.59089 0.922652 4.72209 0.721084 4.96468 0.62345C5.02082 0.600728 5.07696 0.576882 5.13309 0.553711C5.2152 0.553711 5.29709 0.553711 5.3792 0.553711C5.38779 0.55956 5.39571 0.568559 5.40496 0.570583C5.6918 0.629974 5.90621 0.78205 5.98788 1.08013C6.07021 1.3809 6.10566 1.68708 6.06691 2.00158C6.03411 2.26681 6.02289 2.53475 5.99317 2.80065C5.97401 2.97095 5.93989 3.13945 5.91039 3.3084C5.88266 3.46745 5.85228 3.62605 5.82102 3.795H5.92999C6.52722 3.795 7.12445 3.79342 7.72168 3.79589C7.95239 3.79679 8.1657 3.85326 8.3493 4.00961C8.65176 4.26697 8.76117 4.58889 8.66827 4.97786C8.64252 5.08516 8.59123 5.18595 8.55006 5.29438C8.74488 5.41699 8.88467 5.59246 8.95159 5.82575C8.96634 5.87704 8.98395 5.92766 9.00024 5.9785L9.00002 5.97827Z"
            fill="#FFC000"
        />
        <path
            d="M1.87205 4.59485V9.13057C1.84629 9.13237 1.82384 9.13507 1.80161 9.13507C1.29815 9.1353 0.794478 9.1308 0.291024 9.138C0.138469 9.14002 -0.00065807 8.9983 2.34171e-06 8.84082C0.00550577 7.51848 0.00374467 6.19614 0.00264399 4.8738C0.00264399 4.71408 0.0992843 4.5989 0.256903 4.5971C0.792496 4.59125 1.32809 4.59485 1.87227 4.59485H1.87205Z"
            fill="#FFC000"
        />
    </svg>
</template>

<script>
export default {
    name: 'ThumbSvg'
}
</script>

<style scoped></style>
