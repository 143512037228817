<template>
    <div
        v-if="featureTags.length > 0"
        class="d-flex flex-wrap justify-content-lg-end"
    >
        <!--Temporary styles changes for mobile view-->
        <!--        <div class="row justify-content-lg-end">-->
        <div
            v-for="(tag, index) in instantBookRefundTags"
            :key="`instant_book_refund_${index}`"
            class="feature-tag feature-tag-reverse px-3 mb-2 mr-1 mr-lg-0 ml-lg-2"
        >
            <p
                v-if="tag.content?.trim().length > 0"
                class="text-mid text-12 text-ffc000 feature-tag-content"
                style="white-space: nowrap"
            >
                {{ tag.content }}
            </p>
        </div>
        <!--        </div>-->
        <!--        <div class="row justify-content-lg-end">-->
        <div
            v-for="(tag, index) in selectionTags"
            :key="`selection_${index}`"
            class="feature-tag px-3 mb-2 mr-1 mr-lg-0 ml-lg-2"
        >
            <p
                v-if="tag.content?.trim().length > 0"
                class="text-mid text-12 text-white feature-tag-content"
            >
                {{ tag.content }}
            </p>
        </div>
        <!--        </div>-->
    </div>
</template>

<script>
import _partition from 'lodash/partition'

export default {
    name: 'SpaceCardFeatureTags',
    props: {
        featureTags: {
            type: Array,
            required: true,
            default() {
                return []
            }
        }
    },
    computed: {
        tagsByPosition() {
            return _partition(
                this.featureTags,
                (_) => _.position === undefined || _.position === null
            )
        },
        instantBookRefundTags() {
            return this.tagsByPosition[0]
        },
        selectionTags() {
            return this.tagsByPosition[1]
        }
    }
}
</script>

<style scoped>
.feature-tag {
    background-color: #ffc000;
    border-radius: 36px;
    height: 18px;
    border: 1px solid #ffc000;
}
.feature-tag-reverse {
    background-color: transparent;
    border: 1px solid #ffc000;
    border-radius: 36px;
    height: 18px;
}
.feature-tag-content {
    user-select: none;
}
@media (max-width: 992px) {
    .feature-tag {
        padding-top: 0.8rem;
        padding-bottom: 0.8rem;
    }
}
@media (min-width: 991px) {
    .feature-tag {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
}
</style>
