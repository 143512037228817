<template>
    <b-skeleton-wrapper :loading="true">
        <template #loading>
            <!--Debug: style="border: red solid 1px;"-->
            <div class="p-4">
                <div class="row pb-2 pb-lg-4 px-2 px-lg-2">
                    <div class="col px-2">
                        <div class="d-flex justify-content-between">
                            <b-skeleton
                                class="skeleton-space-title"
                                key="key-skeleton-space-title"
                            />
                            <b-skeleton
                                class="skeleton-feature-tag d-none d-lg-block"
                                key="key-skeleton-space-feature-tags"
                                width="20%"
                            />
                        </div>
                        <b-skeleton
                            class="skeleton-space-star-location mt-2"
                            key="key-skeleton-space-star-location"
                            width="30%"
                        />
                    </div>
                </div>
                <!--Debug: style="border: blue solid 1px;"-->
                <div class="row h-auto">
                    <div class="col-12 col-lg-5 pl-3 pr-3 pr-lg-0">
                        <b-skeleton
                            class="skeleton-space-images mt-3 mt-lg-4"
                            key="key-skeleton-space-images"
                        />
                        <b-skeleton
                            class="skeleton-space-comment mt-3 mt-lg-4 mb-4 mb-lg-0"
                            key="key-skeleton-space-comment"
                        />
                    </div>
                    <div
                        class="col-12 col-lg-7 pl-lg-4 d-flex align-items-end justify-content-center flex-column"
                    >
                        <b-skeleton
                            class="skeleton-more-link mb-3"
                            key="key-skeleton-more-link"
                            width="15%"
                        />
                        <!--                        <b-skeleton-->
                        <!--                            class="skeleton-space-tags w-100"-->
                        <!--                            key="key-skeleton-space-tags"-->
                        <!--                        />-->
                        <b-skeleton
                            class="skeleton-space-timeslots w-100"
                            key="key-skeleton-space-timeslots-1"
                        />
                        <b-skeleton
                            class="skeleton-space-timeslots w-100 mt-2"
                            key="key-skeleton-space-timeslots-2"
                        />
                    </div>
                </div>
            </div>
        </template>
    </b-skeleton-wrapper>
</template>

<script>
export default {
    name: 'SpaceCardSkeleton'
}
</script>

<style scoped>
@media (max-width: 991px) {
    .skeleton-space-title {
        height: 20px;
        width: 50%;
    }
    .skeleton-space-comment {
        height: 20px;
        width: 70%;
    }
}
@media (min-width: 992px) {
    .skeleton-space-title {
        height: 22px;
        width: 25%;
    }
    .skeleton-space-comment {
        height: 20px;
        width: 90%;
    }
}
.skeleton-feature-tag {
    height: 15px;
}
.skeleton-space-star-location {
    height: 15px;
}
.skeleton-space-images {
    height: 220px;
    width: auto;
    border-radius: 12px;
}
/*
.skeleton-space-tags {
    height: 80px;
}*/
.skeleton-more-link {
}
.skeleton-space-timeslots {
    height: 90px;
    border-radius: 12px;
}
</style>
