<template>
    <b-button
        v-if="!isMapView"
        class="position-absolute align-items-center view-map-button d-none d-lg-flex"
        variant="transparent"
        style="bottom: 8px; right: 8px; background-color: white;z-index: 1;"
        @click.stop.prevent="onViewMapButtonClicked"
    >
        <SpaceCardViewMapSvg height="15px" width="15px" />
        <div class="ml-1 text-13">
            {{ $t('list.view_on_map') }}
        </div>
    </b-button>
</template>

<script>
import SpaceCardViewMapSvg from '@/components/iconSvg/SpaceCardViewMapSvg'

export default {
    name: 'SpaceCardViewMapButton',
    components: {
        SpaceCardViewMapSvg
    },
    props: {
        isMapView: {
            type: Boolean,
            required: true,
            default: false
        },
        listItem: {
            type: Object,
            required: true,
            default: undefined
        }
    },
    methods: {
        onViewMapButtonClicked() {
            this.$emit('onViewMapButtonClicked', this.listItem)
        }
    }
}
</script>

<style scoped>
.view-map-button {
    display: flex;
    transition: 0s;
    opacity: 0;
}
</style>
