<template>
    <svg
        width="29"
        height="33"
        viewBox="0 0 29 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M26.929 7.73402L24.7875 9.85767L24.6467 9.95357C26.7315 12.3295 28 15.4408 28 18.8432C28 26.2874 21.9442 32.3432 14.5 32.3432C7.05582 32.3432 1 26.2874 1 18.8432C1 15.4077 2.29246 12.269 4.41382 9.88506C4.37614 9.85766 4.33846 9.83026 4.30535 9.79715L2.24336 7.73516C1.88942 7.38122 1.88942 6.80121 2.24336 6.44727C2.5973 6.09333 3.17731 6.09333 3.53125 6.44727L5.59324 8.50926C5.62978 8.5458 5.66061 8.5869 5.69029 8.628C7.84362 6.76924 10.5872 5.5784 13.5992 5.37859C13.5946 5.3432 13.5877 5.30781 13.5877 5.27127V3.14877C13.5877 3.03345 13.6117 2.92384 13.6517 2.82223H10.2504C9.7492 2.82223 9.33931 2.41234 9.33931 1.91111C9.33931 1.40989 9.7492 1 10.2504 1H18.8592C19.3604 1 19.7703 1.40989 19.7703 1.91111C19.7703 2.41234 19.3604 2.82223 18.8592 2.82223H15.346C15.386 2.92384 15.41 3.03345 15.41 3.14877V5.27127C15.41 5.30781 15.4031 5.3432 15.3986 5.37859C18.4641 5.58068 21.2523 6.81034 23.4227 8.72733C23.4638 8.64855 23.5141 8.5732 23.5792 8.50812L25.6412 6.44613C25.9951 6.09219 26.5751 6.09219 26.929 6.44613C27.283 6.80007 27.283 7.38007 26.929 7.73402Z"
            stroke="#F2BC1F"
            stroke-miterlimit="10"
        />
        <path
            d="M2.70239 19.1953H5.60357"
            stroke="#F2BC1F"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M24.0759 19.1953H26.9771"
            stroke="#F2BC1F"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M14.5 31.333V28.4307"
            stroke="#F2BC1F"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M14.5 9.95945V7.05713"
            stroke="#F2BC1F"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M14.5 12.0083V19.1944"
            stroke="#F2BC1F"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

<script>
export default {
    name: 'OutOfRoomSvg'
}
</script>
