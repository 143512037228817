export const getSpaceNameFromService = (service) => {
    let spaceName = ''
    if (service === 'hotel') {
        spaceName = 'rest'
    } else if (service === 'co-working') {
        spaceName = 'work'
    } else if (service === 'meeting') {
        spaceName = 'meet'
    } else if (service === 'staycation') {
        spaceName = 'staycation'
    } else if (service === 'party') {
        spaceName = 'party'
    }
    return spaceName
}
