<template>
    <b-button
        @click="handleBtnClick"
        variant="transparent"
        :style="`background-color: #${bgColor}`"
        aria-label="circle forward button"
        class="forward-button"
    >
        <ArrowForwardIOS style="width: 14px; height: 14px;" />
    </b-button>
</template>
<script>
import ArrowForwardIOS from '@/components/iconSvg/ArrowForwardIOS.vue'

export default {
    name: 'CircleForwardButton',
    components: { ArrowForwardIOS },
    props: {
        bgColor: {
            type: String,
            default: '000000'
        }
    },
    methods: {
        handleBtnClick() {
            this.$emit('handleBtnClick')
        }
    }
}
</script>
<style scoped>
.forward-button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
