<template>
    <div v-if="bookTypeThemeFilters?.length > 0">
        <client-only>
            <SwiperContainer
                :option="swiperOption"
                :type="type"
                :is-allow-touch-move="deviceType() === 'mobile'"
                name="book-type-theme"
                :is-init-transition="setIsInitTransition"
                :isSendGAEvent="false"
            >
                <b-form-checkbox
                    v-for="(item, idx) in bookTypeThemeFilters"
                    :key="`bookType_${idx}`"
                    v-model="selectedValues"
                    :value="item.value"
                    :class="checkboxStyle(item)"
                    @change="setSelectedValues"
                    class="flex-center swiper-slide book-type-filter"
                    style="width: fit-content;"
                    size="sm"
                    button-variant="transparent"
                    button
                >
                    <p class="text-14">
                        {{ item.text }}
                    </p>
                </b-form-checkbox>
            </SwiperContainer>
            <template #placeholder>
                <b-skeleton-wrapper :loading="true">
                    <template #loading>
                        <div
                            class="d-flex skeleton-wrapper align-items-end mx-3 mx-lg-0"
                        >
                            <b-skeleton
                                v-for="(item, idx) in 2"
                                :key="`bookTypeSkeleton_${idx}`"
                                style="padding-top: 15px; margin-right: 10px; border-radius: 10px"
                                width="80px"
                            ></b-skeleton>
                        </div>
                    </template>
                </b-skeleton-wrapper>
            </template>
        </client-only>
    </div>
</template>

<script>
import _isEqual from 'lodash/isEqual'
import SwiperContainer from '@/components/ui/SwiperContainer'

export default {
    name: 'BookTypeThemeFilter',
    components: { SwiperContainer },
    props: {
        inputTheme: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        inputTimeFrame: {
            type: Array,
            required: true,
            default() {
                return []
            }
        },
        type: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            input: {
                selectedTheme: this.inputTheme,
                selectedTimeFrame: this.inputTimeFrame
            },
            swiperOption: {
                slidesPerView: 'auto',
                spaceBetween: 5,
                mode: 'horizontal',
                freeMode: true,
                slidesOffsetBefore: 14,
                breakpoints: {
                    992: {
                        slidesOffsetBefore: 0
                    }
                }
            },
            selectedValues: [...this.inputTimeFrame, ...this.inputTheme]
        }
    },
    computed: {
        searchBarSetting() {
            return this.$store.getters['api/searchBarSetting']
        },
        bookTypeThemeFilters() {
            return this.searchBarSetting.filtering
                .filter(
                    (_) =>
                        _.ga_filter_type === 'time_frame' && this.space !== 'hotel' ||
                        _.ga_filter_type === 'theme'
                )
                .map((filter) => {
                    filter.value.map((_) => {
                        _.type = filter.ga_filter_type
                        return _
                    })
                    return filter
                })
                .flatMap((_) => _.value)
        },
        prevRoute() {
            return this.$store.state.prevRoute
        },
        space() {
            return this.$route.params.space
        },
        setIsInitTransition() {
            const isRefreshPage = this.prevRoute === '/'
            const isSpaceChanged = !this.prevRoute.includes('/' + this.space)
            return isRefreshPage || isSpaceChanged
        }
    },
    methods: {
        setTheme(checked) {
            this.$emit('setTheme', checked, undefined, undefined, true)
        },
        isThemeSelected(item) {
            return this.input.selectedTheme.includes(item.value)
        },
        setTimeFrame(checked) {
            this.$emit('setTimeFrame', checked)
        },
        isTimeFrameSelected(item) {
            return this.input.selectedTimeFrame.includes(item.value)
        },
        isItemSelected(item) {
            return this.selectedValues.includes(item.value)
        },
        setSelectedValues(item) {
            const timeFrame = []
            const theme = []
            item.forEach((item) => {
                const itemType = this.bookTypeThemeFilters.find(
                    (_) => _.value === item
                )?.type
                if (itemType === 'time_frame') {
                    timeFrame.push(item)
                } else if (itemType === 'theme') {
                    theme.length = 0
                    theme.push(item)
                }
            })
            if (!_isEqual(timeFrame, this.input.selectedTimeFrame)) {
                this.setTimeFrame(timeFrame)
            }
            if (!_isEqual(theme, this.input.selectedTheme)) {
                this.setTheme(theme)
            }
        },
        checkboxStyle(item) {
            return this.isItemSelected(item) ? 'checkbox-selected' : 'checkbox'
        }
    }
}
</script>

<style scoped>
.checkbox-selected {
    background: var(--palette-yellow);
}
.book-type-filter {
    border: 1px solid var(--palette-yellow);
    white-space: nowrap;
    font-weight: 500;
    border-radius: 20px;
}
.checkbox-selected >>> p {
    color: white;
}
.checkbox >>> p {
    color: var(--palette-yellow);
}
@media (max-width: 767px) {
    .skeleton-wrapper {
        height: 29px;
    }
}
@media (min-width: 768px) {
    .skeleton-wrapper {
        height: 32px;
    }
}
</style>
