<template>
    <div
        class="d-flex justify-content-between align-items-start bg-white w-100 card-container"
    >
        <ImageContainer
            v-if="listItem.images?.length > 0"
            :src="listItem.images[0]"
            :style="featuredFrameStyle(listItem)"
            class="space-img"
            alt="featured frame"
            style="height: 126px; object-fit: cover; aspect-ratio: 1 / 1;"
        />
        <div
            class="d-flex flex-column justify-content-between align-items-start w-100 h-100 px-3 py-2"
        >
            <div>
                <div class="text-primary text-s-bold text-14 text-left mb-1">
                    {{ spaceTypeText }}
                </div>
                <div
                    class="text-15 text-left text-mid hide-overflow-text"
                    style="-webkit-line-clamp: 2;"
                >
                    {{ listItem.name?.[algoliaLang] }}
                </div>
            </div>
            <div>
                <MinPrice
                    v-if="listItem.button_list?.[0]?.price"
                    :space-type="globalSpace()"
                    :price="getDisplayPrice(listItem.button_list?.[0]?.price)"
                    class="text-18"
                />
                <div v-else class="text-14 text-404040 text-bold">
                    {{ tagText(listItem?.status?.day_space_status) }}
                </div>
                <div
                    v-if="listItem.button_list?.length > 0"
                    class="d-flex flex-wrap text-12 text-left text-9d9d9d"
                >
                    {{
                        $t('search_bar.current_timeslot') +
                            '：' +
                            listItem.button_list[0]?.time
                    }}
                </div>
            </div>
        </div>
        <HeartButton
            v-if="false"
            :slug="listItem.slug"
            :space-name="listItem?.name?.[algoliaLang]"
            :space="$route.params.space"
            class="position-absolute"
            style="right: 0; top: 3%; z-index: 1;"
        />
    </div>
</template>
<script>
import { getAlgoliaLang } from '@/services/algoliaApi'
import HeartButton from '@/components/item/HeartButton'
import MinPrice from '@/components/item/MinPrice.vue'
import RecommendSvg from '~/components/iconSvg/RecommendSvg.vue'
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'MapSpaceCard',
    components: {ImageContainer, RecommendSvg, MinPrice, HeartButton },
    props: {
        listItem: {
            type: Object,
            default: undefined
        },
        spaceTypeText: {
            type: String,
            required: true,
            default: undefined
        }
    },
    computed: {
        algoliaLang() {
            return getAlgoliaLang(
                this.$route.params.region,
                this.$route.params.locale
            )
        }
    },
    mounted() {},
    methods: {
        featuredTitleStyle(listItem) {
            const color = '#F2BC1F'
            const fill = color
            const fontStyle = 'normal'
            const fontWeight = '700'
            return {
                color,
                fill,
                /** NOT apply font size from Algolia, due to limited card layout */
                fontSize: '14px',
                fontWeight,
                fontStyle
            }
        },
        featuredFrameStyle(listItem) {
            if (!listItem?.ranking?.is_featured && !listItem?.ranking?.is_promotion) {
                return {}
            }
            const color = '#F2BC1F'
            return {
                border: '6px solid',
                borderColor: color,
                borderRadius: '15px'
            }
        }
    }
}
</script>
<style scoped>
.card-container {
    border-radius: 12px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    background-color: transparent;
    overflow: hidden;
    height: 126px;
}
</style>
