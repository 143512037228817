<template>
    <div class="d-flex align-items-center">
        <SpaceAreaSvg v-if="area" height="14px" width="14px" class="mr-1" />
        <p v-if="area" class="text-afafaf text-reg text-14 mr-3 area-text">
            {{ area }}
        </p>
        <SpaceCapacitySvg
            v-if="capacity"
            height="14px"
            width="14px"
            class="mr-1"
        />
        <p v-if="capacity" class="text-afafaf text-reg text-14 capacity-text">
            {{ capacity }}
        </p>
    </div>
</template>

<script>
import SpaceAreaSvg from '@/components/iconSvg/SpaceAreaSvg'
import SpaceCapacitySvg from '@/components/iconSvg/SpaceCapacitySvg'
export default {
    name: 'SpaceCardAreaCapacity',
    components: {
        SpaceAreaSvg,
        SpaceCapacitySvg
    },
    props: {
        area: {
            type: Number,
            required: false,
            default: undefined
        },
        capacity: {
            type: String,
            required: false,
            default: undefined
        }
    }
}
</script>

<style scoped>
.area-text {
    user-select: none;
}
.capacity-text {
    user-select: none;
}
</style>
