<template>
    <div class="py-3 px-4">
        <client-only>
            <div
                class="d-flex align-items-center justify-content-between flex-wrap"
            >
                <div class="text-s-bold" style="white-space: nowrap;">
                    {{ $t('search_bar.filter_search_results') }}
                </div>
                <TextButton @handleBtnClick="setClearFilter"
                    >{{ $t('search_bar.clear_all') }}
                </TextButton>
            </div>
            <template #placeholder>
                <TextSkeleton height="15px" style="height: 23px;" />
            </template>
        </client-only>
    </div>
</template>
<script>
import TextButton from '~/components/item/TextButton.vue'
import TextSkeleton from '~/components/item/TextSkeleton.vue'

export default {
    name: 'AdvanceSearchHeadSection',
    components: { TextSkeleton, TextButton },
    methods: {
        setClearFilter() {
            this.$emit('setClearFilter')
        }
    }
}
</script>
