<template>
    <div class="space-card-duration-separator" />
</template>

<script>
export default {
    name: 'SpaceCardDurationSeparator'
}
</script>

<style scoped>
.space-card-duration-separator {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    padding: 0;
    width: 100%;
}
</style>
