<template>
    <div
        class="position-absolute m-3 text-right d-flex flex-column"
        style="top: 0; left: 0; z-index: 1;"
    >
        <span
            v-if="
                (!listItem.isAvailable ||
                    !listItem.button_list ||
                    listItem.button_list.length === 0) &&
                    listItem.status?.day_space_status
            "
            :style="`backgroundColor: ${getTagColor('flow-yellow')}`"
            class="text-light global-space-tag text-mid mb-2"
        >
            {{ tagText(listItem.status.day_space_status) }}
        </span>
        <!--Disable image tags in new space card-->
        <span
            v-for="(tag, index) in listItem.image_tags"
            v-if="false"
            :key="index"
            :style="`backgroundColor: ${getTagColor(tag.colour)}`"
            class="text-light global-space-tag text-mid mb-2"
        >
            {{ tag.name }}
        </span>
    </div>
</template>

<script>
import colors from '@/themes/colors'

export default {
    name: 'SpaceCardImageTags',
    props: {
        listItem: {
            type: Object,
            required: true,
            default: undefined
        }
    },
    methods: {
        getTagColor(color) {
            switch (color) {
                case 'light-blue':
                    return colors.tagLightBlue
                case 'green':
                    return colors.tagGreen
                case 'deep-blue':
                    return colors.tagDeepBlue
                case 'flow-yellow':
                default:
                    return colors.tagYellow
            }
        }
    }
}
</script>

<style scoped></style>
