<template>
    <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.23106 6.89318C9.33829 6.26475 10.0764 5.06774 10.0764 3.71113C10.0764 1.67622 8.44053 0.0403118 6.40562 0.0403118C4.37071 0.0403118 2.7348 1.67622 2.7348 3.71113C2.7348 5.06774 3.47295 6.26475 4.58018 6.89318C2.26597 7.65128 0.610107 9.82585 0.610107 12.3994H1.38816C1.38816 9.61637 3.62258 7.38196 6.40562 7.38196C9.18866 7.38196 11.4231 9.61637 11.4231 12.3994H12.2011C12.2011 9.82585 10.5453 7.65128 8.23106 6.89318ZM3.51285 3.71113C3.51285 2.10515 4.79963 0.818366 6.40562 0.818366C8.0116 0.818366 9.29839 2.10515 9.29839 3.71113C9.29839 5.30714 8.00163 6.6039 6.40562 6.6039C4.80961 6.6039 3.51285 5.30714 3.51285 3.71113Z"
            fill="#afafaf"
        />
    </svg>
</template>

<script>
export default {
    name: 'SpaceCapacitySvg'
}
</script>
