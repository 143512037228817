<template>
    <div
        :style="computedFrameStyle"
        class="d-flex d-lg-none align-items-center justify-content-center mt-n2 mr-n2 promo-frame-container w-auto"
    >
        <p
            :style="computeTitleStyle"
            class="text-white text-bold text-16 px-2 promo-frame-title text-center"
        >
            {{ frameStyle?.content?.[algoliaLang] }}
        </p>
    </div>
</template>

<script>
export default {
    name: 'SpaceCardPromoFrameIcon',
    props: {
        frameStyle: {
            type: Object,
            required: true,
            default: undefined
        },
        algoliaLang: {
            type: String,
            required: true
        }
    },
    computed: {
        computedFrameStyle() {
            const gradient = ['#ffc000', '#fedc01']
            return {
                background: `linear-gradient(90deg, ${gradient[0]} 0%, ${gradient[1]} 100%)`,
                height: '100%',
                width: '100%'
            }
        },
        computeTitleStyle() {
            return {
                color: '#765821'
            }
        }
    }
}
</script>

<style scoped>
.promo-frame-container {
    min-height: 50px;
    border-bottom-left-radius: 17px;
    border-top-right-radius: 17px;
    overflow: hidden;
}
.promo-frame-title {
    text-shadow: 0 2px 4px 0 #8A680240;
}
</style>
