<template>
    <div
        @click="handleBtnClick"
        class="border-0 align-self-center d-flex align-items-center justify-content-center button-box-shadow text-white text-mid"
        style="border-radius: 50px; background-color: #313131; white-space: nowrap; padding: 10px 19px;"
    >
        <slot />
    </div>
</template>
<script>
export default {
    name: 'PillButton',
    props: {},
    methods: {
        handleBtnClick() {
            this.$emit('handleBtnClick')
        }
    }
}
</script>
