<template>
    <PopularFilter
        :date="date"
        :num-ppl="numPpl"
        :space="space"
        :type="type"
        :slug="slug"
        :device="device"
        :is-display-btn="isDisplayBtn"
        :name="'4-star'"
        :hits="hits"
        :btn-name="eventBtnName('Location', '4 star')"
        @navigateToDetails="navigateToDetails"
    >
        {{ getLocationHeader('4_star_space', query, space, localeKeyword) }}
    </PopularFilter>
</template>

<script>
import dateServices from '@/services/dateServices'
import PopularFilter from '~/components/location/PopularFilter'

export default {
    name: 'FourStarSpace',
    components: {
        PopularFilter
    },
    props: {
        date: {
            type: String,
            default: dateServices.getDateByFormat(new Date())
        },
        numPpl: {
            type: Number,
            default: 0
        },
        space: {
            type: String,
            default: 'hotel'
        },
        query: {
            type: String,
            required: true,
            default: undefined
        },
        type: {
            type: String,
            required: true,
            default: undefined
        },
        slug: {
            type: String,
            required: true,
            default: ''
        },
        device: {
            type: String,
            default: ''
        },
        isDisplayBtn: {
            type: Boolean,
            default: false
        },
        localeKeyword: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            filter: { f_star_rating: '4' }
        }
    },
    async fetch() {
        await this.getPopularFilterHits()
    },
    computed: {
        hits() {
            // eslint-disable-next-line standard/computed-property-even-spacing
            const hits = this.$store.getters[
                'algoliaSearch/fourStarSpaceResultHits'
            ]
            return hits.slice(0, 10)
        }
    },
    mounted() {
        this.getPopularFilterHits()
    },
    methods: {
        async getPopularFilterHits() {
            try {
                let params = this.getPopularFilterParams(this.space, this.query)
                params = {
                    ...params,
                    ...this.filter,
                    isSkipAnalytics: true
                }
                await this.$store.dispatch(
                    'algoliaSearch/SEARCH_FOUR_STAR_SPACE_INDEX',
                    params
                )
            } catch (err) {
                this.$nuxt.error(err)
            }
        },
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        }
    }
}
</script>
