<template>
    <div class="">
        <div v-if="isLocation" class="list-container">
            <PopularSpace
                :date="input.date.value"
                :num-ppl="numPpl"
                :space="space"
                :query="locationDetails.name"
                :type="locationDetails.type"
                :slug="isLocation"
                :locale-keyword="localeKeyword"
                :is-display-btn="true"
                :btn-name="eventBtnName('Location', 'popular')"
                @navigateToDetails="navigateToDetails"
                @handleBtnClick="redirectToExactAreaSearch"
                class="my-4 my-xl-5"
            >
                {{
                    getLocationHeader(
                        'popular_space',
                        locationDetails.name,
                        space,
                        localeKeyword
                    )
                }}
            </PopularSpace>
        </div>
        <div v-if="isHourlyHotelLocation" class="list-container">
            <HourlyHotelSpace
                :date="input.date.value"
                :num-ppl="numPpl"
                :space="space"
                :query="locationDetails.name"
                :type="locationDetails.type"
                :slug="isLocation"
                :locale-keyword="localeKeyword"
                :is-display-btn="false"
                @navigateToDetails="navigateToDetails"
                class="my-4 my-xl-5"
            >
                {{
                    getLocationHeader(
                        'hourly_hotel',
                        locationDetails.name,
                        space,
                        localeKeyword
                    )
                }}
            </HourlyHotelSpace>
        </div>
        <div v-show="!isBlockGooglebotFromSearchSection" class="py-lg-4 main-list-container">
            <div id="search-section"></div>
            <HeaderTitle
                v-if="globalSpaceTypeNum(space) === '1' && isLocation"
                class="px-3 my-3"
                >{{ getHeader('search_title') }}
            </HeaderTitle>
            <div class="d-flex">
                <div class="d-none d-lg-block px-3 search-container">
                    <div class="scroller">
                        <BasicSearchField
                            :key="`basic_${basicSearchFieldUpdateKey}`"
                            :type="'desktop'"
                            :num-ppl="numPpl"
                            :min-pax="minPax"
                            :max-pax="maxPax"
                            :disable-date-list="searchBarSetting"
                            :selected-date="input.date.value"
                            :input-space-type="input.spaceType"
                            :input-words="input.words"
                            :selected-check-in-time="input.checkInTime"
                            :is-loading="list.loadingSpace"
                            :is-fetching-location="isFetchingLocation"
                            :is-show-immediate-placeholder="
                                isShowImmediatePlaceholder
                            "
                            :is-show-placeholder-suggestion="false"
                            @setNumPpl="setNumPpl"
                            @setSpaceType="setSpaceType"
                            @setDate="setDate"
                            @setCheckInTime="setCheckInTime"
                            @startSearching="startSearching"
                            @desktopSearchClick="desktopSearchClick"
                            @setDefaultPax="setDefaultPax"
                            @setQuery="setQuery"
                            @getCurrentLocation="getCurrentLocation"
                            @setQuerySourceFromBasicSearchField="setQuerySource"
                            class="field-container py-3 m-3 card-shadow"
                            style="padding-top: 34px; padding-bottom: 34px;"
                        />
                        <div
                            v-if="Object.keys(searchBarSetting).length !== 0"
                            :key="updateAdvanceFilter"
                            class="field-container m-3 card-shadow"
                        >
                            <AdvanceSearchHeadSection
                                @setClearFilter="setClearFilter"
                            />
                            <HorizontalRule />
                            <AdvanceSearch
                                :key="advanceSearchUpdateKey"
                                :input-theme="input.theme"
                                :input-price-range="input.priceRange"
                                :input-time-frame="input.timeFrame"
                                :input-star-rating="input.starRating"
                                :is-show-theme="false"
                                :is-show-time-frame="false"
                                @setPriceRange="onFilterPriceRangeUpdated"
                                @setStarRating="setStarRating"
                                @setClearFilter="setClearFilter"
                            />
                        </div>
                    </div>
                </div>
                <div class="w-100">
                    <!-- Desktop filter & sorting section-->
                    <div class="d-none d-lg-block mb-3 p-3">
                        <BookTypeThemeFilter
                            :key="updateBookTypeThemeFilter"
                            :input-time-frame="input.timeFrame"
                            :input-theme="input.theme"
                            @setTheme="setTheme"
                            @setTimeFrame="setTimeFrame"
                            type="desktop"
                            class="mb-3"
                        />
                        <div
                            class="d-flex justify-content-between align-items-end"
                        >
                            <div>
                                <SortingButton
                                    :align-right="false"
                                    :input-sort="input.sort"
                                    @setSort="setSort"
                                />
                                <TotalResultsText
                                    :total-hits="totalResultHits"
                                    :is-loading-space="list.loadingSpace"
                                    class="mt-4"
                                />
                            </div>
                            <DesktopMapButton
                                @handleBtnClick="handleDesktopMapBtnClick"
                            />
                        </div>
                    </div>
                    <!-- Mobile filter & sorting input section-->
                    <div class="d-lg-none d-block">
                        <div
                            v-if="false"
                            class="d-flex align-items-center py-3 my-2"
                        >
                            <div
                                class="pl-3 position-absolute"
                                style="z-index: 2"
                            >
                                <BackButton
                                    class="text-18 text-s-bold"
                                    @handleBtnClick="handleBackBtnClick"
                                />
                            </div>
                            <div
                                class="position-absolute w-100 d-flex justify-content-center"
                            >
                                <div class="text-18 text-s-bold">
                                    {{ input.spaceType.text }}
                                </div>
                            </div>
                        </div>
                        <div class="mx-3 mt-3">
                            <b-button
                                @click="setSearchBarVisible"
                                variant="transparent"
                                :class="{
                                    'ssr-search-bar card-shadow': isSSR,
                                    'csr-search-bar': !isSSR
                                }"
                                class="button-active mobile-search-bar"
                            >
                                <SearchSvg class="ml-1 mr-3" />
                                <client-only>
                                    <div class="d-flex flex-column text-left">
                                        <div
                                            v-if="input.words.length > 0"
                                            class="text-16 text-bold hide-overflow-text"
                                            style="-webkit-line-clamp: 1;"
                                        >
                                            {{ input.words }}
                                        </div>
                                        <AnimatedText
                                            v-else
                                            :input-words="input.words"
                                            :is-absolute-position="false"
                                            :is-show-immediate-placeholder="
                                                isShowImmediatePlaceholder
                                            "
                                            @updateIndex="updateAnimateIndex"
                                            class="text-16 text-bold"
                                        />
                                        <div
                                            class="d-flex align-items-center"
                                            style="white-space: nowrap"
                                        >
                                            <div class="text-6d6d6d text-14">
                                                {{ `${selectedDisplayDate}, ` }}
                                            </div>
                                            <div
                                                class="text-6d6d6d text-14 ml-2"
                                            >
                                                {{ `${selectedDisplayTime}, ` }}
                                            </div>
                                            <div
                                                class="text-6d6d6d text-14 ml-2"
                                            >
                                                {{ input.spaceType.text }}
                                            </div>
                                        </div>
                                    </div>
                                    <template #placeholder>
                                        <b-skeleton-wrapper :loading="true">
                                            <template #loading>
                                                <b-skeleton
                                                    style="padding-top: 10px;"
                                                    width="80px"
                                                ></b-skeleton>
                                                <b-skeleton
                                                    style="padding-top: 9px;"
                                                    width="120px"
                                                ></b-skeleton>
                                            </template>
                                        </b-skeleton-wrapper>
                                    </template>
                                </client-only>
                                <client-only>
                                    <div class="d-flex w-auto flex-grow-1" />
                                    <div
                                        v-if="space === 'party'"
                                        class="d-flex mx-2"
                                    >
                                        <div class="mr-1">
                                            {{ '|' }}
                                        </div>
                                        <div style="white-space: nowrap;">
                                            {{ numPpl + $t('ppl') }}
                                        </div>
                                    </div>
                                    <div
                                        :class="
                                            isMobileFilterSelected
                                                ? 'filter-container-selected'
                                                : 'filter-container-default'
                                        "
                                        class="filter-container"
                                    >
                                        <FilterSvg
                                            :is-selected="
                                                isMobileFilterSelected
                                            "
                                        />
                                    </div>
                                    <template #placeholder>
                                        <div style="height: 44px;"></div>
                                    </template>
                                </client-only>
                            </b-button>
                        </div>
                        <BookTypeThemeFilter
                            :key="updateBookTypeThemeFilter"
                            :input-time-frame="input.timeFrame"
                            :input-theme="input.theme"
                            @setTheme="setTheme"
                            @setTimeFrame="setTimeFrame"
                            type="mobile"
                            class="mt-2"
                        />
                        <div
                            :class="!map.visible ? 'd-flex' : 'd-none'"
                            class="justify-content-between align-items-end px-3 py-2"
                        >
                            <TotalResultsText
                                :total-hits="totalResultHits"
                                :is-loading-space="list.loadingSpace"
                            />
                            <SortingButton
                                :input-sort="input.sort"
                                @setSort="setSort"
                            />
                        </div>
                        <div
                            class="position-absolute w-100 d-flex justify-content-center"
                        >
                            <PillButton
                                v-if="
                                    !list.loadingSpace &&
                                        !isEmptyResults &&
                                        isMobileFloatingBtnVisible &&
                                        !map.visible
                                "
                                @handleBtnClick="handleMobileMapBtnClick"
                                class="d-lg-none d-block"
                                style="position: fixed; z-index: 2; bottom:5vh;"
                            >
                                <ShowMapSvg class="mr-2" />
                                <div class="text-16">
                                    {{ $t('list.map') }}
                                </div>
                            </PillButton>
                        </div>
                    </div>
                    <b-button
                        v-if="
                            !list.loadingSpace &&
                                isMobileFloatingBtnVisible &&
                                !isEmptyResults &&
                                !map.visible
                        "
                        @click="scrollToTop"
                        aria-label="scroll to top"
                        class="border-0 align-self-center d-flex align-items-center justify-content-center button-box-shadow"
                        style="border-radius: 50px; height: 41px; width: 41px; position: fixed; bottom: 5vh; right: 18px; z-index: 2; background-color: #313131"
                    >
                        <ExpendLessSvg style="fill: #ffffff" />
                    </b-button>
                    <div v-if="list.loadingSpace" class="d-flex flex-wrap">
                        <div
                            v-for="(item, initialSkeletonIndex) in 24"
                            :key="initialSkeletonIndex"
                            class="mb-4 mb-sm-4 px-3 px-sm-3 w-100"
                        >
                            <ListSpaceCard
                                :list-item="undefined"
                                :date="input.date.value"
                                :num-ppl="numPpl"
                                type="list-space-card-skeleton"
                            />
                        </div>
                    </div>
                    <div>
                        <p
                            v-if="isEmptyResults"
                            class="text-16 text-left mb-3 text-s-bold px-3"
                        >
                            {{ $t('search_bar.empty_results_hint') }}
                        </p>
                        <div
                            v-if="stateHistory.includes('FETCH_STATE_SPACE')"
                            class="d-flex flex-wrap"
                        >
                            <div
                                v-for="(listItem, index) in exactHit"
                                :key="index"
                                class="mb-4 mb-sm-4 px-3 px-sm-3 w-100"
                            >
                                <ListSpaceCard
                                    :list-item="listItem"
                                    :date="input.date.value"
                                    :num-ppl="numPpl"
                                    :type="'desktop-exact-space'"
                                    @navigateToDetails="navigateToDetails"
                                    @setAndDisplayMap="setAndDisplayMap"
                                    @updateMapPopupInfo="updateMapPopupInfo"
                                    @setDesktopMapVisible="setDesktopMapVisible"
                                />
                                <!--                            <ListItem-->
                                <!--                                :list-item="listItem"-->
                                <!--                                :date="input.date.value"-->
                                <!--                                :num-ppl="numPpl"-->
                                <!--                                :type="'desktop-exact-space'"-->
                                <!--                                @navigateToDetails="navigateToDetails"-->
                                <!--                                @setAndDisplayMap="setAndDisplayMap"-->
                                <!--                                @updateMapPopupInfo="updateMapPopupInfo"-->
                                <!--                                @setDesktopMapVisible="setDesktopMapVisible"-->
                                <!--                            />-->
                            </div>
                        </div>
                        <div v-if="stateHistory.includes('FETCH_STATE_NEARBY')">
                            <AlternativeResultsHint>
                                {{ $t('search_bar.nearby_results_hint') }}
                            </AlternativeResultsHint>
                            <div class="d-flex flex-wrap">
                                <div
                                    v-for="(listItem, index) in nearbyHits"
                                    :key="index"
                                    class="mb-4 mb-sm-4 px-3 px-sm-3 w-100"
                                >
                                    <ListSpaceCard
                                        :list-item="listItem"
                                        :date="input.date.value"
                                        :num-ppl="numPpl"
                                        :type="'desktop-exact-nearby'"
                                        @navigateToDetails="navigateToDetails"
                                        @setAndDisplayMap="setAndDisplayMap"
                                        @updateMapPopupInfo="updateMapPopupInfo"
                                        @setDesktopMapVisible="
                                            setDesktopMapVisible
                                        "
                                    />
                                    <!--                                <ListItem-->
                                    <!--                                    :list-item="listItem"-->
                                    <!--                                    :date="input.date.value"-->
                                    <!--                                    :num-ppl="numPpl"-->
                                    <!--                                    :type="'desktop-exact-nearby'"-->
                                    <!--                                    @navigateToDetails="navigateToDetails"-->
                                    <!--                                    @setAndDisplayMap="setAndDisplayMap"-->
                                    <!--                                    @updateMapPopupInfo="updateMapPopupInfo"-->
                                    <!--                                    @setDesktopMapVisible="setDesktopMapVisible"-->
                                    <!--                                />-->
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="stateHistory.includes('FETCH_STATE_AREA')"
                            class="d-flex flex-wrap"
                        >
                            <div
                                v-for="(listItem, index) in areaHits"
                                :key="index"
                                class="mb-4 mb-sm-4 px-3 px-sm-3 w-100"
                            >
                                <ListSpaceCard
                                    :list-item="listItem"
                                    :date="input.date.value"
                                    :num-ppl="numPpl"
                                    :type="'desktop-exact-area'"
                                    @navigateToDetails="navigateToDetails"
                                    @setAndDisplayMap="setAndDisplayMap"
                                    @updateMapPopupInfo="updateMapPopupInfo"
                                    @setDesktopMapVisible="setDesktopMapVisible"
                                />
                                <!--                            <ListItem-->
                                <!--                                :list-item="listItem"-->
                                <!--                                :date="input.date.value"-->
                                <!--                                :num-ppl="numPpl"-->
                                <!--                                :type="'desktop-exact-area'"-->
                                <!--                                @navigateToDetails="navigateToDetails"-->
                                <!--                                @setAndDisplayMap="setAndDisplayMap"-->
                                <!--                                @updateMapPopupInfo="updateMapPopupInfo"-->
                                <!--                                @setDesktopMapVisible="setDesktopMapVisible"-->
                                <!--                            />-->
                            </div>
                        </div>
                        <div
                            v-if="
                                stateHistory.includes('FETCH_STATE_DISTRICT') ||
                                    stateHistory.includes(
                                        'FETCH_STATE_META_DISTRICT'
                                    )
                            "
                        >
                            <AlternativeResultsHint v-if="areaHits.length > 0">
                                {{ $t('search_bar.nearby_results_hint') }}
                            </AlternativeResultsHint>
                            <div class="d-flex flex-wrap">
                                <div
                                    v-for="(listItem, index) in districtHits"
                                    :key="index"
                                    class="mb-4 mb-sm-4 px-3 px-sm-3 w-100"
                                >
                                    <ListSpaceCard
                                        :list-item="listItem"
                                        :date="input.date.value"
                                        :num-ppl="numPpl"
                                        :type="'desktop-exact-district'"
                                        @navigateToDetails="navigateToDetails"
                                        @setAndDisplayMap="setAndDisplayMap"
                                        @updateMapPopupInfo="updateMapPopupInfo"
                                        @setDesktopMapVisible="
                                            setDesktopMapVisible
                                        "
                                    />
                                    <!--                                <ListItem-->
                                    <!--                                    :list-item="listItem"-->
                                    <!--                                    :date="input.date.value"-->
                                    <!--                                    :num-ppl="numPpl"-->
                                    <!--                                    :type="'desktop-exact-district'"-->
                                    <!--                                    @navigateToDetails="navigateToDetails"-->
                                    <!--                                    @setAndDisplayMap="setAndDisplayMap"-->
                                    <!--                                    @updateMapPopupInfo="updateMapPopupInfo"-->
                                    <!--                                    @setDesktopMapVisible="setDesktopMapVisible"-->
                                    <!--                                />-->
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
                                stateHistory.includes(
                                    'FETCH_STATE_AVAILABLE'
                                ) || isFallbackResults
                            "
                        >
                            <AlternativeResultsHint v-if="isExactResults">
                                {{ $t('search_bar.alternative_results_hint') }}
                            </AlternativeResultsHint>
                            <div class="d-flex flex-wrap">
                                <div
                                    v-for="(listItem, index) in availableHits"
                                    :key="index"
                                    class="mb-4 mb-sm-4 px-3 px-sm-3 w-100"
                                >
                                    <ListSpaceCard
                                        :list-item="listItem"
                                        :date="input.date.value"
                                        :num-ppl="numPpl"
                                        :type="'desktop-available'"
                                        @navigateToDetails="navigateToDetails"
                                        @setAndDisplayMap="setAndDisplayMap"
                                        @updateMapPopupInfo="updateMapPopupInfo"
                                        @setDesktopMapVisible="
                                            setDesktopMapVisible
                                        "
                                    />
                                    <!--                                <ListItem-->
                                    <!--                                    :list-item="listItem"-->
                                    <!--                                    :date="input.date.value"-->
                                    <!--                                    :num-ppl="numPpl"-->
                                    <!--                                    :type="'desktop-available'"-->
                                    <!--                                    @navigateToDetails="navigateToDetails"-->
                                    <!--                                    @setAndDisplayMap="setAndDisplayMap"-->
                                    <!--                                    @updateMapPopupInfo="updateMapPopupInfo"-->
                                    <!--                                    @setDesktopMapVisible="setDesktopMapVisible"-->
                                    <!--                                />-->
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="
                                stateHistory.includes(
                                    'FETCH_STATE_ALTERNATIVE'
                                ) ||
                                    stateHistory.includes(
                                        'FETCH_STATE_UNAVAILABLE'
                                    )
                            "
                        >
                            <AlternativeResultsHint
                                v-if="isShowAlternativeUnavailableHints"
                            >
                                {{ $t('search_bar.alternative_results_hint') }}
                            </AlternativeResultsHint>
                            <div class="d-flex flex-wrap">
                                <div
                                    v-for="(listItem,
                                    index) in alternativeHits"
                                    :key="index"
                                    class="mb-4 mb-sm-4 px-3 px-sm-3 w-100"
                                >
                                    <ListSpaceCard
                                        :list-item="listItem"
                                        :date="input.date.value"
                                        :num-ppl="numPpl"
                                        :type="
                                            'desktop-alternative'
                                        "
                                        @navigateToDetails="navigateToDetails"
                                        @setAndDisplayMap="setAndDisplayMap"
                                        @updateMapPopupInfo="updateMapPopupInfo"
                                        @setDesktopMapVisible="
                                            setDesktopMapVisible
                                        "
                                    />
                                </div>
                            </div>
                            <AlternativeResultsHint
                                v-if="isShowAlternativeUnavailableHints"
                            >
                                {{ $t('search_bar.unavailable_results_hint') }}
                            </AlternativeResultsHint>
                            <div class="d-flex flex-wrap">
                                <div
                                    v-for="(listItem,
                                    index) in unavailableHits"
                                    :key="index"
                                    class="mb-4 mb-sm-4 px-3 px-sm-3 w-100"
                                >
                                    <ListSpaceCard
                                        :list-item="listItem"
                                        :date="input.date.value"
                                        :num-ppl="numPpl"
                                        :type="
                                            'desktop-unavailable'
                                        "
                                        @navigateToDetails="navigateToDetails"
                                        @setAndDisplayMap="setAndDisplayMap"
                                        @updateMapPopupInfo="updateMapPopupInfo"
                                        @setDesktopMapVisible="
                                            setDesktopMapVisible
                                        "
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!list.loadingSpace"
                            class="d-flex justify-content-center"
                        >
                            <BookButton
                                v-if="isLocation"
                                :book-button-text="$t('details.see_all')"
                                :button-class="'btn-white'"
                                @buttonClicked="redirectToExactAreaSearch"
                                class="my-3 text-14 w-auto mx-3"
                                style="padding: 10px 100px !important;"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MobileMapView
            v-if="map.visible"
            :map="map"
            :selected-list-item="selectedListItem"
            :space-type-text="input.spaceType.text"
            :class="map.visible ? 'mobile-map-container' : ''"
            @updateMapPopupInfo="updateMapPopupInfo"
            @navigateToDetails="navigateToDetails"
            @handleBtnClick="handleBackBtnClick"
            @close="map.visible = false"
            class="d-block d-lg-none"
            style="flex: 0;"
        />
        <Search
            v-if="isSearchBarVisible"
            :type="'mobile'"
            :num-ppl="numPpl"
            :min-pax="minPax"
            :max-pax="maxPax"
            :selected-date="input.date.value"
            :input-words="input.words"
            :input-price-range="input.priceRange"
            :input-time-frame="input.timeFrame"
            :input-check-in-time="input.checkInTime"
            :input-star-rating="input.starRating"
            :input-theme="input.theme"
            :is-fetching-location="isFetchingLocation"
            :is-show-placeholder-suggestion="false"
            :is-show-theme="true"
            @setNumPpl="setNumPpl"
            @setSpaceType="setSpaceType"
            @setDate="setDate"
            @searchButtonClick="mobileSearchClick"
            @setPriceRange="onFilterPriceRangeUpdated"
            @setTimeFrame="setTimeFrame"
            @setClearFilter="setClearFilter"
            @getCurrentLocation="getCurrentLocation"
            @setQuery="setQuery"
            @close="isSearchBarVisible = false"
            @setQuerySourceFromSearch="setQuerySource"
            @setPriceRangeFromSearchBarSetting="
                setPriceRangeFromSearchBarSetting
            "
            @resetSearchBarSetting="resetSearchBarSetting"
            class="d-lg-none"
        />
        <MapSearch
            v-if="isMapSearchVisible"
            :num-ppl="numPpl"
            :date="input.date.value"
            :space-type="input.spaceType.value"
            :input-price-range="input.priceRange"
            :input-time-frame="input.timeFrame"
            :input-star-rating="input.starRating"
            :input-theme="input.theme"
            :map="map"
            :selected-list-item="selectedListItem"
            @setPriceRange="onFilterPriceRangeUpdated"
            @setTimeFrame="setTimeFrame"
            @setTheme="setTheme"
            @setStarRating="setStarRating"
            @setClearFilter="setClearFilter"
            @updateMapPopupInfo="updateMapPopupInfo"
            @navigateToDetails="navigateToDetails"
            @close="isMapSearchVisible = false"
        />
        <TimeoutRefreshModal
            v-if="isTimeoutRefreshModalVisible"
            @close="setTimeoutRefreshModalVisible"
        />
        <div v-if="isLocation && space === 'hotel'" class="list-container pt-5">
            <ThreeStarSpace
                :date="input.date.value"
                :space="space"
                :query="locationDetails.name"
                :type="locationDetails.type"
                :slug="isLocation"
                :locale-keyword="localeKeyword"
                @navigateToDetails="navigateToDetails"
                class="my-4 my-xl-5"
            />
            <FourStarSpace
                :date="input.date.value"
                :space="space"
                :query="locationDetails.name"
                :type="locationDetails.type"
                :slug="isLocation"
                :locale-keyword="localeKeyword"
                @navigateToDetails="navigateToDetails"
                class="my-4 my-xl-5"
            />
            <FiveStarSpace
                :date="input.date.value"
                :space="space"
                :query="locationDetails.name"
                :type="locationDetails.type"
                :slug="isLocation"
                :locale-keyword="localeKeyword"
                @navigateToDetails="navigateToDetails"
                class="my-4 my-xl-5"
            />
            <SwimmingPoolSpace
                :date="input.date.value"
                :space="space"
                :query="locationDetails.name"
                :type="locationDetails.type"
                :slug="isLocation"
                :locale-keyword="localeKeyword"
                @navigateToDetails="navigateToDetails"
                class="my-4 my-xl-5"
            />
            <FitnessCenterSpace
                :date="input.date.value"
                :space="space"
                :query="locationDetails.name"
                :type="locationDetails.type"
                :slug="isLocation"
                :locale-keyword="localeKeyword"
                @navigateToDetails="navigateToDetails"
                class="my-4 my-xl-5"
            />
        </div>
    </div>
</template>

<script>
import _find from 'lodash/find'
import _isEmpty from 'lodash/isEmpty'
import mapStyle from 'assets/json/map-style.json'
import Vue from 'vue'
import VueScrollTo from 'vue-scrollto'
import {
    computeSearchIndex,
    computeDateDiffSearchIndex,
    computeFallbackSearchType
} from '@/lib-flow-main-search/src/search'
import dateServices from '@/services/dateServices'
import storage from '@/services/storage'
import analysis from '@/services/analysis'
import ListSpaceCard from '@/components/list/ListSpaceCard'
import BookTypeThemeFilter from '@/components/list/BookTypeThemeFilter'
import BasicSearchField from '@/components/ui/BasicSearchField'
import Search from '@/components/modal/Search'
import AdvanceSearch from '@/components/ui/AdvanceSearch'
import MapSearch from '@/components/ui/MapSearch'
import {
    ALGOLIA_EVENT_NAME,
    QUERY_SOURCE_POPULAR_SEARCH,
    QUERY_SOURCE_SUGGESTION,
    TEST_TAG_DOMAIN,
    getSpaceTypeId,
    getAlgoliaLang,
    getQuerySourceQueryParam,
    getExactSearchQueryParam,
    sendObjectClickedEvent,
    sendFilterClickedEvent,
    TYPE_EXACT_SPACE_SEARCH,
    TYPE_EXACT_AREA_SEARCH,
    TYPE_EXACT_DISTRICT_SEARCH,
    TYPE_EXACT_META_DISTRICT_SEARCH
} from '@/services/algoliaApi'
import MobileMapView from '@/components/ui/MobileMapView'
import PillButton from '@/components/item/PillButton'
import PopularSpace from '@/components/location/PopularSpace'
import HourlyHotelSpace from '@/components/location/HourlyHotelSpace'
import HeaderTitle from '@/components/home/HeaderTitle'
import BookButton from '@/components/item/BookButton'
import TotalResultsText from '@/components/ui/TotalResultsText'
import SearchSvg from '@/components/iconSvg/SearchSvg'
import ShowMapSvg from '@/components/iconSvg/ShowMapSvg'
import FilterSvg from '@/components/iconSvg/FilterSvg'
import AnimatedText from '@/components/ui/AnimatedText'
import { DATE_TIME, QUERY_SOURCE } from '@/lib-flow-main-search/src/constants'
import ThreeStarSpace from '~/components/location/ThreeStarSpace'
import FourStarSpace from '~/components/location/FourStarSpace'
import FiveStarSpace from '~/components/location/FiveStarSpace'
import SwimmingPoolSpace from '~/components/location/SwimmingPoolSpace'
import FitnessCenterSpace from '~/components/location/FitnessCenterSpace'
import ExpendLessSvg from '@/components/iconSvg/ExpandLessSvg.vue'
import BackButton from '~/components/item/BackButton.vue'
import AdvanceSearchHeadSection from '~/components/ui/AdvanceSearchHeadSection.vue'
import HorizontalRule from '~/components/item/HorizontalRule.vue'
import DesktopMapButton from '~/components/list/DesktopMapButton.vue'
import SortingButton from '~/components/list/SortingButton.vue'
import AlternativeResultsHint from '@/components/list/AlternativeResultsHint.vue'
import visibilityChangeMixin from '@/mixins/visibilityChangeMixin'
import {
    isBefore8pm,
    isEvening,
    isPeriodTimeFilter,
    mapTimePeriodToTrackingValue
} from '@/lib-flow-main-search/src/utils'
import TimeoutRefreshModal from "@/components/modal/TimeoutRefreshModal.vue"
import {
    getMetaCityName,
    getGAEventName,
    getMetaRegionName,
    getFirstFiveHitsSlug,
    getMetaCountryName, isFromGoogleOrganicSearch
} from "@/utils/analysis";
import common from "@/services/common";

Vue.use(VueScrollTo)
export default {
    name: 'SpaceSearchView',
    components: {
        TimeoutRefreshModal,
        AlternativeResultsHint,
        SortingButton,
        DesktopMapButton,
        HorizontalRule,
        AdvanceSearchHeadSection,
        BackButton,
        ExpendLessSvg,
        FitnessCenterSpace,
        SwimmingPoolSpace,
        FiveStarSpace,
        FourStarSpace,
        ThreeStarSpace,
        FilterSvg,
        BookButton,
        HeaderTitle,
        PopularSpace,
        HourlyHotelSpace,
        PillButton,
        MobileMapView,
        MapSearch,
        AdvanceSearch,
        Search,
        BasicSearchField,
        BookTypeThemeFilter,
        TotalResultsText,
        SearchSvg,
        AnimatedText,
        ShowMapSvg,
        ListSpaceCard
    },
    mixins: [visibilityChangeMixin],
    props: {
        isSetRouteQuery: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isDateVisible: false,
            isSearchBarVisible: false,
            isAddFilter: false,
            numOfFilters: 0,
            sortingVisible: false,
            scrollState: 0,
            map: {
                visible: false,
                center: {
                    lat: 0,
                    lng: 0
                },
                option: {
                    zoomControl: false,
                    zoomControlOptions: {
                        position: 9
                    },
                    streetViewControl: false,
                    streetViewControlOptions: {
                        position: 9
                    },
                    mapTypeControl: false,
                    fullscreenControl: false,
                    styles: mapStyle,
                    scrollwheel: true,
                    gestureHandling: 'greedy'
                },
                popup: {
                    visible: false,
                    selected: {
                        id: '',
                        name: '',
                        currency: '',
                        price: '',
                        image: '',
                        coordinate: {},
                        urlTitle: '',
                        spaceName: ''
                    }
                }
            },
            list: {
                loading: true,
                loadingSpace: true,
                complete: false,
                option: {
                    mode: 'native',
                    pullRefresh: {
                        enable: true
                    },
                    pushLoad: {
                        enable: true,
                        auto: true,
                        autoLoadDistance: 0
                    },
                    sizeStrategy: 'number',
                    scrollPanel: {
                        scrollingX: false,
                        scrollingY: true,
                        speed: 300
                    }
                }
            },
            input: {
                spaceType: {},
                words: '',
                date: {},
                checkInTime: '',
                location: '',
                theme: [],
                priceRange: [0, 2500],
                timeFrame: [],
                starRating: [],
                page: 1,
                sort: {},
                currentUserLatLng: '',
                querySource: '', // temp store the query source(qs) value from SearchingForm
                querySourceType: undefined,
                querySourceValue: undefined,
                suggestCategory: undefined,
                exactSearchType: undefined,
                suggestionId: undefined,
                /** Exact Area, District, Meta District Search */
                locationHierarchies: undefined,
                initialArea: undefined,
                initialDistrict: undefined,
                initialMetaDistrict: undefined,
                city: undefined
            },
            clearFilter: false,
            settings: {
                suppressScrollY: false,
                suppressScrollX: true,
                wheelPropagation: false
            },
            scrollTop: 0,
            isMobileFloatingBtnVisible: false,
            numPpl: 0,
            minPax: 0,
            maxPax: 0,
            isMapSearchVisible: false,
            selectedListItem: {},
            isEmptyResults: false,
            isFetchingLocation: false,
            updateAdvanceFilter: 0,
            updateBookTypeThemeFilter: 0,
            isTestTag: false, // add _tag:test tag to algolia filter or not
            animIndex: 0,
            isMobileFilterSelected: false,
            isShowImmediatePlaceholder: false,
            basicSearchFieldUpdateKey: 0,
            advanceSearchUpdateKey: 10,
            isSetDefaultMap: false,
            isTimeoutRefreshModalVisible: false,
            source: undefined,
            sourceSection: undefined
        }
    },
    async fetch() {
        await this.$store.dispatch('api/GET_REGION_LIST', {
            lang: this.lang,
            region: this.region
        })
    },
    computed: {
        isSigned() {
            return this.$store.getters['member/isSigned']
        },
        space() {
            return this.$route.params.space
        },
        region() {
            return this.$route.params.region
        },
        lang() {
            return this.$route.params.locale
        },
        dates() {
            return this.$store.getters['api/availableDateList']
        },
        searchBarSetting() {
            return this.$store.getters['api/searchBarSetting']
        },
        res() {
            return this.$store.getters['api/searchResult']
        },
        searchResults() {
            return this.$store.getters['algoliaSearch/spaceResult']
        },
        spaceResultHits() {
            return this.$store.getters['algoliaSearch/spaceResultHits']
        },
        popularSpaceResultHits() {
            return this.$store.getters['algoliaSearch/popularSpaceResultHits']
        },
        exactHit() {
            return this.$store.getters['algoliaSearch/exactHit']
        },
        nearbyHits() {
            return this.$store.getters['algoliaSearch/nearbyHits']
        },
        areaHits() {
            return this.$store.getters['algoliaSearch/areaHits']
        },
        districtHits() {
            return this.$store.getters['algoliaSearch/districtHits']
        },
        availableHits() {
            return this.$store.getters['algoliaSearch/availableHits']
        },
        alternativeHits() {
            // eslint-disable-next-line standard/computed-property-even-spacing
            return this.$store.getters[
                'algoliaSearch/alternativeHits'
            ]
        },
        unavailableHits() {
            return this.$store.getters[
                'algoliaSearch/unavailableHits'
                ]
        },
        isShowAlternativeUnavailableHints() {
            return !this.isExactResults &&
                (!this.isLocation ||
                    (this.displayAlternativeForLocation &&
                        this.isLocation))
        },
        stateHistory() {
            return this.$store.getters['algoliaSearch/stateHistory']
        },
        isExactResults() {
            return this.$store.getters['algoliaSearch/isExactResults']
        },
        isFallbackResults() {
            return this.$store.getters['algoliaSearch/isFallbackResults']
        },
        spaceTypeInfo() {
            return this.$store.getters['api/spaceTypeInfo']
        },
        algoliaLang() {
            return getAlgoliaLang(
                this.$route.params.region,
                this.$route.params.locale
            )
        },
        algoliaIndex() {
            if (this.$route.name === 'region-locale-space') {
                return computeDateDiffSearchIndex(
                    this.region,
                    getSpaceTypeId(this.input.spaceType.name),
                    process.env.TYPESENSE_ENV,
                    this.input.date.value
                )
            } else {
                return computeSearchIndex(
                    this.region,
                    getSpaceTypeId(this.input.spaceType.name),
                    process.env.TYPESENSE_ENV
                )
            }
        },
        searchBarPriceRange() {
            return this.$store.getters['api/searchBarPriceRange']
        },
        searchBarPlaceholder() {
            return (
                this.$store.getters['api/searchBarPlaceholder'] ??
                this.$t('search_bar.current_location')
            )
        },
        locationDetails() {
            return this.$store.getters['api/locationDetails']
        },
        isLocation() {
            return this.$route.params.location
        },
        isHourlyHotelLocation() {
            return this.$route.params.location === 'tsuen-wan'
        },
        displayAlternativeForLocation() {
            return this.availableHits?.length < 24
        },
        isKeyword() {
            return this.$route.params.keyword
        },
        localeKeyword() {
            return this.isKeyword ? this.isKeyword.replaceAll('-', '_') : ''
        },
        selectedDisplayDate() {
            if (this.input.date.value) {
                return dateServices.formatSearchBarDisplayDate(
                    this.input.date.value,
                    this.lang
                )
            }
            return ''
        },
        selectedDisplayTime() {
            if (
                this.input.checkInTime === DATE_TIME.ANY_CHECK_IN_TIME ||
                this.input.checkInTime?.[0] === DATE_TIME.ANY_CHECK_IN_TIME
            ) {
                return this.services === 'rest' || this.paramsSpace === 'hotel'
                    ? this.$t('search_bar.any_time')
                    : this.$t('search_bar.any_start_time')
            } else if (this.input.checkInTime === DATE_TIME.ASAP_CHECK_IN_TIME) {
                return this.services === 'rest'
                    ? this.$t('search_bar.asap_time')
                    : this.$t('search_bar.asap_time_non_hotel')
            } else if (Array.isArray(this.input.checkInTime)) {
                return this.input.checkInTime
                    .map(time => this.mapToTimePeriodTranslations(time, this.checkInPeriodsTranslations))
                    .join(', ')
            } else if (isPeriodTimeFilter(this.input.checkInTime)) {
                return this.input.checkInTime.split(',')
                    .map(time => this.mapToTimePeriodTranslations(time, this.checkInPeriodsTranslations))
                    .join(', ')
            } else {
                return this.input.checkInTime
            }
        },
        totalResultHits() {
            return this.$store.getters['algoliaSearch/displayTotalHits']
        },
        isRedirectToListingFromLocation() {
            if (this.isLocation) {
                const isQueryChanged =
                    this.input.words !== this.locationDetails.name
                const isSpaceTypeChanged = this.isSpaceTypeChanged(
                    this.globalSpace(this.input.spaceType.name)
                )
                return isQueryChanged || isSpaceTypeChanged
            }
            return false
        },
        isListing() {
            return !this.isLocation
        },
        prefGroup() {
            return this.$store.getters['general/prefGroup']
        },
        isBlockGooglebotFromSearchSection() {
            return this.isBlockGooglebotFromButton && this.isLocation
        },
        checkInPeriodsTranslations() {
            const translations = {}
            this.$store.getters['api/checkInPeriods'].forEach(period => {
                translations[period.value] = period.text
            })
            return translations
        },
        getGATimeParams() {
            const queryTime = this.$route.query.time
            const timeArr = queryTime?.split(',')
            if (timeArr?.length > 0) {
                const GATime = timeArr.map(time => {
                    return mapTimePeriodToTrackingValue(time)
                })
                return GATime.join(', ')
            }
            return ''
        },
        isDefaultPriceRange() {
            return this.input.priceRange[0] === this.searchBarPriceRange.min
                && this.input.priceRange[1] === this.searchBarPriceRange.max
        },
        themeOverrideTagsDict() {
            const tags = {}
            this.searchBarSetting.filtering
                .filter((_) => _.ga_filter_type === 'theme')
                .flatMap((_) => _.value)
                .forEach((_) => {
                    if (_.override_tags) {
                        tags[_.value] = _.override_tags
                    }
                })
            return tags
        },
        prevRoute() {
            return this.$store.state.prevRoute
        }
    },
    watch: {
        isEmptyResults(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.fallbackSearch(true)
            }
        },
        isSigned: {
            immediate: true,
            async handler(newVal) {
                if (newVal) {
                    const idToken = await this.getIdToken()
                    const profile = await this.$store.dispatch(
                        'api/GET_MEMBER_PROFILE_PREFILL',
                        {
                            id_token: idToken,
                            region: this.region,
                            lang: this.lang
                        }
                    )
                    // if (profile?.email?.endsWith(TEST_TAG_DOMAIN)) {
                    //     this.isTestTag = true
                    //     await this.startSearching(true, false)
                    // }
                }
            }
        },
        /* LOCATION SEARCH START */
        popularSpaceResultHits(newVal) {
            if (newVal.length > 0 && this.isLocation) {
                this.startSearching(true)
            }
        },
        prefGroup: {
            immediate: false,
            async handler(newVal, oldVal) {
                if (newVal !== oldVal && this.$device.isDesktop) {
                    await new Promise((r) => setTimeout(r, 1000))
                    await this.startSearching()
                }
            }
        },
        '$route' () {
            this.setDateFromQuery()
        },
        spaceResultHits(newVal) {
            if (
                newVal.length > 0 &&
                newVal[0].status?.day_space_status &&
                !this.isSetDefaultMap
            ) {
                this.isSetDefaultMap = true
                this.setDefaultMap()
            }
        }
    },
    created() {
        // SSR
        this.setRouteQueryInput()
    },
    mounted() {
        this.setRouteQueryInput()
        /* LISTING SEARCH START */

        this.source = this.$route.params.source

        if (this.isListing && !this.isBlockGooglebotFromButton) {
            this.startSearching(true)
        }
        // this.$store.watch(
        //     (state) => state.algoliaSearch.spaceResult,
        //     this.setDefaultMap,
        //     {
        //         immediate: true,
        //         deep: true
        //     }
        // )
        window.addEventListener('scroll', this.handleScrollMethod)
        this.$on('visibilityChange', this.handleVisibilityChange)
    },
    destroyed() {
        document.documentElement.style.overflow = 'auto'
        window.removeEventListener('scroll', this.handleScrollMethod)
    },
    methods: {
        async getSearchRes(
            params,
            resetResults = false,
            resetIsEmptyResult = true
        ) {
            try {
                if (this.$route.name !== 'region-locale-space') {
                    params.group = undefined
                }
                params.isTestTag = this.isTestTag
                params.analyticsTags = this.searchAnalyticsTags()
                params.isOverride = this.isOverride
                params.themeOverrideTagsDict = this.themeOverrideTagsDict
                if (resetResults) {
                    await this.$store.dispatch('api/RESET_SEARCH_RES')
                }
                if (resetIsEmptyResult) {
                    this.isEmptyResults = false
                }
                // add f_num_ppl in params and space_type = 'party'
                // Algolia Search
                const {
                    hasNext,
                    isEmptyResults,
                    isFetchMoreResult
                } = await this.$store.dispatch(
                    'algoliaSearch/SEARCH_SPACE_INDEX',
                    params
                )
                if (isEmptyResults) {
                    this.isEmptyResults = true
                }
                // complete = no more lists
                // loading = loading spaces
                this.list.loading = false
                this.list.complete = !hasNext
                /*
                    Remove this will cause location page get empty result
                 */
                // await this.$store.dispatch('api/GET_SEARCH_BAR_SETTING', params)
                if (isFetchMoreResult) {
                    await this.handleScrollEnd(params)
                } else {
                    this.list.loadingSpace = false
                }
            } catch (err) {
                this.$nuxt.error(err)
                // this.$sentry.captureException(err)
            }
        },
        setDefaultPax() {
            let defaultPax = ''
            this.spaceTypeInfo.map((info) => {
                if (info.space_type === 'party') {
                    this.minPax = info.min_pax
                    this.maxPax = info.max_pax
                    defaultPax = info.default_pax
                }
            })
            const queryNumPpl = parseInt(this.$route.query.numPpl || defaultPax)
            if (queryNumPpl > this.maxPax) {
                this.numPpl = this.maxPax
            } else if (queryNumPpl < this.minPax) {
                this.numPpl = defaultPax
            } else {
                this.numPpl = queryNumPpl
            }
        },
        setRouteQueryInput() {
            // SSR start from here
            this.resetData()
            this.setPriceRangeFromSearchBarSetting()
            this.setDateFromQuery()
            this.setSpaceTypeFromParams()
            this.setCheckInTimeFromQuery()
            this.setSearchKeywordFromQuery()
            this.setSortFromQuery()
            this.setThemeFromQuery()
            this.setPriceRangeFromQuery()
            this.setStarRatingFromQuery()
            this.setBookTypeFromQuery()
            // this.preselectMaxPriceForCheapHotelsLocationPage()
            this.setBookTypeForLocationPages()
            this.setCurrentUserLatLngFromQuery()
            this.setQuerySourceFromQuery()
            this.setExactSearchTypeFromQuery()
            this.setSuggestionIdFromQuery()
            this.setAreaFromQuery()
            this.setDistrictFromQuery()
            this.setMetaDistrictFromQuery()
            this.setCityFromQuery()
            this.setDefaultPax()
        },
        resetData() {
            // reset data here avoid duplicate input
            this.numOfFilters = 0
            this.input.timeFrame = []
            this.input.starRating = []
            this.input.theme = []
        },
        setDateFromQuery() {
            const dateRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
            const dateQuery = this.$route.query.date?.toString()
            let date = ''
            if (dateRegex.test(dateQuery)) {
                date = dateServices.isDayEnded(dateQuery, this.region)
                    ? dateServices.getTomorrow()
                    : dateQuery
            } else if (dateQuery === 'tmr') {
                date = dateServices.getTomorrow()
            } else {
                date = dateServices.getToday()
            }
            this.setDate(date, true)
        },
        setSpaceTypeFromParams() {
            const type = this.globalSpaceType(this.space)
            this.setSpaceType(type, true)
        },
        setCheckInTimeFromQuery() {
            const timeRegex = /(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]/
            const timeQuery = this.$route.query.time?.toString()
            if (
                isPeriodTimeFilter(timeQuery) ||
                timeQuery === DATE_TIME.ASAP_CHECK_IN_TIME ||
                timeQuery === DATE_TIME.ANY_CHECK_IN_TIME ||
                timeRegex.test(timeQuery)
            ) {
                this.setCheckInTime(timeQuery, true)
            }
        },
        setSearchKeywordFromQuery() {
            const query = this.isLocation
                ? this.locationDetails.name
                : this.input.words || this.$route.query.q
            if (query) this.setSearchKeyword(query, true)
        },
        setSortFromQuery() {
            let sortItem = null
            if (this.$route.query.s_price) {
                sortItem = this.setSortItem(
                    's_price',
                    this.$route.query.s_price
                )
            }
            if (this.$route.query.s_star_rating) {
                sortItem = this.setSortItem(
                    's_star_rating',
                    this.$route.query.s_star_rating
                )
            }
            if (this.$route.query.s_price_per_hour) {
                sortItem = this.setSortItem(
                    's_price_per_hour',
                    this.$route.query.s_price_per_hour
                )
            }
            if (this.$route.query.s_user_rating) {
                sortItem = this.setSortItem(
                    's_user_rating',
                    this.$route.query.s_user_rating
                )
            }
            if (sortItem) {
                this.setSort(sortItem, true)
            }
        },
        setThemeFromQuery() {
            if (this.$route.query.f_theme) {
                const themes = this.$route.query.f_theme.split(',')
                if (themes.length > 0) {
                    themes.forEach((item) => {
                        this.input.theme.push(item)
                    })
                }
                this.isAddFilter = true
                this.updateBookTypeThemeFilter += 1
                this.numOfFilters += 1
            }
        },
        setPriceRangeFromSearchBarSetting() {
            if (
                this.searchBarPriceRange.min !== undefined &&
                this.searchBarPriceRange.max !== undefined
            ) {
                this.input.priceRange = [
                    this.searchBarPriceRange.min,
                    this.searchBarPriceRange.max
                ]
            }
        },
        setPriceRangeFromQuery() {
            if (this.$route.query.f_priceRange_min) {
                this.input.priceRange[0] = this.$route.query.f_priceRange_min
            }
            if (this.$route.query.f_priceRange_max) {
                this.input.priceRange[1] = this.$route.query.f_priceRange_max
            }
            if (
                this.$route.query.f_priceRange_min ||
                this.$route.query.f_priceRange_max
            ) {
                this.isAddFilter = true
                this.numOfFilters += 1
                this.advanceSearchUpdateKey += 1
                this.isMobileFilterSelected = true
            }
        },
        // preselectMaxPriceForCheapHotelsLocationPage(){
        //     // Preselect max price for cheap hotels location page
        //     if (
        //         this.isKeyword === 'cheap-hotels' &&
        //         !this.$route?.query?.f_priceRange_max
        //     ) {
        //         const maxPrice = {
        //             hk: 1000,
        //             mo: 1000,
        //             sg: 200,
        //             my: 600
        //         }
        //         this.input.priceRange[1] = maxPrice[this.region]
        //     }
        // },
        setStarRatingFromQuery() {
            if (this.$route.query.f_star_rating) {
                const ratings = this.$route.query.f_star_rating.split(',')
                if (ratings.length > 0) {
                    ratings.forEach((item) => {
                        this.input.starRating.push(parseInt(item))
                    })
                }
                this.isAddFilter = true
                this.numOfFilters += 1
                this.advanceSearchUpdateKey += 1
                this.isMobileFilterSelected = true
            }
        },
        setBookTypeFromQuery() {
            if (this.$route.query.f_book_type) {
                let bookTypes = this.$route.query.f_book_type.split(',')
                if (
                    bookTypes.includes('hour') &&
                    bookTypes.includes('session')
                ) {
                    bookTypes.splice(bookTypes.indexOf('hour'), 1)
                    bookTypes.splice(bookTypes.indexOf('session'), 1)
                    bookTypes = ['hour,session', ...bookTypes]
                }
                if (bookTypes.length > 0) {
                    bookTypes.forEach((item) => {
                        this.input.timeFrame.push(item)
                    })
                }
                this.isAddFilter = true
                this.updateBookTypeThemeFilter += 1
                this.numOfFilters += 1
            }
        },
        setBookTypeForLocationPages() {
            if (this.isKeyword === 'staycation') {
                if (!this.input.timeFrame.includes('overnight')) {
                    this.input.timeFrame.push('overnight')
                    this.isAddFilter = true
                    this.numOfFilters += 1
                }
            } else if (this.isKeyword === 'day-use-hotels') {
                if (!this.input.timeFrame.includes('hour,session')) {
                    this.input.timeFrame.push('hour,session')
                    this.isAddFilter = true
                    this.numOfFilters += 1
                }
            }
        },
        setCurrentUserLatLngFromQuery() {
            if (this.$route.query.user_lat_long) {
                this.input.currentUserLatLng = this.$route.query.user_lat_long
                this.setSearchKeyword(this.$t('search_bar.current_location'))
            }
        },
        setQuerySourceFromQuery() {
            if (this.$route.query.qs) {
                this.input.querySource = this.$route.query.qs
            }
        },
        setExactSearchTypeFromQuery() {
            if (this.$route.query.es_type) {
                this.input.exactSearchType = this.$route.query.es_type
            }
        },
        setSuggestionIdFromQuery() {
            if (this.$route.query.sId) {
                this.input.suggestionId = this.$route.query.sId
            }
        },
        setAreaFromQuery() {
            if (this.$route.query.area) {
                this.input.initialArea = this.$route.query.area
            }
        },
        setDistrictFromQuery() {
            if (this.$route.query.district) {
                this.input.initialDistrict = this.$route.query.district
            }
        },
        setMetaDistrictFromQuery() {
            if (this.$route.query.meta_district) {
                this.input.initialMetaDistrict = this.$route.query.meta_district
            }
        },
        setCityFromQuery() {
            if (this.$route.query.city) {
                this.input.city = this.$route.query.city
            }
        },
        setSortItem(key, value) {
            return this.searchBarSetting?.sorting?.find((item) => {
                return item.key === key && item.value === value
            })
        },
        setDefaultMap() {
            if (
                this.spaceResultHits?.length > 0 &&
                !_isEmpty(this.spaceResultHits[0])
            ) {
                const item = this.spaceResultHits[0]
                this.setMapCenter(item)
                this.updateMapPopupInfo(item)
            } else {
                this.map.popup.visible = false
            }
        },
        setMapCenter(item) {
            this.map.center.lat = item?._geoloc[0]
            this.map.center.lng = item?._geoloc[1]
            if (this.deviceType() === 'desktop') {
                this.map.center.lat += 0.007
            }
        },
        // updateMapCenter(coordinate) {
        //     console.log('updateMapCenter');
        //     if (this.$refs.map) {
        //         this.$refs.map.panTo(coordinate)
        //     }
        // },
        updateMapPopupInfo(item) {
            this.setMapCenter(item)
            this.selectedListItem = item
            const coordinate = {
                lat: item._geoloc[0],
                lng: item._geoloc[1]
            }
            this.map.popup.visible = true
            this.map.popup.selected = {
                coordinate,
                image: item.images?.length > 0 ? item.images[0] : '',
                name: item.name[this.algoliaLang],
                currency: item.location?.country?.currency?.code,
                price: item.status?.price?.min ?? 0,
                id: item.slug,
                date: this.input.date.value,
                type: this.input.spaceType.value,
                urlTitle: item.slug,
                spaceName: item.name[this.algoliaLang]
            }
        },
        async setAndDisplayMap(item, section) {
            this.hiddenScrollBar()
            this.setMapCenter(item)
            await this.updateMapPopupInfo(item)
            this.map.visible = true
            this.sendLogEventViewMap(section)
        },
        setDefaultSorting() {
            this.setSort(this.searchBarSetting.sorting[0], true)
        },
        setSpaceType(type, fromCreated = false) {
            // spaceType changed, reset sort index
            if (
                this.input.spaceType.name !== type &&
                this.searchBarSetting.sorting?.length > 0
            ) {
                this.setSort(this.searchBarSetting.sorting[0], true)
            }
            const spaceTypeDetails = this.spaceTypeInfo.find((item) => {
                return item.appclip_space_type === this.space
            })
            if (!type) {
                type = spaceTypeDetails.space_type
            }
            const spaceTypeString = spaceTypeDetails.space_type_string
            switch (type) {
                case 'rest':
                    this.input.spaceType = {
                        text: spaceTypeString,
                        value: '1',
                        name: 'rest',
                        header: 'list.daycation',
                        icon: spaceTypeDetails.icon
                    }
                    break
                case 'work':
                    this.input.spaceType = {
                        text: spaceTypeString,
                        value: '2',
                        name: 'work',
                        header: 'list.work_spaces',
                        icon: spaceTypeDetails.icon
                    }
                    break
                case 'meet':
                    this.input.spaceType = {
                        text: spaceTypeString,
                        value: '3',
                        name: 'meet',
                        header: 'list.meeting_rooms',
                        icon: spaceTypeDetails.icon
                    }
                    break
                case 'staycation':
                    this.input.spaceType = {
                        text: spaceTypeString,
                        value: '4',
                        name: 'staycation',
                        header: 'list.staycation',
                        icon: spaceTypeDetails.icon
                    }
                    break
                case 'party':
                    this.input.spaceType = {
                        text: spaceTypeString,
                        value: '5',
                        name: 'party',
                        header: 'list.party',
                        icon: spaceTypeDetails.icon
                    }
                    break
                default:
                    this.input.spaceType = {
                        text: spaceTypeString,
                        value: '1',
                        name: '',
                        header: 'list.daycation',
                        icon: spaceTypeDetails.icon
                    }
                    break
            }
        },
        setDate(date, fromCreated = false) {
            switch (date) {
                case dateServices.getToday():
                    this.input.date = {
                        text: 'list.today',
                        value: dateServices.getToday()
                    }
                    break
                case dateServices.getTomorrow():
                    this.input.date = {
                        text: 'list.tomorrow',
                        value: dateServices.getTomorrow()
                    }
                    break
                default:
                    this.input.date = {
                        text: date,
                        value: date
                    }
            }
            if (fromCreated) {
                this.basicSearchFieldUpdateKey += 1
            }
        },
        setSort(item, fromCreated = false) {
            this.input.sort = item
            if (!fromCreated) {
                this.sourceSection = 'SORT';
                this.startSearching()
                const source = getGAEventName(this.landingPageName,undefined, item.value + '_' + item?.ga_value)
                analysis.sendSort(source, item?.ga_value)
            }
        },
        updateQuery(params) {
            this.isAddFilter = false
            this.numOfFilters = 0
            const query = {
                date: params.f_date,
                time: params.f_time || undefined,
                q: params.q || undefined,
                s_price: params.s_price || undefined,
                s_price_per_hour: params.s_price_per_hour || undefined,
                s_star_rating: params.s_star_rating || undefined,
                s_user_rating: params.s_user_rating || undefined,
                s_early_available: params.s_early_available || undefined,
                user_lat_long: params.user_lat_long || undefined,
                qs: params.qs || undefined,
                es_type: params.es_type || undefined,
                area: params.area || undefined,
                district: params.district || undefined,
                meta_district: params.meta_district || undefined,
                city: params.city || undefined,
                suggestionId: params.suggestionId || undefined,
                group:
                    params.group && this.isLocationGroupValid
                        ? params.group
                        : this.region === 'my'
                        ? 'klang-valley'
                        : undefined
            }
            // if (params.s_favourite) {
            //     query.s_favourite = storage.get(storage.key.liked)
            // }
            if (!this.clearFilter) {
                if (params.f_priceRange_min) {
                    query.f_priceRange_min = params.f_priceRange_min
                    this.isAddFilter = true
                }
                if (params.f_priceRange_max) {
                    query.f_priceRange_max = params.f_priceRange_max
                    this.isAddFilter = true
                }
                if (params.f_priceRange_min || params.f_priceRange_max) {
                    this.numOfFilters += 1
                }
                if (params.f_book_type && params.space_type !== 'party') {
                    query.f_book_type = params.f_book_type
                    this.isAddFilter = true
                    this.numOfFilters += 1
                }
                if (params.f_num_ppl) {
                    query.numPpl = params.f_num_ppl
                }
                if (params.f_star_rating) {
                    query.f_star_rating = params.f_star_rating
                    this.isAddFilter = true
                }
                if (params.f_theme) {
                    query.f_theme = params.f_theme
                    this.isAddFilter = true
                    this.numOfFilters += 1
                }
            }
            return query
        },
        getUserInput(nextPage = this.input.page) {
            const checkInTime = Array.isArray(this.input.checkInTime)
                ? this.input.checkInTime.join(',')
                : this.input.checkInTime
            const now = new Date().valueOf()
            const today = dateServices.getToday()
            const isDayEnded = dateServices.isDayEnded(
                this.input.date.value,
                this.region
            )
            const isAutoNextDay = this.input.date.value === today &&
                isEvening(now, today, this.paramsRegion) &&
                !isBefore8pm(now, today, this.paramsRegion) &&
                (
                    this.input.checkInTime.includes(DATE_TIME.MIDNIGHT_TIME) ||
                    this.input.checkInTime.includes(DATE_TIME.AFTER_MIDNIGHT_TIME)
                )
            if (isAutoNextDay) {
                this.setDate(dateServices.getTomorrow(), true)
            }
            const obj = {
                space_type: this.input.spaceType.name,
                f_date: isDayEnded || isAutoNextDay
                    ? dateServices.getTomorrow()
                    : this.input.date.value,
                f_time: dateServices.isDayEnded(
                    this.input.date.value,
                    this.region
                )
                    ? DATE_TIME.ANY_CHECK_IN_TIME
                    : checkInTime,
                page: nextPage,
                lang: this.lang,
                region: this.region,
                group: this.paramsGroup,
                device: this.deviceType(),
                algoliaIndex: this.algoliaIndex,
                algoliaLang: this.algoliaLang,
                space_type_num: this.globalSpaceTypeNum(this.space),
                f_num_ppl: this.space === 'party' ? this.numPpl : undefined,
                [this.input.sort.key]:
                    this.input.sort.key !== ''
                        ? this.input.sort.value
                        : undefined,
                f_priceRange_min:
                    this.input.priceRange[0] > this.searchBarPriceRange.min
                        ? this.input.priceRange[0]
                        : undefined,
                f_priceRange_max:
                    this.input.priceRange[1] < this.searchBarPriceRange.max
                        ? this.input.priceRange[1]
                        : undefined,
                f_book_type:
                    this.input.timeFrame.length > 0
                        ? this.input.timeFrame.join(',')
                        : undefined,
                f_star_rating:
                    this.input.starRating.length > 0
                        ? this.input.starRating.join(',')
                        : undefined,
                f_theme:
                    this.input.theme.length > 0
                        ? this.input.theme.join(',')
                        : undefined
            }
            if (this.input.currentUserLatLng.length > 0) {
                obj.user_lat_long = this.input.currentUserLatLng
                obj.q = ''
            } else if (this.input.words !== this.searchBarPlaceholder) {
                obj.q = this.input.words
                this.input.currentUserLatLng = ''
            }
            if (this.input.querySource) {
                obj.qs = this.input.querySource
                this.isShowImmediatePlaceholder =
                    obj.qs === QUERY_SOURCE.EMPTY_SEARCH
            } else {
                obj.qs = getQuerySourceQueryParam(
                    this.$t('search_bar.current_location'),
                    this.input.words,
                    this.input.querySourceValue,
                    this.input.querySourceType
                )
            }
            if (this.input.exactSearchType) {
                obj.es_type = this.input.exactSearchType
            } else {
                obj.es_type = getExactSearchQueryParam(
                    this.input.words,
                    this.input.querySourceValue,
                    this.input.querySourceType,
                    this.input.suggestCategory
                )
            }
            if (obj.es_type === TYPE_EXACT_AREA_SEARCH) {
                if (this.input.initialArea) {
                    obj.area = this.input.initialArea
                } else if (this.input.locationHierarchies?.[0]) {
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    obj[
                        this.input.locationHierarchies[0].field
                    ] = this.input.locationHierarchies[0].value
                }
                if (this.input.initialDistrict) {
                    obj.district = this.input.initialDistrict
                } else if (this.input.locationHierarchies?.[1]) {
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    obj[
                        this.input.locationHierarchies[1].field
                    ] = this.input.locationHierarchies[1].value
                }
            } else if (obj.es_type === TYPE_EXACT_DISTRICT_SEARCH) {
                if (this.input.initialDistrict) {
                    obj.district = this.input.initialDistrict
                } else {
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    obj[
                        this.input.locationHierarchies[0].field
                    ] = this.input.locationHierarchies[0].value
                }
            } else if (obj.es_type === TYPE_EXACT_META_DISTRICT_SEARCH) {
                if (this.input.initialMetaDistrict) {
                    obj.meta_district = this.input.initialMetaDistrict
                } else {
                    // eslint-disable-next-line standard/computed-property-even-spacing
                    obj[
                        this.input.locationHierarchies[0].field
                    ] = this.input.locationHierarchies[0].value
                }
            } else if (obj.es_type === TYPE_EXACT_SPACE_SEARCH) {
                if (this.input.suggestionId) {
                    obj.suggestionId = this.input.suggestionId
                }
            }
            return obj
        },
        updateSelectedFilters(selected) {
            if (selected?.date) this.setDate(selected.date)
            if (selected?.checkInTime) this.setCheckInTime(selected.checkInTime)
            if (selected?.spaceType) this.setSpaceType(selected.spaceType)
            // if (!selected.getCurrentLocation) this.input.sort.key = ''
            if (
                selected?.spaceType === 'party' &&
                this.input.sort.key === 's_star_rating'
            ) {
                this.setDefaultSorting()
            }
            if (selected?.priceRange)
                this.onFilterPriceRangeUpdated(selected.priceRange, false)
            if (selected?.timeFrame)
                this.setTimeFrame(selected.timeFrame, false)
            if (selected?.starRating)
                this.setStarRating(selected.starRating, false)
            if (selected?.theme) this.setTheme(selected.theme, false)
            if (selected?.numPpl) this.setNumPpl(selected.numPpl)
        },
        checkMobileFilterSelected(selected) {
            if (
                selected?.priceRange?.length > 0 &&
                selected?.priceRange[0] === this.searchBarPriceRange.min &&
                selected?.priceRange[1] === this.searchBarPriceRange.max
            ) {
                return false
            }
            return !!(selected?.priceRange || selected?.starRating?.length > 0)
        },
        async startSearching(fromCreated = false, isSendEvent = true) {
            this.setLoadingState(true)
            if (this.isLocation) {
                this.exactAreaSearchForKeywordPage()
            }
            const params = this.getUserInput()
            params.isNewSearch = true
            const isSpaceTypeChanged = this.isSpaceTypeChanged(
                this.globalSpace(params.space_type)
            )
            if (isSpaceTypeChanged) {
                this.resetFiltersIfSpaceTypeChanged()
            }
            /**
             console.log('_space, startSearching, params, ', params)
             */

            await this.getSearchBarSetting()
            /** Send log event and search before route update **/
            if (!isSpaceTypeChanged) {
                if (isSendEvent) {
                    this.sendLogEventSearchResult(params.es_type, params.q, !!params.user_lat_long)
                }
                await this.getSearchRes(params, true, true)
            }

            if (!(fromCreated && this.isLocation)) {
                await this.handleRouteUpdate(params)
            }
        },
        isSpaceTypeChanged(currentSpaceType, prevSpaceType = this.space) {
            return (
                prevSpaceType !== undefined &&
                prevSpaceType !== currentSpaceType
            )
        },
        setLoadingState(isLoading) {
            this.list.loading = isLoading
            this.list.loadingSpace = isLoading
        },
        resetFiltersIfSpaceTypeChanged() {
            this.updateAdvanceFilter += 1
            this.updateBookTypeThemeFilter += 1
            this.clearFilter = true
            this.input.priceRange = [1, 2]
            this.input.timeFrame = []
            this.input.starRating = []
            this.input.theme = []
            this.numPpl = 0
        },
        async handleRouteUpdate(params) {
            const query = this.updateQuery(params)
            await this.updateRouteSpaceParams(params, query)
        },
        async updateRouteSpaceParams(params, query) {
            const obj = {
                region: this.region,
                locale: this.lang,
                space: this.globalSpace(params.space_type)
            }
            const routeName = this.isRedirectToListingFromLocation
                ? 'region-locale-space'
                : this.$route.name
            if (routeName === 'region-locale-space-location-keyword') {
                obj.keyword = this.isKeyword
                obj.location = this.isLocation
            }
            await this.$router.push({
                name: routeName,
                params: obj,
                query
            })
            // update route query
            // if (query) {
            //     this.$router
            //         .replace({
            //             query
            //         })
            //         .catch((error) => {
            //             if (
            //                 error.name !== 'NavigationDuplicated' &&
            //                 !error.message.includes(
            //                     'Avoided redundant navigation to current location'
            //                 )
            //             ) {
            //                 // But print any other errors to the console
            //                 this.$sentry.captureException(error)
            //             }
            //         })
            // }
        },
        async fallbackSearch(isNewSearch) {
            const pageNum = isNewSearch ? 1 : this.searchResults.nextPage
            const isTodayEnded = dateServices.isDayEnded(
                dateServices.getToday(),
                this.region
            )
            let fallbackTime
            if (this.input.checkInTime === DATE_TIME.ANY_CHECK_IN_TIME) {
                fallbackTime = DATE_TIME.ANY_CHECK_IN_TIME
            } else {
                fallbackTime = isTodayEnded ? '00:00' : this.input.checkInTime
            }
            const fallbackParams = {
                space_type: this.input.spaceType.name,
                f_date: isTodayEnded
                    ? dateServices.getTomorrow()
                    : dateServices.getToday(),
                f_time: fallbackTime,
                page: pageNum,
                lang: this.lang,
                region: this.region,
                group: this.paramsGroup,
                device: this.deviceType(),
                algoliaIndex: this.algoliaIndex,
                space_type_num: this.globalSpaceTypeNum(this.space),
                q: '',
                qs: 'es', // query source: empty search
                fallbackType: computeFallbackSearchType(fallbackTime)
            }
            fallbackParams.isNewSearch = isNewSearch
            await this.getSearchRes(fallbackParams, isNewSearch, false)
        },
        setQuery(query, isGetCurrentLocation) {
            if (query === '') {
                this.input.querySource = undefined
                this.input.exactSearchType = undefined
                this.input.initialArea = undefined
                this.input.initialDistrict = undefined
                this.input.initialMetaDistrict = undefined
            }
            this.input.words = query
            if (isGetCurrentLocation) {
                this.getCurrentLocation()
            } else {
                this.input.currentUserLatLng = ''
            }
        },
        onFilterPriceRangeUpdated(
            priceRange,
            isSearch = true,
            isMapFilter = false
        ) {
            this.scroll(false)
            this.setFilter('priceRange', priceRange, isMapFilter)
            if (isSearch) this.startSearching()
        },
        setTimeFrame(timeFrame, isSearch = true, isMapFilter = false) {
            this.scroll(false)
            this.setFilter('timeFrame', timeFrame, isMapFilter)
            if (isSearch) this.startSearching()
        },
        setStarRating(starRating, isSearch = true, isMapFilter = false) {
            this.scroll(false)
            this.setFilter('starRating', starRating, isMapFilter)
            if (isSearch) this.startSearching()
        },
        setTheme(theme, isSearch = true, isMapFilter = false, isQuickFilter = false) {
            this.scroll(false)
            this.setFilter('theme', theme, isMapFilter, isQuickFilter)
            if (isSearch) this.startSearching()
        },
        setClearFilter() {
            this.scroll(false)
            this.updateAdvanceFilter += 1
            this.updateBookTypeThemeFilter += 1
            this.clearFilter = true
            this.resetPriceRange()
            this.input.timeFrame = []
            this.input.starRating = []
            this.input.theme = []
            setTimeout(() => {
                this.startSearching()
            }, 200)
        },
        resetPriceRange() {
            this.searchBarSetting.filtering.map((filter) => {
                if (
                    filter.key === 'f_priceRange' &&
                    this.input.priceRange?.length > 0
                ) {
                    this.input.priceRange[0] = filter.value.min
                    this.input.priceRange[1] = filter.value.max
                }
            })
        },
        setFilter(filter, value, isMapFilter, isQuickFilter) {
            this.clearFilter = false
            this.updateAdvanceFilter += 1
            this.updateBookTypeThemeFilter += 1
            let shouldSendFilterEvent = false
            const obj = {}
            switch (filter) {
                case 'timeFrame':
                    obj.time_frame = value
                    this.input.timeFrame = value
                    break
                case 'priceRange':
                    obj.price_range = value
                    this.input.priceRange = value
                    this.sourceSection = 'SEARCH_FILTER'
                    break
                case 'starRating':
                    obj.star_rating = value
                    this.input.starRating = value
                    this.sourceSection = 'SEARCH_FILTER'
                    shouldSendFilterEvent = true
                    break
                case 'theme':
                    obj.theme = value
                    this.input.theme = value
                    this.sourceSection = 'THEME_FILTER'
                    shouldSendFilterEvent = true
                    break
            }
            if (isQuickFilter) {
                const source = getGAEventName(this.landingPageName)
                analysis.sendApplyQuickFilter(source, obj)
            }
            if (shouldSendFilterEvent && process.client) {
                sendFilterClickedEvent(this.algoliaIndex, isMapFilter, {
                    timeFrame: this.input.timeFrame,
                    starRating: this.input.starRating,
                    theme: this.input.theme
                })
            }
        },
        // setLocation(number) {
        //     this.input.location = number
        //     let list = []
        //     list = _cloneDeep(this.input.location)
        //
        //     if (_find(this.input.location, { value: number })) {
        //         _remove(list, (item) => item.value === number)
        //     } else {
        //         _remove(list, (item) => item.value === number)
        //         let text = ''
        //         switch (number) {
        //             case '0':
        //                 text = 'list.all-hk'
        //                 break
        //             case '1':
        //                 text = 'list.all-kn'
        //                 break
        //             case '2':
        //                 text = 'list.all-nt'
        //                 break
        //             case '3':
        //                 text = 'list.all-oi'
        //                 break
        //             case '4':
        //                 text = 'list.all-mc'
        //                 break
        //         }
        //         list.push({
        //             text,
        //             value: number
        //         })
        //     }
        //     this.input.location = list
        // },
        // this.$router.push({
        //     name: 'region-locale-order-checkout',
        //     params: {
        //         region: this.region,
        //         locale: this.lang,
        //         isPaying: true,
        //         space: this.$route.params.space,
        //         spaceName: this.slug,
        //         venueType: this.selected.room.value,
        //         bookId: this.selected.bookLength.bookId,
        //         startTime: this.selected.checkIn.value,
        //         endTime: this.selected.checkOut.value,
        //         bookPrice: this.selected.price.value,
        //         rateId: this.selected.rateId,
        //         priceCheckToken: this.selected.priceCheckToken,
        //         bedGroupId: this.selected.bedGroupId,
        //         guarantee: this.selected.guarantee,
        //         payType: this.selected.payType,
        //         refund: this.selected.refund,
        //         dist: this.selected.dist
        //     },
        //     query: {
        //         is_walkin: this.isWalkIn === 1 ? 'true' : 'false'
        //     }
        // })
        navigateToDetails(params) {
            this.globalNavigateToDetails(
                params,
                this.space,
                this.input.date.value
            )
            params.theme = this.input.theme
            params.starRating = this.input.starRating
            params.timeFrame = this.input.timeFrame
            this.sendLogEventsItem(params)
        },
        handleScrollEnd() {
            // vertical.process > 0.9
            if (!this.list.loading && !this.list.complete) {
                this.list.loading = true
                setTimeout(async () => {
                    if (
                        this.searchResults.hasNext ||
                        this.searchResults.nextPage >=
                            this.searchResults.totalPage
                    ) {
                        const params = this.getUserInput(
                            this.searchResults.nextPage
                        )
                        // console.log('SpaceSearchView, Line 1977, handleScrollEnd, ')
                        if (this.isEmptyResults) {
                            await this.fallbackSearch(false)
                        } else {
                            params.isNewSearch = false
                            await this.getSearchRes(params)
                        }
                    } else {
                        this.list.loading = false
                    }
                }, 0)
            }
        },
        liked(id) {
            if (process.browser && storage.get(storage.key.liked)) {
                const list = JSON.parse(storage.get(storage.key.liked)).list
                return !!_find(list, (i) => {
                    return i === id
                })
            } else {
                return false
            }
        },
        setSearchKeyword(key, fromCreated = false) {
            this.input.words = key
        },
        async getCurrentLocation() {
            if (!this.input.currentUserLatLng) {
                this.isFetchingLocation = true
                if (navigator.geolocation) {
                    await navigator.geolocation.getCurrentPosition(
                        await this.setCurrentLocation,
                        await this.handleGetLocationError,
                        { enableHighAccuracy: true, maximumAge: 5000 }
                    )
                }
            }
        },
        setCurrentLocation(position) {
            // set undefined to clear the qs value from Home Page
            this.input.querySource = undefined
            const lat = position.coords.latitude
            const lon = position.coords.longitude
            this.input.currentUserLatLng = `${lat},${lon}`
            this.input.words = this.$t('search_bar.current_location')
            this.isFetchingLocation = false
        },
        handleGetLocationError(error) {
            let msg = ''
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    msg = this.$t('err_gps_permission_denied')
                    break
                case error.POSITION_UNAVAILABLE:
                    msg = this.$t('err_gps_position_unavailable')
                    break
                case error.TIMEOUT:
                    msg = this.$t('err_gps_timeout')
                    break
                default:
                case error.UNKNOWN_ERROR:
                    msg = this.$t('err_gps_unknown')
                    break
            }
            this.$sentry.captureException(error)
            alert(msg)
            this.isFetchingLocation = false
        },
        sendLogEventSearchResult(esType, query, hasUserLatLong) {
            let searchTerm = ''
            if (hasUserLatLong) {
                searchTerm = this.$t('search_bar.current_location')
            } else {
                searchTerm = query
            }
            const source = this.computeGASource()

            if (source) {
                const filter = this.$route?.params?.filter
                let filterObj = {}

                if (filter && typeof filter === 'object') {
                    filterObj = this.$route.params.filter
                }

                if (this.input.starRating.length > 0) {
                    filterObj.starRating = this.input.starRating
                }

                if (this.input.priceRange.length > 0 && !this.isDefaultPriceRange) {
                    filterObj.priceRange = this.input.priceRange
                }

                analysis.sendViewSearchResult(
                    this.space,
                    this.input.date.value,
                    searchTerm,
                    this.getGATimeParams,
                    source,
                    filterObj,
                    getFirstFiveHitsSlug(this.exactHit, this.nearbyHits, this.areaHits, this.districtHits, this.availableHits, this.alternativeHits, this.unavailableHits),
                    getMetaCountryName(this.region),
                    getMetaCityName(this.region, this.currentRegionObj, this.paramsGroup),
                    getMetaRegionName(esType, searchTerm, this.region, this.currentRegionObj, this.paramsGroup)
                )
            }
        },
        computeGASource() {
            const { source, prevRoute, $device, landingPageName } = this;
            const isCrawler = $device.isCrawler;
            const getPageType = () => {
                if (prevRoute.includes('/promotions/') || prevRoute.includes('/blog/')) {
                    return prevRoute.includes('/promotions/') ? 'PROMOTIONS' : 'BLOG';
                }
                return isCrawler ? 'CRAWLER' : isFromGoogleOrganicSearch(document?.referrer) ? 'GOOGLE_ORGANIC_SEARCH' : landingPageName;
            };

            const getPageSlug = () => {
                const prevRouteStrArr = prevRoute.split('/');
                const prevRouteStr = common.lastArrItem(prevRouteStrArr);
                return common.cleanQueryParams(prevRouteStr);
            };

            const getPageSection = () => {
                return this.sourceSection
            };

            const page = getPageType();
            const slug = prevRoute.includes('/promotions/') || prevRoute.includes('/blog/')
                ? getPageSlug()
                : '';

            const section = getPageSection()

            // Reset GA source data
            this.source = undefined;
            this.sourceSection = undefined;

            if (!source && !section && !slug) return ''

            return source ?? getGAEventName(page, section, slug);
        },
        sendLogEventsItem(params) {
            params.itemPosition = this.spaceResultHits.findIndex(
                (_) => _.objectID === params.objectID
            )
            if (params.itemPosition !== -1 && process.client) {
                sendObjectClickedEvent(
                    params.isMapView
                        ? ALGOLIA_EVENT_NAME.MAP_ITEM_CLICKED
                        : ALGOLIA_EVENT_NAME.LIST_ITEM_CLICKED,
                    params.queryID,
                    this.algoliaIndex,
                    params.objectID,
                    params.itemPosition + 1
                )
            }
        },
        // sendOutboundClickTrack(url) {
        //     this.$gtag('event', 'click', {
        //         event_category: 'outbound',
        //         event_label: url,
        //         transport_type: 'beacon'
        //     })
        // },
        handleScrollMethod(event) {
            const scrollTop = event.srcElement.scrollingElement.scrollTop
            this.isMobileFloatingBtnVisible = scrollTop > 400
            this.scrollTop = scrollTop

            const totalHeight = event.srcElement.scrollingElement.scrollHeight
            const windowHeight = window.innerHeight
            const scrollHeight = totalHeight - windowHeight
            // set search nav and map button display condition for mobile view
            if (
                scrollHeight - 5000 < scrollTop ||
                scrollHeight - scrollHeight / 7 < scrollTop
            ) {
                this.handleScrollEnd()
            }
        },
        setSearchBarVisible() {
            this.isSearchBarVisible = true
        },
        scrollToTop(smooth = true) {
            if (process.client) {
                const options = {
                    top: 0
                }
                if (smooth) options.behavior = 'smooth'
                window.scrollTo(options)
            }
        },
        scroll(smooth = true) {
            if (process.client) {
                if (this.isLocation) {
                    this.scrollToPosition('search-section')
                } else {
                    this.scrollToTop(smooth)
                }
            }
        },
        setNumPpl(numPpl) {
            this.numPpl = numPpl
        },
        setCheckInTime(time) {
            this.input.checkInTime = time
        },
        handleMobileMapBtnClick() {
            this.setDefaultMap()
            this.map.visible = true
            this.sendLogEventViewMap(undefined, 'MAP_PILL_BUTTON')
        },
        handleDesktopMapBtnClick() {
            this.setDefaultMap()
            this.setDesktopMapVisible()
            this.sendLogEventViewMap(undefined, 'MAP_IMAGE_BUTTON')
        },
        sendLogEventViewMap(section, name) {
            const source = getGAEventName(this.landingPageName, section, name)
            analysis.sendViewMap(source)
        },
        setDesktopMapVisible() {
            this.map.option.zoomControl = true
            this.map.option.streetViewControl = true
            this.isMapSearchVisible = true
        },
        scrollAfterSearch() {
            if (this.isLocation) {
                this.scrollToPosition('search-section')
            } else {
                this.scrollToTop(false)
            }
        },
        desktopSearchClick() {
            this.sourceSection = 'SEARCH_RESULT_BAR'
            this.scrollAfterSearch()
            this.startSearching()
        },
        async mobileSearchClick(selected) {
            // console.log('_space, mobileSearchClick, selected, ', selected)
            this.setLoadingState(true)
            this.scrollAfterSearch()
            this.updateSelectedFilters(selected)
            this.isMobileFilterSelected = this.checkMobileFilterSelected(
                selected
            )
            this.setQuery(selected.words, selected.getCurrentLocation)
            await this.startSearching()
        },
        scrollToPosition(position) {
            this.$scrollTo('#' + position)
        },
        handleBackBtnClick() {
            if (this.map.visible) {
                this.map.visible = false
                this.addScrollBar()
            } else {
                this.navigateRoute('')
            }
        },
        redirectToExactAreaSearch() {
            const query = this.$route.query
            const date = query.date ?? dateServices.getToday()
            const time = query.time ?? 'any_time'
            let path = `/${this.region}/${this.lang}/${this.space}?date=${date}&time=${time}&q=${this.input.words}`
            if (this.popularSpaceResultHits.length > 0) {
                const location = this.popularSpaceResultHits[0].location
                path += `&qs=ps&es_type=area&area=${location?.area?.id}&district=${location?.district?.id}`
            }
            this.$router.push(path)
        },
        setQuerySource(
            value,
            category,
            locationHierarchies,
            suggestionId,
            isQuerySuggestion
        ) {
            // set undefined to clear the qs value from Home Page
            this.input.querySource = undefined
            this.input.exactSearchType = undefined
            this.input.suggestionId = undefined
            this.input.initialArea = undefined
            this.input.initialDistrict = undefined
            this.input.initialMetaDistrict = undefined

            this.input.querySourceType = isQuerySuggestion
                ? QUERY_SOURCE_SUGGESTION
                : QUERY_SOURCE_POPULAR_SEARCH
            this.input.querySourceValue = value
            this.input.suggestCategory = category
            this.input.locationHierarchies = locationHierarchies
            this.input.suggestionId = suggestionId
        },
        renderListOrLoadingSkeleton(list, listType, loadingCount) {
            /** EXACT_SPACE, EXACT_NEARBY, EXACT_AREA, EXACT_DISTRICT, AVAILABLE, ALTERNATIVE_UNAVAILABLE */
            return list.length > 0 && this.isLocation
                ? this.locationRenderList(list, listType)
                : list.length > 0
                ? list
                : new Array(Math.round(loadingCount)).fill({})
        },
        locationRenderList(list, listType) {
            let renderNum = 24
            switch (listType) {
                case 'ALTERNATIVE_UNAVAILABLE':
                    const remain = renderNum - this.availableHits?.length
                    renderNum = remain > 0 ? remain : 0
                    break
            }
            return list.slice(0, renderNum)
        },
        getHeader(meta) {
            const keyword = this.$t(`keywords.${this.localeKeyword}`)
            const obj = {
                location: this.locationDetails.name,
                country:
                    this.locationDetails.name !== this.regionName()
                        ? this.regionName()
                        : '',
                keyword
            }
            const spaceTypeNum = this.globalSpaceTypeNum(this.space)
            return this.$t(`location.${meta}_${spaceTypeNum}`, obj)
        },
        updateAnimateIndex(index) {
            this.animIndex = index
        },
        exactAreaSearchForKeywordPage() {
            const type = this.locationDetails?.type
            if (
                this.popularSpaceResultHits.length > 0 &&
                type !== 'country' &&
                type !== 'city'
            ) {
                const area = { field: 'area', value: '' }
                const district = { field: 'district', value: '' }
                const location = this.popularSpaceResultHits[0].location
                area.value = location.area?.id
                district.value = location.district?.id
                const locationHierarchies = [area, district]
                this.setQuerySource(
                    this.locationDetails.name,
                    'LOCATION-AREA',
                    locationHierarchies,
                    '',
                    true
                )
            }
        },
        async resetSearchBarSetting() {
            await this.getSearchBarSetting()
            this.setPriceRangeFromSearchBarSetting()
            this.setPriceRangeFromQuery()
        },
        getSearchBarSetting(spaceType = this.globalSpaceType(this.space)) {
            return this.$store.dispatch('api/GET_SEARCH_BAR_SETTING', {
                space_type: spaceType,
                region: this.region,
                lang: this.lang,
                group: this.paramsGroup,
                device: this.deviceType()
            })
        },
        handleVisibilityChange(value) {
            if (value){
                this.isTimeoutRefreshModalVisible = true
            }
        },
        setTimeoutRefreshModalVisible(){
            this.isTimeoutRefreshModalVisible = false
            this.startSearching()
        }
    }
}
</script>

<style scoped>
.gm-control-active > img:nth-child(1) {
    content: attr(alt);
    width: 20px;
}

.gm-control-active > img {
    content: attr(alt);
    width: 27px;
}
.space-dropdown >>> button {
    background-color: transparent !important;
    border: transparent;
    padding: 0;
}
.space-dropdown >>> .dropdown-menu {
    margin-top: 10px;
    top: 116%;
}
::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(157, 157, 157);
    opacity: 1; /* Firefox */
    font-weight: normal;
}

@-moz-document url-prefix() {
    .dropdown > .dropdown-menu {
        left: 4px !important;
        text-align: end;
    }
}
.gm-control-active {
    height: 10px;
}
.field-container {
    border-radius: 17px;
    width: 279px;
    background-color: white;
}
@media (min-width: 1228px) {
    .list-container {
        margin: auto;
        padding: 0 70px;
        width: 100%;
    }
}
.search-container {
    position: -webkit-sticky;
    position: sticky;
    top: 21px;
    height: 100%;
    z-index: 1;
}
.scroller {
    overflow-y: auto;
    overflow-x: hidden;
    height: 85vh;
    padding-right: 14px;
}

/* For Chrome, Safari, and Opera */
.scroller::-webkit-scrollbar {
    display: none;
}

/* Show the scrollbar when hovering */
.scroller:hover {
    -ms-overflow-style: auto; /* For Internet Explorer and Edge */
}

/* For Chrome, Safari, and Opera */
.scroller:hover::-webkit-scrollbar {
    display: block;
    width: 16px;
}

/* Style the scrollbar */
.scroller:hover::-webkit-scrollbar-thumb {
    background: #909090;
    border-radius: 8px;
    border: 4px solid transparent;
    background-clip: content-box;
}
.mobile-map-container {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: var(--vh);
}
.mobile-search-bar {
    display: flex;
    align-items: center;
    border-radius: 36px;
    width: 100%;
}
.csr-search-bar {
    border: 2px solid var(--palette-gold);
}
.ssr-search-bar {
    border: 2px solid transparent;
}
.filter-container {
    border-width: 2px;
    border-style: solid;
    border-radius: 24px;
    padding: 8px;
}
.filter-container-default {
    border-color: var(--palette-gold);
}
.filter-container-selected {
    background-color: var(--palette-gold);
    border-color: #ffffff;
}
</style>
