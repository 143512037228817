const colors = {
    primary: '#F2BC1F',
    secondary: '#000000',
    success: '#8FDE1C',
    info: '#139191',
    warning: '#000000',
    danger: '#F2371F',
    light: '#FFFFFF',
    dark: '#000000',

    payme: '#db0011',
    lightPayme: 'rgba(219, 0, 17, 0.3)',

    transparent: 'rgba(0, 0, 0, 0)',
    darkTransparent: 'rgba(0, 0, 0, 0.5)',
    lightTransparent: 'rgba(255, 255, 255, 0.3)',

    tagLightBlue: '#45C5D4',
    tagGreen: '#609D59',
    tagYellow: '#F2BC1F',
    tagDeepBlue: '#44546A'
}

export default colors
