<template>
    <client-only>
        <b-button
            @click="handleBtnClick"
            variant="transparent"
            class="p-0 position-relative button-active map-button"
            style="border: 0"
        >
            <ImageContainer
                alt="desktop mapview"
                src="https://imagedelivery.net/qOh2lp-0AGWeBP68yRH5aQ/b6302de7-b577-4fee-3db4-5ee3d0d19100/public"
                width="398"
                height="65"
                style="border-radius: 8px; width: 398px; height: 65px"
            />
            <div class="mb-2 text-14 desktop-map-tag flex-center">
                <ShowMapSvg class="mr-2" />
                <div class="text-14">
                    {{ $t('list.view_on_map') }}
                </div>
            </div>
        </b-button>
        <template #placeholder>
            <div
                style="border-radius: 8px; overflow: hidden; position: relative;"
            >
                <b-skeleton-wrapper :loading="true">
                    <template #loading>
                        <b-skeleton-img
                            height="65px"
                            width="398px"
                            no-aspect
                        ></b-skeleton-img>
                    </template>
                </b-skeleton-wrapper>
            </div>
        </template>
    </client-only>
</template>
<script>
import ShowMapSvg from '~/components/iconSvg/ShowMapSvg.vue'
import ImageContainer from "@/components/ui/ImageContainer.vue";

export default {
    name: 'DesktopMapButton',
    components: {ImageContainer, ShowMapSvg },
    methods: {
        handleBtnClick() {
            this.$emit('handleBtnClick')
        }
    }
}
</script>
<style scoped>
.desktop-map-tag {
    position: absolute;
    font-weight: 600;
    white-space: nowrap;
    right: 20px;
    top: 45px;
    background-color: black;
    color: #ffffff;
    padding: 6px 20px;
    border-radius: 50px;
}
.map-button:hover {
    opacity: 0.7;
}
</style>
