<template>
    <div class="d-flex align-items-center justify-content-between">
        <h2
            v-if="routeName === 'region-locale-space'"
            class="space-name text-404040 text-bold text-left align-self-center"
        >
            {{ title }}
        </h2>
        <div
            v-else
            class="space-name text-404040 text-bold text-left align-self-center"
        >
            {{ title }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpaceCardTitle',
    props: {
        title: {
            type: String,
            required: true
        }
    },
    computed: {
        routeName() {
            return this.$route.name
        }
    }
}
</script>

<style scoped>
.space-name {
    font-size: 20px;
}
@media (max-width: 575px) {
    .space-name {
        font-size: 19px;
    }
}
</style>
