<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div
                        @click.stop
                        class="d-flex justify-content-center m-auto"
                        style="max-width: 500px;"
                    >
                        <div
                            class="bg-light cal-container text-center py-4 px-4"
                        >
                            <div class="text-16 text-dark text-bold mt-3 mb-4">
                                {{ $t('timeout_refresh_title') }}
                            </div>
                            <div
                                class="text-14 text-1e1e1e px-4 mx-4 mb-5 px-lg-5"
                            >
                                {{ $t('timeout_refresh_des') }}
                            </div>
                            <BookButton
                                :book-button-text="$t('refresh')"
                                :button-class="'btn-yellow'"
                                @buttonClicked="setTopUpModalVisible"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import BookButton from '@/components/item/BookButton.vue'

export default {
    components: { BookButton },
    methods: {
        close() {
            this.$emit('close')
        },
        setTopUpModalVisible() {
            this.close()
        }
    }
}
</script>

<style scoped>
.modal-mask {
    position: fixed;
    z-index: 1038;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    display: table;
    transition: opacity 0.3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
.modal-container {
    transition: all 0.3s ease;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    opacity: 0;
}
.cal-container {
    position: relative;
    border-radius: 10px;
}
.close-btn {
    font-size: 22px;
    position: absolute;
    right: -50px;
    top: 0;
    transform: rotate(45deg);
}

@media (max-width: 767.98px) {
    .cal-container {
        position: relative;
        border-radius: 10px;
        width: 90vw;
    }
    .close-btn {
        font-size: 22px;
        position: absolute;
        right: 0;
        top: -50px;
        transform: rotate(45deg);
    }
    .date-picker-wrapper >>> .vdp-datepicker__calendar {
        font-size: 14px !important;
        width: 100%;
        height: 100%;
    }
    .modal-wrapper {
        vertical-align: middle;
    }
}
</style>
