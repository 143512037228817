<template>
    <PopularFilter
        :date="date"
        :num-ppl="numPpl"
        :space="space"
        :type="type"
        :slug="slug"
        :device="device"
        :is-display-btn="isDisplayBtn"
        :name="'fitness-center'"
        :hits="hits"
        :btn-name="eventBtnName('Location', 'gym')"
        @navigateToDetails="navigateToDetails"
    >
        {{
            getLocationHeader(
                'fitness_center_space',
                query,
                space,
                localeKeyword
            )
        }}
    </PopularFilter>
</template>

<script>
import dateServices from '@/services/dateServices'
import PopularFilter from '~/components/location/PopularFilter'

export default {
    name: 'FitnessCenterSpace',
    components: {
        PopularFilter
    },
    props: {
        date: {
            type: String,
            default: dateServices.getDateByFormat(new Date())
        },
        numPpl: {
            type: Number,
            default: 0
        },
        space: {
            type: String,
            default: 'hotel'
        },
        query: {
            type: String,
            required: true,
            default: undefined
        },
        type: {
            type: String,
            required: true,
            default: undefined
        },
        slug: {
            type: String,
            required: true,
            default: ''
        },
        device: {
            type: String,
            default: ''
        },
        isDisplayBtn: {
            type: Boolean,
            default: false
        },
        localeKeyword: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            fitnessCenterName: {
                en: 'Gym / Fitness center',
                tc: '健身中心',
                sc: '健身中心',
                ms: 'Pusat kecergasan',
                vi: 'Trung tâm thể dục'
            },
            filter: { f_fitness_center: '' }
        }
    },
    async fetch() {
        await this.getPopularFilterHits()
    },
    computed: {
        hits() {
            // eslint-disable-next-line standard/computed-property-even-spacing
            const hits = this.$store.getters[
                'algoliaSearch/fitnessCenterSpaceResultHits'
            ]
            return hits.slice(0, 10)
        }
    },
    mounted() {
        this.getPopularFilterHits()
    },
    methods: {
        async getPopularFilterHits() {
            try {
                let params = this.getPopularFilterParams(this.space, this.query)
                // eslint-disable-next-line standard/computed-property-even-spacing
                this.filter.f_fitness_center = this.fitnessCenterName[
                    this.globalAlgoliaLang
                ]
                params = {
                    ...params,
                    ...this.filter,
                    isSkipAnalytics: true
                }
                await this.$store.dispatch(
                    'algoliaSearch/SEARCH_FITNESS_CENTER_SPACE_INDEX',
                    params
                )
            } catch (err) {
                this.$nuxt.error(err)
            }
        },
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        }
    }
}
</script>
