<template>
    <b-button
        @click="handleBtnClick"
        variant="transparent"
        class="text-292929 p-0"
    >
        <WestSvg />
    </b-button>
</template>
<script>
import WestSvg from '@/components/iconSvg/WestSvg.vue'

export default {
    name: 'BackButton',
    components: { WestSvg },
    methods: {
        handleBtnClick() {
            this.$emit('handleBtnClick')
        }
    }
}
</script>
