var render = function render(){var _vm=this,_c=_vm._self._c;return _c('router-link',{staticClass:"more-link button-active",attrs:{"to":{ path: _vm.destUrl }}},[_c('div',{staticClass:"text-13 text-838383 d-flex align-items-center",staticStyle:{"white-space":"nowrap"},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.onMoreClicked({
                btn: _vm.btn,
                slug: _vm.listItem?.slug,
                name: _vm.listItem?.name?.[_vm.algoliaLang],
                objectID: _vm.listItem?.id,
                queryID: _vm.listItem?.queryID
            })}}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }