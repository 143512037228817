<template>
    <div class="d-flex justify-content-between bg-white w-100">
        <GMap
            :map="map"
            :selected-list-item="selectedListItem"
            @updateMapPopupInfo="setCurrentMarker"
        />
        <div class="position-absolute w-100" style="z-index: 100; bottom:5%;">
            <div class="d-flex justify-content-center mb-3">
                <PillButton @handleBtnClick="handleBtnClick">
                    <FormatListBulletedSvg
                        class="mr-2"
                        style="width: 18px; height: 18px; fill: #FFFFFF"
                    />
                    <div class="text-16">{{ $t('list.list') }}</div>
                </PillButton>
            </div>
            <SwiperContainer
                :option="{
                    slidesPerView: 1.2,
                    spaceBetween: 15,
                    slidesOffsetBefore: 14,
                    slidesOffsetAfter: 14,
                    freeMode: true
                }"
                :name="'spaceCardContainer'"
                :marker-index="markerIndex"
                @setCurrentSlide="setCurrentSlide"
            >
                <b-button
                    v-for="(listItem, stepIndex) in spaceResultHits"
                    :key="stepIndex"
                    @click="
                        navigateToDetails({
                            btn: getGAEventName('MAP_SEARCH','SPACE_CARD', undefined, stepIndex),
                            slug: listItem.slug,
                            name: listItem.name.en,
                            objectID: listItem.id,
                            queryID: listItem?.queryID
                        })
                    "
                    class="swiper-slide button-active p-0"
                    variant="transparent"
                >
                    <MapSpaceCard
                        :list-item="listItem"
                        :space-type-text="spaceTypeText"
                    />
                </b-button>
            </SwiperContainer>
        </div>
    </div>
</template>
<script>
import { getAlgoliaLang } from '@/services/algoliaApi'
import SwiperContainer from '@/components/ui/SwiperContainer'
import MapSpaceCard from '@/components/item/MapSpaceCard'
import PillButton from '@/components/item/PillButton'
import FormatListBulletedSvg from '@/components/iconSvg/FormatListBulletedSvg.vue'
import GMap from '~/components/ui/Map.vue'
import {getGAEventName} from "@/utils/analysis";

export default {
    name: 'MobileMapView',
    components: {
        GMap,
        FormatListBulletedSvg,
        PillButton,
        MapSpaceCard,
        SwiperContainer
    },
    props: {
        spaceTypeText: {
            type: String,
            required: true,
            default: undefined
        },
        selectedListItem: {
            type: Object,
            required: true,
            default: undefined
        },
        map: {
            type: Object,
            required: true,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            markerIndex: 0
        }
    },
    computed: {
        algoliaLang() {
            return getAlgoliaLang(
                this.$route.params.region,
                this.$route.params.locale
            )
        },
        spaceResultHits() {
            return this.$store.getters['algoliaSearch/spaceResultHits']
        }
    },
    mounted() {
        const slideIndex = this.spaceResultHits.findIndex(
            (_) => _.id === this.selectedListItem.id
        )
        this.setCurrentMarker(this.selectedListItem, slideIndex)
    },
    methods: {
        getGAEventName,
        updateMapPopupInfo(item) {
            this.$emit('updateMapPopupInfo', item)
        },
        navigateToDetails(params) {
            const _params = {
                ...params,
                isMapView: true
            }
            this.$emit('navigateToDetails', _params)
        },
        setCurrentSlide(currentSlide) {
            const item = this.spaceResultHits[currentSlide]
            this.updateMapPopupInfo(item)
        },
        setCurrentMarker(item, index) {
            this.markerIndex = index
            this.updateMapPopupInfo(item)
        },
        handleBtnClick() {
            this.$emit('handleBtnClick')
        }
    }
}
</script>
<style scoped></style>
