<template>
    <p
        class="text-16 text-s-bold mb-4 mb-lg-4 mx-3 mx-lg-0 px-3 alternative-section-title"
    >
        <slot />
    </p>
</template>
<script>
export default {
    name: 'AlternativeResultsHint'
}
</script>

<style scoped>
.alternative-section-title {
    background-color: var(--palette-yellow);
    border-radius: 8px;
    padding-top: 0.6rem !important;
    padding-bottom: 0.6rem !important;
}
</style>
