<template>
    <div v-if="hits.length > 0 && !hideHits" :key="hits.toString()">
        <HeaderTitle :type="headerType" class="d-flex mb-3 px-3 px-lg-0">
            <slot />
        </HeaderTitle>
        <div
            v-if="isDisplayBtn"
            class="w-100 d-flex justify-content-end mb-3 px-3"
        >
            <TextButton class="text-14 text-9d9d9d"
                ><div @click="$emit('handleBtnClick')">
                    {{ $t('details.see_all') }}
                </div>
            </TextButton>
        </div>
        <SwiperContainer
            :is-set-slides-per-view="true"
            :set-margin="true"
            :name="name"
            :type="device"
            :width-adjustment="10"
            :is-free-mode="true"
            class="px-3"
        >
            <div
                v-for="(listItem, index) in hits"
                :id="`${device}-${name}-swiper-slide`"
                :key="index"
                :class="checkboxStyle(listItem, index)"
                class="card-item swiper-slide mt-2"
            >
                <PopularSpaceCard
                    :list-item="listItem"
                    :date="date"
                    :num-ppl="numPpl"
                    :space="space"
                    :slug="slug"
                    :btn-name="btnName"
                    :is-unlisted-hourly-hotel="isUnlistedHourlyHotel"
                    :position="index"
                    @navigateToDetails="navigateToDetails"
                    class="popular-list-item"
                />
            </div>
        </SwiperContainer>
    </div>
</template>

<script>
import PopularSpaceCard from '@/components/list/PopularSpaceCard'
import SwiperContainer from '@/components/ui/SwiperContainer'
import dateServices from '@/services/dateServices'
import HeaderTitle from '~/components/home/HeaderTitle'
import TextButton from '~/components/item/TextButton'

export default {
    name: 'PopularFilter',
    components: { TextButton, HeaderTitle, SwiperContainer, PopularSpaceCard },
    props: {
        date: {
            type: String,
            default: dateServices.getDateByFormat(new Date())
        },
        numPpl: {
            type: Number,
            default: 0
        },
        space: {
            type: String,
            default: 'hotel'
        },
        type: {
            type: String,
            required: true,
            default: undefined
        },
        slug: {
            type: String,
            required: true,
            default: ''
        },
        device: {
            type: String,
            default: ''
        },
        isDisplayBtn: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        hits: {
            type: Array,
            default() {
                return []
            }
        },
        headerType: {
            required: false,
            type: String,
            default: 'mobile'
        },
        btnName: {
            type: String,
            required: true
        },
        isUnlistedHourlyHotel: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            swiperOption: {},
            defaultOption: {
                slidesPerView: 1.7,
                spaceBetween: 10,
                mode: 'horizontal',
                breakpoints: {
                    2250: {
                        slidesPerView: 6.4,
                        spaceBetween: 20
                    },
                    1700: {
                        slidesPerView: 5.3,
                        spaceBetween: 20
                    },
                    1400: {
                        slidesPerView: 4.3,
                        spaceBetween: 20
                    },
                    1000: {
                        slidesPerView: 3.3,
                        spaceBetween: 20
                    },
                    800: {
                        slidesPerView: 2.9,
                        spaceBetween: 20
                    },
                    680: {
                        slidesPerView: 2.3,
                        spaceBetween: 10
                    },
                    579.98: {
                        slidesPerView: 2,
                        spaceBetween: 10
                    }
                }
            }
        }
    },
    computed: {
        hideHits() {
            return this.hits.length < 2 && this.globalSlug
        }
    },
    methods: {
        navigateToDetails(params) {
            this.$emit('navigateToDetails', params)
        },
        checkboxStyle(item, index) {
            let style = ''
            switch (index) {
                case 0:
                    style += 'first-checkbox'
                    break
                case this.hits?.length - 1:
                    style += 'last-checkbox'
            }
            return style
        }
    }
}
</script>
<style scoped>
.popular-list-item,
.swiper-slide {
    display: flex;
    width: auto !important;
    margin-right: 10px;
    aspect-ratio: 1/1.05;
}
@media (max-width: 576px) {
    .popular-list-item,
    .swiper-slide {
        height: 220px;
    }
}
@media (min-width: 577px) and (max-width: 991px) {
    .popular-list-item,
    .swiper-slide {
        height: 262px;
    }
}
@media (min-width: 992px) and (max-width: 1799px) {
    .popular-list-item,
    .swiper-slide {
        height: 262px;
    }
}
@media (min-width: 1800px) {
    .popular-list-item,
    .swiper-slide {
        height: 262px;
        margin-right: 20px;
    }
}
/*@media (max-width: 991px) {
    .first-checkbox {
        margin-left: 14px;
    }
    .last-checkbox {
        margin-right: 14px;
    }
}*/
</style>
