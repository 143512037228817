export default {
    mounted() {
        window.addEventListener('visibilitychange', this.visibilityChangeMixin)
    },
    beforeUnmount() {
        window.removeEventListener(
            'visibilitychange',
            this.visibilityChangeMixin
        )
    },
    methods: {
        visibilityChangeMixin() {
            let isChanged = null
            if (document.visibilityState === 'visible') {
                const lastUpdateTime = new Date(
                    localStorage.getItem('lastUpdateTime')
                )
                const currentTime = new Date()
                const timeSinceLastUpdate = currentTime - lastUpdateTime
                const threshold = 60000 * 15 // 15 minutes

                if (timeSinceLastUpdate > threshold) {
                    isChanged = true
                }
            } else {
                localStorage.setItem('lastUpdateTime', new Date())
            }
            this.$emit('visibilityChange', isChanged)
        }
    }
}
