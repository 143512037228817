<template>
    <svg width="54" height="10" viewBox="0 0 54 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 9L53 1" stroke="#FFC000" stroke-width="2" stroke-linecap="round"/>
    </svg>
</template>

<script>
export default {
    name: 'CrossOutSvg'
}
</script>

<style scoped></style>
