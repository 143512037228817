<template>
    <div v-show="tags && tags.length > 0" class="d-flex flex-wrap">
        <div
            v-for="(tag, index) in tags"
            :key="index"
            :style="'color:' + getTagColor(tag.colour)"
            :class="tag.type === 'fomo_last_booked' ? 'd-flex' : 'd-flex'"
        >
            <div
                v-show="tag"
                :class="getTagStyleByType(tag.type)"
                class="text-mid text-13 position-relative tag-content"
            >
                {{ tag.content }}
            </div>
        </div>
    </div>
</template>
<script>
import colors from '@/themes/colors'

export default {
    name: 'Tags',
    props: {
        tags: {
            type: Array,
            required: false,
            default() {
                return []
            }
        }
    },
    methods: {
        getTagColor(color) {
            switch (color) {
                case 'light-blue':
                    return colors.tagLightBlue
                case 'green':
                    return colors.tagGreen
                case 'deep-blue':
                    return colors.tagDeepBlue
                case 'flow-yellow':
                default:
                    return colors.tagYellow
            }
        }
    }
}
</script>
<style scoped>
.purple-tag {
    background: #8b1af5;
    color: white !important;
    border-radius: 5px 4px 4px 5px;
    clip-path: polygon(100% 0%, 93% 50%, 100% 100%, 0 100%, 0% 50%, 0 0);
    display: flex;
}
.room-type-tag {
    padding: 2px 11px 2px 5px;
    font-size: 11px;
}
.space-tag {
    padding: 3px 18px 3px 9px;
    font-size: 13px;
}
.feature {
    display: flex;
    align-items: center;
}
.tag-content {
    user-select: none;
    margin-right: 0.4rem;
    margin-top: 0.4rem;
}
</style>
