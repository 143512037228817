<template>
    <router-link :to="{ path: destUrl }" class="more-link button-active">
        <div
            @click.prevent.stop="
                onMoreClicked({
                    btn,
                    slug: listItem?.slug,
                    name: listItem?.name?.[algoliaLang],
                    objectID: listItem?.id,
                    queryID: listItem?.queryID
                })
            "
            class="text-13 text-838383 d-flex align-items-center"
            style="white-space: nowrap;"
        >
            <slot />
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'SpaceCardMoreButton',
    props: {
        listItem: {
            type: Object,
            required: true
        },
        destUrl: {
            type: String,
            required: true
        },
        algoliaLang: {
            type: String,
            required: true
        },
        btn: {
            type: String,
            required: true
        }
    },
    methods: {
        onMoreClicked(params) {
            this.$emit('onMoreClicked', params)
        }
    }
}
</script>

<style scoped>
.more-link:hover {
    opacity: 0.7;
}
</style>
