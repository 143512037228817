<template>
    <svg
        width="11"
        height="12"
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect y="0.25" width="11" height="11" fill="url(#pattern0)" />
        <defs>
            <pattern
                id="pattern0"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
            >
                <use
                    xlink:href="#image0_412_2550"
                    transform="scale(0.03125 0.0322581)"
                />
            </pattern>
            <image
                id="image0_412_2550"
                width="32"
                height="31"
                xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAIKADAAQAAAABAAAAHwAAAACsP2+eAAABiElEQVRIDe1XwVHDMBC88/CHTkgqwB04fEwNST50ABRB/GR44g8xDcTpwJSSBjjuCMp4zMmWLIE/6GNpLe2ub3TSGauXogGES+g0AmoW+Wregb2HQ/yJJi4qCDjzVtMWKB/X5j8za7J8iaZflQVJf1sWO4OFPjV+4TwZ0ATYUarhMbFeA0DwECyGcNfHgSbc2qR22LT3LtgQfwJAewtRZcF9YZ2H4F2IWhtvU/PwSgxl+SqVlzFbVer8HIFp2+QG+rPAIziv5eOMD68nJKyym+W969IoBr7Fd2zgApAOruIyL9hAW5yPzwPfIbc+BoL2wE/xj/Q6Xzd/YiCGuBgdFYFY4qMMxBT3NhBb3MvAb4iLAec0REhqvjjOj6nmv9tFTGvOm5DF69jiYsg5AlwbLLQvCMWcIxAqZFs/uQEcqtttzl3xIf7//4LeLODUS11DPXbeycBbueGC9Ng4301vb3rjn1LoAuj8rXOAAOuuSIzq2PwXaPyiJ2mo1+12vOuzf0z0bJnwpfsJ6TK6wk7HMTMAAAAASUVORK5CYII="
            />
        </defs>
    </svg>
</template>

<script>
export default {
    name: 'SpaceAreaSvg'
}
</script>
