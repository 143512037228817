var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex justify-content-between bg-white w-100"},[_c('GMap',{attrs:{"map":_vm.map,"selected-list-item":_vm.selectedListItem},on:{"updateMapPopupInfo":_vm.setCurrentMarker}}),_vm._v(" "),_c('div',{staticClass:"position-absolute w-100",staticStyle:{"z-index":"100","bottom":"5%"}},[_c('div',{staticClass:"d-flex justify-content-center mb-3"},[_c('PillButton',{on:{"handleBtnClick":_vm.handleBtnClick}},[_c('FormatListBulletedSvg',{staticClass:"mr-2",staticStyle:{"width":"18px","height":"18px","fill":"#FFFFFF"}}),_vm._v(" "),_c('div',{staticClass:"text-16"},[_vm._v(_vm._s(_vm.$t('list.list')))])],1)],1),_vm._v(" "),_c('SwiperContainer',{attrs:{"option":{
                slidesPerView: 1.2,
                spaceBetween: 15,
                slidesOffsetBefore: 14,
                slidesOffsetAfter: 14,
                freeMode: true
            },"name":'spaceCardContainer',"marker-index":_vm.markerIndex},on:{"setCurrentSlide":_vm.setCurrentSlide}},_vm._l((_vm.spaceResultHits),function(listItem,stepIndex){return _c('b-button',{key:stepIndex,staticClass:"swiper-slide button-active p-0",attrs:{"variant":"transparent"},on:{"click":function($event){_vm.navigateToDetails({
                        btn: _vm.getGAEventName('MAP_SEARCH','SPACE_CARD', undefined, stepIndex),
                        slug: listItem.slug,
                        name: listItem.name.en,
                        objectID: listItem.id,
                        queryID: listItem?.queryID
                    })}}},[_c('MapSpaceCard',{attrs:{"list-item":listItem,"space-type-text":_vm.spaceTypeText}})],1)}),1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }