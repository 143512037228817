var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex align-items-center flex-wrap"},[_c('StarRating',{staticClass:"mb-1",attrs:{"star-rating":_vm.listItem.ranking?.star_rating}}),_vm._v(" "),(
            _vm.listItem?.ranking?.user_rating ||
                _vm.listItem.ranking?.total_comments
        )?_c('div',{staticClass:"d-flex align-items-center mb-1"},[_c('SpaceCardSeparator',{staticClass:"mx-2 mx-lg-3",attrs:{"separator-height":17}}),_vm._v(" "),_c('ThumbSvg',{attrs:{"width":"12px","height":"12px"}}),_vm._v(" "),(_vm.listItem?.ranking?.user_rating)?_c('UserRating',{staticClass:"ml-2",attrs:{"rating":_vm.listItem?.ranking?.user_rating}}):_vm._e(),_vm._v(" "),(_vm.listItem.ranking?.total_comments)?_c('TotalComment',{staticClass:"ml-1",attrs:{"comments":_vm.listItem.ranking?.total_comments}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"d-flex align-items-center mb-1"},[(
                _vm.listItem.ranking?.star_rating &&
                    _vm.listItem?.location?.area?.name?.[_vm.algoliaLang]
            )?_c('SpaceCardSeparator',{staticClass:"mx-2 mx-lg-3",attrs:{"separator-height":17}}):_vm._e(),_vm._v(" "),_c('SpaceCardLocation',{attrs:{"algolia-lang":_vm.algoliaLang,"is-hide-location-link":_vm.hideLocationLink(_vm.listItem?.location?.area?.slug),"list-item":_vm.listItem,"type":'LISTING'}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }