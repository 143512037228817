<template>
    <div>
        <router-link
            v-for="(btn, index) in buttonList"
            :to="{
                path: computeTimeslotPath(
                    listItem?.slug,
                    btn.book_type,
                    btn.start_value,
                    btn.end_value
                )
            }"
            :key="index"
            class="timeslot-link button-active"
        >
            <div
                @click.prevent.stop="
                    onTimeslotClicked({
                        btn: getGAEventName(landingPageName,'SPACE_CARD', 'Quick button', index),
                        slug: listItem?.slug,
                        name: listItem?.name?.[algoliaLang],
                        objectID: listItem?.id,
                        queryID: listItem?.queryID,
                        book_type: btn.book_type,
                        start_value: btn.start_value,
                        end_value: btn.end_value,
                        bookId: btn.book_id
                    })
                "
                :class="buttonList.length - 1 !== index ? 'mb-2' : ''"
                class="row position-relative timeslot-click-area button-active button-hover"
            >
                <div class="col-8 d-flex justify-content-between">
                    <p
                        v-if="btn.period_start_time_str"
                        class="text-reg text-12 text-765821 position-absolute text-center"
                        style="top: 32px; left: 0;"
                    >
                        {{ $t('list_timeslot_btn.early_check_in') }}
                    </p>
                    <div
                        class="col-3 d-flex flex-column align-items-center justify-content-center px-0"
                    >
                        <p
                            v-if="btn.period_start_time_str"
                            class="text-ffc000 text-bold timeslot-text position-absolute"
                            style="top: -16px;"
                        >
                            {{ btn.start_time_str }}
                        </p>
                        <p class="text-bold timeslot-text">
                            {{ btn.period_start_time_str ?? btn.start_time_str }}
                        </p>
                        <CrossOutSvg
                            v-if="btn.period_start_time_str"
                            class="position-absolute"
                        />
                    </div>
                    <div
                        class="col-6 flex-column flex-center position-relative px-2"
                    >
                        <p class="text-reg duration-text">
                            {{ btn.fmt_duration }}
                        </p>
                        <SpaceCardDurationSeparator
                            class="mx-3"
                            style="margin: 0.2em 0"
                        />
                        <p class="text-s-bold duration-text text-transparent">
                            {{ btn.fmt_duration }}
                        </p>
                        <!--Last Room Tag-->
                        <span
                            v-if="btn.availability_tag"
                            :class="
                                getTagStyleByType(btn.availability_tag.type)
                            "
                            class="fomo-tag-timeslot"
                        >
                            {{ btn.availability_tag.content }}
                        </span>
                    </div>
                    <div
                        class="col-3 d-flex flex-column align-items-center justify-content-center px-0"
                    >
                        <div class="d-flex position-relative">
                            <span class="text-bold timeslot-text">
                                {{ btn.end_time_str }}
                            </span>
                            <span
                                v-if="btn.cross_day > 0"
                                class="text-11 position-absolute"
                                style="right: -9px; top: -2px"
                            >
                                {{ `+${btn.cross_day}` }}
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    :class="paramsRegion === 'vn' ? 'pl-3' : ''"
                    class="col-4 d-flex align-items-center flex-column"
                >
                    <div>
                        <!--Pending BE data-->
                        <div class="d-flex">
                            <p
                                v-if="btn.price"
                                :class="isDiscountPrice ? 'text-f66e32' : ''"
                                class="text-bold text-18"
                            >
                                {{ getDisplayPrice(btn.price) }}
                            </p>
                            <div v-if="false">
                                <p
                                    class="text-bold text-white discount-text bg-f66e32 text-13"
                                >
                                    -20%
                                </p>
                            </div>
                        </div>
                        <!--Pending BE data-->
                        <p
                            v-if="false"
                            class="text-404040 text-reg text-10 orig-price-text"
                        >
                            {{
                                $t('list_timeslot_btn.overnight') +
                                    getDisplayPrice(btn.price)
                            }}
                        </p>
                        <div
                            v-if="btn?.price_per_hour_int"
                            class="d-flex"
                            style="white-space: nowrap"
                        >
                            <p
                                class="text-765821 bg-fff8e8 hour-rate-text px-2 py-1 text-11 text-s-bold"
                            >
                                {{
                                    getDisplayPrice(btn.price_per_hour_int) +
                                        `${$t('list_timeslot_btn.per_hour')}`
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script>
import SpaceCardDurationSeparator from '@/components/list/SpaceCardDurationSeparator'
import CrossOutSvg from '@/components/iconSvg/CrossOutSvg'
import {getGAEventName} from "@/utils/analysis";


export default {
    name: 'SpaceCardTimeslots',
    components: {
        SpaceCardDurationSeparator,
        CrossOutSvg
    },
    props: {
        listItem: {
            type: Object,
            required: true
        },
        buttonList: {
            required: true,
            type: Array
        },
        date: {
            required: true,
            type: String,
            default: undefined
        },
        algoliaLang: {
            type: String,
            required: true
        }
    },
    computed: {
        spaceCardType() {
            return this.$route.params.space
        },
        isDiscountPrice() {
            return false
        }
    },
    methods: {
        getGAEventName,
        computeTimeslotPath(slug, bookLength, startValue, endValue) {
            const path = `/${this.paramsRegion}/${this.paramsLang}/${slug}/${this.spaceCardType}`
            // if (this.numPpl > 0 && this.spaceCardType === 'party') {
            //     path += `&numPpl=${this.numPpl}`
            // }
            // if (bookLength) {
            //     path += `&booklength=${bookLength}`
            // }
            // if (startValue) {
            //     path += `&start=${startValue}`
            // }
            // if (endValue) {
            //     path += `&end=${endValue}`
            // }
            return path
        },
        onTimeslotClicked(params) {
            this.$emit('onTimeslotClicked', params)
        }
    }
}
</script>

<style scoped>
.timeslot-link {
    text-decoration: none;
}
.timeslot-click-area {
    border: 1px solid #d6d6d6;
    border-radius: 14px;
    color: #1e1e1e;
    width: 100%;
    margin: auto;
    align-items: center;
}
@media (max-width: 992px) {
    .timeslot-click-area {
        padding: 1rem 0.5rem;
    }
    .timeslot-text {
        font-size: 16px;
    }
    .duration-text {
        font-size: 11px;
    }
}
@media (min-width: 993px) {
    .timeslot-click-area {
        padding: 1.5rem 1rem;
    }
    .timeslot-text {
        font-size: 19px;
    }
    .duration-text {
        font-size: 13px;
    }
}
.discount-text {
    border-radius: 6px 0 6px 0;
    padding: 0 4px;
    display: inline-block;
}
.orig-price-text {
    text-decoration: line-through;
}
.hour-rate-text {
    border-radius: 4px;
    border: 1px solid #ffc000;
}
.fomo-tag-timeslot {
    position: absolute;
    bottom: -7px;
    white-space: nowrap;
}
</style>
